<template>
  <main class="projects-group">
    <div class="btn-primary-l"><p class="-ntr">Manager</p></div>
    <ul v-if="projects">
      <li
        v-for="project in projects"
        :class="{ isSelected: project.selected }"
        :key="project"
        :ref="`p${project.proj_id}`"
        @mouseenter="setSelection(project)"
        @mouseleave="setSelection(project)"
      >
        <img
          :src="[!project.project_image ? thumbnail : project.project_image]"
          alt="project"
          class="project-img"
        />

        <section class="project-banner">
          <p class="-ntb">{{ project.project_name }}</p>
          <div class="reference">
            <p class="-str">{{ project.reference }}</p>
            <p class="-str">Units: {{ project.proj_units_count }}</p>
          </div>

          <section
            class="project-controls"
            :class="{ hidden: !project.selected }"
          >
            <div
              @click="handleClick(project.proj_id)"
              :ref="`btn${project.proj_id}`"
            >
              <p class="-xstb">Enter Project</p>
            </div>

            <div @click="toggleSecondaryMenu(project)">
              <p class="-xstr">Options</p>
            </div>
          </section>

          <section
            class="secondary-menu"
            v-show="project.secondaryMenu"
            :ref="`sm${project.proj_id}`"
          >
            <div class="secondary-menu-item">
              <p class="-xstr">Project Details</p>
            </div>
            <div
              class="secondary-menu-item delete-p"
              @click="
                [
                  (modalConfig.state.action = 'delete'),
                  (modalConfig.opened = true),
                ]
              "
            >
              <p class="-xstr">Delete Project</p>
            </div>
            <div
              class="secondary-menu-item"
              @click="closeSecondaryMenu(project)"
            >
              <p class="-xstr">Close</p>
            </div>
          </section>
        </section>

        <Modal
          v-if="modalConfig.opened"
          :setModal="modalConfig.state.type"
          :action="modalConfig.state.action"
          :item="{
            title: `You're about to delete the project: ${project.reference} - ${project.project_name}`,
            reference: project.proj_id,
            context: 'project',
            msg: `Once deleted, it will be permanently removed and cannot be
            recovered. Do you want to proceed?`,
          }"
          @confirmAction="deleteProject"
          @closeModal="modalConfig.opened = false"
        />
      </li>
    </ul>
  </main>
  <section class="projects-group">
    <div class="btn-primary-l"><p class="-ntr">Member</p></div>
  </section>
  <section class="projects-group" style="padding-top: 2rem">
    <div class="btn-primary-l"><p class="-ntr">Guest</p></div>
  </section>
</template>

<script>
import Modal from "../../views/Modal.vue";
export default {
  components: { Modal },
  props: ["projects"],
  emits: ["deleteProject"],
  data() {
    return {
      thumbnail: require("../../assets/thumbnails/render.jpg"),
      //REFS
      selectedRef: null,
      selectedRefBtn: null,
      modalConfig: {
        opened: false,
        state: {
          type: "confirmAction",
          action: null,
        },
      },
    };
  },
  methods: {
    setSelection(project) {
      project.selected ? (project.selected = false) : (project.selected = true);

      this.closeSecondaryMenu(project);
    },
    toggleSecondaryMenu(project) {
      !project.secondaryMenu
        ? (project.secondaryMenu = true)
        : this.closeSecondaryMenu(project);
    },
    closeSecondaryMenu(project) {
      if (!project.secondaryMenu) return;

      const domRef = `sm${project.proj_id}`;
      //set timeout to allow for click event to fire
      setTimeout(() => {
        project.secondaryMenu = false;
        this.$refs[domRef][0].classList.remove("slide-out");
      }, 200);

      this.$refs[domRef][0].classList.add("slide-out");
    },
    async handleClick(id) {
      if (id !== 446) return;

      const project = this.projects.find((p) => p.proj_id === id);
      this.$store.dispatch("project", project);

      console.log(this.$store.state.project);

      this.$router.push({ name: "Units", params: { id } });
    },
    deleteProject(id) {
      this.$emit("deleteProject", id);

      this.modalConfig.opened = false;
      this.modalConfig.state.action = "";
    },
  },
};
</script>

<style scoped>
.projects-group {
  grid-column: span 6;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 16px;
  justify-content: left;
  --project-banner-height: 120px;
}
.btn-primary-l {
  cursor: default;
  min-height: 40px;
  max-height: 40px;
}
.btn-primary-l:hover {
  transform: none;
}
ul {
  height: 100%;
  width: 100%;
  user-select: none;
  padding-inline: 16px;
  padding-block: 8px;
  display: flex;
  gap: 2rem;
  overflow: auto;
}
li {
  position: relative;
  filter: grayscale(20%);
  grid-column: auto;
  grid-row: span 1;
  align-self: center;
  max-height: 280px;
  min-height: 280px;
  min-width: 340px;
  max-width: 340px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  outline: 0px solid var(--secondary);
  transition: all 200ms ease;
  overflow: hidden;
}
.isSelected {
  filter: grayscale(0%);
}
.empty-ul {
  overflow: hidden;
}
.empty-li {
  cursor: default;
  grid-column: auto;
  grid-row: span 1;
  align-self: center;
  height: 280px;
  width: 231px;
  border-radius: 8px;
  background-image: none;
  border: 2px dashed var(--gray4);
  background: transparent;
}
.project-banner {
  height: 72px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgba(0, 0, 0, 0.55);
  color: var(--white1);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-block: 8px;
  transition: all 200ms ease-out;
  gap: 16px;
  z-index: 1;
}
.secondary-menu {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 1px;
  background: rgb(88, 184, 255);
  background: linear-gradient(
    29deg,
    rgba(88, 184, 255, 1) 10%,
    rgba(88, 248, 255, 1) 50%,
    rgba(88, 255, 214, 1) 90%
  );
  border-radius: 2px;
  cursor: pointer;
  animation: slide-in 200ms;
}
.secondary-menu-item {
  padding-inline: 8px;
  padding-block: 4px;
  /* background: linear-gradient(
    34deg,
    rgba(17, 17, 17, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  ); */
  background: rgba(0, 0, 0, 0.8);
  margin-bottom: 1px;
  text-align: center;
}

.secondary-menu-item:last-child {
  margin-bottom: 0px;
  color: var(--gray4);
}

.secondary-menu-item:hover {
  color: var(--secondary);
}

.delete-p:hover {
  color: salmon;
}
/*=== EFFECTS ===*/
li:hover {
  box-shadow: rgba(50, 50, 93, 1) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  outline: 8px solid var(--secondary);
}
li:hover .project-banner {
  height: var(--project-banner-height);
}
.empty-li:hover {
  transform: none;
  box-shadow: none;
  outline: none;
}
.reference {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  /* border: 1px solid red; */
  width: 100%;
}
.project-controls {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding-inline: 16px;
  height: 40px;
  animation: fade-in 800ms ease-out;
}
.project-controls div:first-child {
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--secondary);
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 4px;
  /* text-transform: uppercase; */
  font-variant: small-caps;
  transition: 200ms ease-out;
}
.project-controls div:first-child:hover {
  cursor: pointer;
  background: none;
  letter-spacing: 2px;
  color: var(--secondary);
}
.project-controls div:last-child {
  transition: 100ms ease-out;
  align-self: center;
}
.project-controls div:last-child:hover {
  cursor: pointer;
  letter-spacing: 2px;
  color: var(--secondary);
}
.project-controls div:last-child:active {
  scale: 0.9;
}
.project-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: scale 400ms ease;
}
li:hover .project-img {
  scale: 1.1;
}
.slide-out {
  animation: slide-out 200ms forwards;
}
@media screen and (max-width: 719px) {
  ul {
    scroll-snap-type: x mandatory;
  }
  li {
    min-width: 280px;
    scroll-snap-align: center;
  }
}
@keyframes slide-in {
  from {
    transform: translateX(120%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(120%);
  }
}
</style>
