<template>
  <main class="svg-wrapper">
    <svg
      class="animated"
      id="freepik_stories-construction-costs"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <g
        id="freepik--Floor--inject-42"
        class="animable"
        style="transform-origin: 257.947px 361.333px"
      >
        <path
          id="freepik--floor--inject-42"
          d="M96.29,454.63C7,403.08,7,319.51,96.29,268s234-51.54,323.31,0,89.28,135.12,0,186.67S185.57,506.17,96.29,454.63Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 257.947px 361.333px;
          "
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Symbols--inject-42"
        class="animable"
        style="transform-origin: 250.042px 169.01px"
      >
        <g
          id="freepik--money-icon--inject-42"
          class="animable"
          style="transform-origin: 250.042px 169.01px"
        >
          <path
            d="M71.76,160.13l2.07,3.63-4.9,2.79-2.07-3.63c-3.64,1.43-7.93,1.62-11.25-2.17L61,155.51a4.17,4.17,0,0,0,5.57.93c1.62-.92,2.34-2.2,1.52-3.64-1.32-2.32-4.2-.85-7.84.07-3.92.95-8,1.17-10.68-3.6-2.44-4.28-1.21-8.22,2.64-11.23l-2.07-3.63L55,131.6l2.07,3.63c4.2-1.58,7.82-.92,10.6,1.83l-5.07,5c-1.2-1.4-3-1.7-5.18-.46s-2.28,2.51-1.65,3.61c1,1.79,2.77,1.31,6.57.25,4-1.07,9.18-2.39,12.26,3C77.14,152.89,75.63,157.05,71.76,160.13Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 62.0822px 149.075px;
            "
            id="elm4yq49ozjie"
            class="animable"
          ></path>
          <path
            d="M34.39,299.86l-4.94-.79.48-3c-2.37-.83-5-2.64-5.2-6.52l0-.47,6.32-.63V289a2.45,2.45,0,0,0,2.24,2.51c1.13.18,1.76-.13,1.89-.92.18-1.08-.81-1.55-2.73-2.34-.43-.18-.89-.37-1.35-.59-2.65-1.24-5.27-3.07-4.59-7.27.56-3.45,2.86-5.37,6.53-5.46l.48-3,5,.8-.49,3a7.05,7.05,0,0,1,5,6l.06.51-6.08.63,0-.54c0-1.43-1.24-1.92-2.24-2.09-1.17-.18-1.82.1-1.95.85-.15,1,.48,1.35,3.23,2.53s6.51,2.88,5.73,7.67c-.55,3.43-3,5.43-6.83,5.56Zm-3.79-1.62,3,.47.46-2.85h.43c2,0,5.6-.58,6.27-4.71s-2.56-5.46-5.15-6.61-4.11-1.76-3.81-3.6c.08-.5.53-2.09,3.09-1.67a3.28,3.28,0,0,1,3,2.51l4-.42c-.44-2.33-2-3.95-4.64-4.82l-.4-.13.46-2.86-3-.48-.46,2.86h-.43c-2.38,0-5.34.74-6,4.62-.54,3.33,1.22,4.89,4,6.21.45.2.89.39,1.32.56,1.87.78,3.64,1.52,3.33,3.42-.22,1.37-1.35,2-3,1.76a3.46,3.46,0,0,1-3-2.95l-4.3.43c.35,3.2,2.81,4.63,4.87,5.28l.42.12Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 33.91px 285.895px;
            "
            id="elggwc4aflbbv"
            class="animable"
          ></path>
          <path
            d="M378.81,133.84l-1.26-2.76c-2.44.62-5.66.6-7.95-2.55l-.28-.38,4.91-4,.3.46a2.47,2.47,0,0,0,3.26.84,2.26,2.26,0,0,0,1.06-.84,1,1,0,0,0,0-1c-.45-1-1.54-.83-3.57-.43l-1.45.26c-2.89.44-6.09.37-7.86-3.5-1.42-3.12-.55-6.08,2.4-8.16L367.11,109l4.58-2.1,1.26,2.77a7.07,7.07,0,0,1,7.49,2.24l.33.38-4.71,3.9-.31-.43a2.26,2.26,0,0,0-3-.49c-1.08.49-1.47,1.09-1.15,1.78.43.94,1.32.82,4.08.31s7-1.22,9,3.2c1.45,3.16.5,6.2-2.6,8.41l1.27,2.77Zm-.71-4,1.21,2.63,2.73-1.24-1.21-2.64.36-.23c1.72-1.13,4.34-3.6,2.6-7.4s-5.17-3.12-8-2.64-4.4.82-5.17-.87c-.21-.46-.71-2,1.64-3.11a3.28,3.28,0,0,1,3.91.4l3.12-2.58c-1.66-1.7-3.85-2.18-6.53-1.44l-.41.11-1.21-2.63-2.76,1.27,1.21,2.63-.37.24c-2,1.29-4,3.57-2.39,7.15,1.41,3.07,3.74,3.39,6.8,2.93l1.4-.26c2-.39,3.87-.77,4.68,1a2,2,0,0,1,0,1.89,3.13,3.13,0,0,1-1.51,1.26,3.45,3.45,0,0,1-4.17-.77l-3.34,2.75c2.06,2.47,4.91,2.29,7,1.68Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 375.324px 120.37px;
            "
            id="elmpqdcze6dnr"
            class="animable"
          ></path>
          <path
            d="M408.67,254.29l-1.26-2.77c-2.44.63-5.66.61-8-2.54l-.28-.38,4.91-4,.3.45a2.45,2.45,0,0,0,3.26.84,2.19,2.19,0,0,0,1.06-.83,1,1,0,0,0,0-1c-.45-1-1.54-.83-3.57-.42-.46.09-.94.18-1.45.26-2.89.44-6.09.37-7.85-3.5-1.43-3.12-.56-6.08,2.39-8.16L397,229.46l4.58-2.09,1.27,2.77a7,7,0,0,1,7.48,2.23l.33.39-4.71,3.9-.31-.44a2.28,2.28,0,0,0-3-.49c-1.08.5-1.47,1.1-1.15,1.79.43.94,1.32.82,4.08.31s7-1.22,9,3.2c1.45,3.16.5,6.2-2.59,8.41l1.26,2.77Zm-.71-4,1.21,2.64,2.73-1.25-1.21-2.63.36-.23c1.72-1.14,4.34-3.6,2.6-7.41s-5.17-3.11-7.95-2.63-4.4.82-5.17-.88c-.21-.46-.71-2,1.64-3.11a3.3,3.3,0,0,1,3.91.41l3.12-2.59c-1.65-1.69-3.85-2.18-6.53-1.43l-.41.11-1.2-2.63L398.29,230l1.21,2.63-.37.24c-2,1.28-4,3.57-2.39,7.15,1.41,3.07,3.74,3.39,6.8,2.92.48-.07,1-.17,1.4-.26,2-.39,3.87-.76,4.68,1a2,2,0,0,1,0,1.89,3.17,3.17,0,0,1-1.51,1.25,3.46,3.46,0,0,1-4.17-.76l-3.34,2.74c2.07,2.48,4.91,2.3,7,1.69Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 405.175px 240.83px;
            "
            id="elo918u0rauv8"
            class="animable"
          ></path>
          <path
            d="M192.94,175.6l.54-3c-2.35-.89-5-2.74-5.06-6.63v-.48l6.32-.49v.55a2.46,2.46,0,0,0,2.2,2.55,2.14,2.14,0,0,0,1.34-.08,1,1,0,0,0,.56-.8c.2-1.08-.78-1.57-2.68-2.4-.43-.19-.88-.39-1.34-.61-2.62-1.3-5.2-3.18-4.44-7.36.62-3.39,3-5.31,6.64-5.33l.54-3,5,.9-.55,3a7.06,7.06,0,0,1,4.86,6.12l.05.5-6.09.51V159c0-1.44-1.2-1.95-2.2-2.13-1.16-.21-1.83.06-2,.8-.19,1,.62,1.44,3.17,2.59s6.45,3,5.58,7.8c-.62,3.42-3.14,5.37-6.94,5.41l-.55,3Zm1.68-3.66-.52,2.85,3,.54.51-2.85H198c2.06.06,5.62-.46,6.37-4.58s-2.46-5.51-5-6.71-4.08-1.84-3.75-3.68c.09-.49.59-2.07,3.13-1.61a3.29,3.29,0,0,1,3,2.57l4-.33c-.38-2.34-1.91-4-4.53-4.92l-.4-.14.52-2.84-3-.55-.52,2.85h-.44c-2.37-.09-5.34.63-6,4.49-.6,3.33,1.12,4.92,3.9,6.29.44.22.88.41,1.3.59,1.86.82,3.61,1.59,3.27,3.5a2,2,0,0,1-1.11,1.53,3.28,3.28,0,0,1-2,.16,3.44,3.44,0,0,1-3-3l-4.32.34c.28,3.2,2.72,4.69,4.77,5.37Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 197.67px 162.495px;
            "
            id="eltno47u5kbb"
            class="animable"
          ></path>
          <path
            d="M37,106l-5.21-1.16.71-3.21c-2.51-1-5.29-3.16-5.21-7.37v-.47l6.78-.27,0,.55a2.71,2.71,0,0,0,2.3,2.9c1.23.28,2,0,2.16-.91.26-1.19-.79-1.76-2.84-2.76-.45-.22-.93-.45-1.42-.71-2.77-1.51-5.48-3.65-4.48-8.12.82-3.68,3.44-5.61,7.4-5.46l.72-3.21L43.18,77l-.72,3.22a7.62,7.62,0,0,1,5,6.8l0,.5-6.54.3,0-.54c0-1.58-1.24-2.2-2.33-2.44-1.28-.29-2,0-2.21.82-.25,1.12.61,1.61,3.35,3s6.83,3.52,5.7,8.63c-.82,3.66-3.63,5.66-7.74,5.53Zm-4-1.92,3.26.72.68-3.07.43,0c2.24.15,6.14-.27,7.13-4.73s-2.43-6.11-5.17-7.52-4.31-2.16-3.88-4.1c.12-.52.71-2.18,3.41-1.58,1.81.4,2.9,1.45,3.08,2.91l4.48-.2C46.13,84,44.54,82.08,41.7,81l-.4-.15L42,77.73,38.69,77,38,80.07l-.43,0c-2.58-.2-5.85.45-6.78,4.65-.81,3.6,1,5.41,4,7,.47.25.94.48,1.38.69,2,1,3.82,1.86,3.37,3.87C39.2,97.72,38,98.34,36.17,98a3.65,3.65,0,0,1-3.07-3.39l-4.77.19c.16,3.54,2.76,5.27,5,6.1l.41.16Z"
            style="fill: rgb(240, 240, 240); transform-origin: 37.3742px 90.9px"
            id="elj1f43cdxkhn"
            class="animable"
          ></path>
          <path
            d="M174.87,73.89l-1.24,4.28-5.78-1.68,1.24-4.28c-3.81-1.72-7.15-4.87-6.71-10.23l8,.2a4.44,4.44,0,0,0,3.43,5c1.91.56,3.42.16,3.92-1.55.79-2.73-2.47-3.84-5.89-6-3.64-2.28-6.82-5.22-5.19-10.84,1.47-5.06,5.4-7.06,10.57-6.34l1.24-4.29,5.83,1.7-1.24,4.28c4.33,2,6.52,5.3,6.48,9.49l-7.62-.14c.18-2-.93-3.57-3.51-4.32-2.42-.7-3.61.13-4,1.42-.61,2.11,1.06,3.1,4.7,5.22,3.81,2.28,8.66,5.25,6.8,11.65C184.42,72.63,180.1,74.56,174.87,73.89Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 175.936px 58.165px;
            "
            id="el52e3hud7jux"
            class="animable"
          ></path>
          <path
            d="M462.69,280.44l-1.11,3.6-4.85-1.5,1.11-3.6c-3.19-1.5-6-4.21-5.52-8.73l6.75.28a3.76,3.76,0,0,0,2.82,4.25c1.61.49,2.89.18,3.33-1.25.71-2.3-2-3.29-4.88-5.12-3-2-5.68-4.51-4.22-9.24,1.31-4.24,4.67-5.87,9-5.19l1.12-3.6,4.9,1.52-1.12,3.6c3.63,1.78,5.43,4.57,5.33,8.1l-6.43-.23c.18-1.65-.73-3-2.89-3.7s-3.06.06-3.39,1.14c-.55,1.78.84,2.64,3.88,4.48,3.19,2,7.23,4.56,5.57,9.93C470.76,279.52,467.1,281.09,462.69,280.44Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 463.81px 267.19px;
            "
            id="elpm08jfu76o"
            class="animable"
          ></path>
          <path
            d="M452.19,146.77l-.67,3.71-5-.9.67-3.71c-3.36-1.12-6.44-3.47-6.55-8l6.75-.54a3.76,3.76,0,0,0,3.32,3.88c1.66.3,2.9-.17,3.16-1.65.43-2.37-2.41-3-5.47-4.5-3.27-1.6-6.2-3.79-5.33-8.67.79-4.38,3.93-6.4,8.34-6.26l.66-3.71,5.06.91-.67,3.71c3.83,1.34,5.95,3.89,6.29,7.42l-6.43.55c0-1.67-1.09-2.92-3.32-3.32s-3,.42-3.24,1.54c-.33,1.83,1.16,2.52,4.41,4,3.41,1.58,7.75,3.65,6.75,9.2C460.11,144.87,456.65,146.88,452.19,146.77Z"
            style="
              fill: rgb(240, 240, 240);
              transform-origin: 451.695px 133.45px;
            "
            id="el0rrqplxit76i"
            class="animable"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Plants--inject-42"
        class="animable"
        style="transform-origin: 245.096px 407.994px"
      >
        <g
          id="freepik--plants--inject-42"
          class="animable"
          style="transform-origin: 455.815px 407.994px"
        >
          <g
            id="freepik--plants--inject-42"
            class="animable"
            style="transform-origin: 455.815px 407.994px"
          >
            <path
              d="M455.45,401.29a8.82,8.82,0,0,1-.35-9.41c2.16-4.05,6.13-6.95,6.67-6.86s1.93,4.79,0,9.81A10.3,10.3,0,0,1,455.45,401.29Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 458.334px 393.154px;
              "
              id="eljg960ypuj2k"
              class="animable"
            ></path>
            <path
              d="M457.72,412.47a8.84,8.84,0,0,1,4-8.54c3.77-2.62,8.63-3.39,9.07-3.06s-.48,5.15-4.48,8.73A10.36,10.36,0,0,1,457.72,412.47Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 464.271px 406.678px;
              "
              id="eld4pschxbgsb"
              class="animable"
            ></path>
            <path
              d="M450.35,420.48a8.81,8.81,0,0,1-7.72-5.39c-1.92-4.17-2.12-8.67-1.72-9s5.52,1.47,8.35,5.38C452.34,415.69,450.61,419.18,450.35,420.48Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 445.904px 413.266px;
              "
              id="elgbake2uvgxg"
              class="animable"
            ></path>
            <path
              d="M454.28,419.87a94,94,0,0,0,.71,10.74c-.58.13-1.17.25-1.79.36l-.17,0a89.82,89.82,0,0,1,.55-22.58c.77-5,1.8-10.34,4.16-14.88.05-.1.32,0,.29.08a70.89,70.89,0,0,0-2.8,13.74A84.06,84.06,0,0,0,454.28,419.87Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 455.299px 412.219px;
              "
              id="elsh0kgzv7rmm"
              class="animable"
            ></path>
            <path
              d="M452.59,423.46a27.23,27.23,0,0,1-4.15-4.5,15.6,15.6,0,0,1-2.72-5.16c-.06-.26.26,0,.35.14a45,45,0,0,0,3,4.25,19.65,19.65,0,0,0,4.07,3.76,1.23,1.23,0,0,1,.45,1.33A.64.64,0,0,1,452.59,423.46Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 449.675px 418.658px;
              "
              id="elmdy8wooihn9"
              class="animable"
            ></path>
            <path
              d="M452.76,419.51c.88-2.79,2.29-4.79,4.4-7.48a25.74,25.74,0,0,1,2.69-2.93,15.77,15.77,0,0,1,3.86-2.71c.06,0,.44,0,.35,0a42.2,42.2,0,0,0-3.4,3.12,23.76,23.76,0,0,0-2.69,3.22A37.33,37.33,0,0,0,453.8,420C453.56,420.58,452.61,420,452.76,419.51Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 458.409px 413.316px;
              "
              id="ele8kqvv7bvp"
              class="animable"
            ></path>
          </g>
        </g>
        <g
          id="freepik--plants--inject-42"
          class="animable"
          style="transform-origin: 34.3815px 407.994px"
        >
          <g
            id="freepik--plants--inject-42"
            class="animable"
            style="transform-origin: 34.3815px 407.994px"
          >
            <path
              d="M34.74,401.29a8.82,8.82,0,0,0,.36-9.41c-2.16-4.05-6.14-6.95-6.68-6.86s-1.92,4.79,0,9.81A10.29,10.29,0,0,0,34.74,401.29Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 31.8605px 393.154px;
              "
              id="el40esgwjqcmj"
              class="animable"
            ></path>
            <path
              d="M32.47,412.47a8.83,8.83,0,0,0-4-8.54c-3.78-2.62-8.64-3.39-9.07-3.06s.47,5.15,4.48,8.73A10.35,10.35,0,0,0,32.47,412.47Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 25.9207px 406.678px;
              "
              id="el3v4sg9o9dkl"
              class="animable"
            ></path>
            <path
              d="M39.85,420.48a8.83,8.83,0,0,0,7.72-5.39c1.92-4.17,2.12-8.67,1.72-9s-5.52,1.47-8.35,5.38C37.85,415.69,39.58,419.18,39.85,420.48Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 44.2925px 413.266px;
              "
              id="eleuypgi2bww"
              class="animable"
            ></path>
            <path
              d="M35.91,419.87a89.63,89.63,0,0,1-.71,10.74c.59.13,1.18.25,1.79.36l.18,0a90.29,90.29,0,0,0-.55-22.58c-.78-5-1.8-10.34-4.17-14.88-.05-.1-.31,0-.28.08A72.43,72.43,0,0,1,35,407.36,85.45,85.45,0,0,1,35.91,419.87Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 34.8991px 412.219px;
              "
              id="el7brf040rsdw"
              class="animable"
            ></path>
            <path
              d="M37.61,423.46a27.17,27.17,0,0,0,4.14-4.5,15.24,15.24,0,0,0,2.72-5.16c.07-.26-.26,0-.35.14a44.93,44.93,0,0,1-3,4.25A19.65,19.65,0,0,1,37,422a1.23,1.23,0,0,0-.46,1.33A.65.65,0,0,0,37.61,423.46Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 40.4866px 418.687px;
              "
              id="elsh84bhzsusj"
              class="animable"
            ></path>
            <path
              d="M37.43,419.51c-.87-2.79-2.29-4.79-4.39-7.48a25.83,25.83,0,0,0-2.7-2.93,15.74,15.74,0,0,0-3.85-2.71c-.06,0-.45,0-.36,0a40.58,40.58,0,0,1,3.4,3.12,24.65,24.65,0,0,1,2.7,3.22A37.85,37.85,0,0,1,36.4,420C36.63,420.58,37.58,420,37.43,419.51Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 31.7813px 413.316px;
              "
              id="el69rn6bb89ug"
              class="animable"
            ></path>
          </g>
        </g>
      </g>
      <g
        id="freepik--Shadows--inject-42"
        class="animable"
        style="transform-origin: 224.076px 409.772px"
      >
        <polygon
          id="freepik--Shadow--inject-42"
          points="65.75 454.34 29.64 433.49 181.47 345.64 217.58 366.49 65.75 454.34"
          style="fill: rgb(224, 224, 224); transform-origin: 123.61px 399.99px"
          class="animable"
        ></polygon>
        <path
          id="freepik--shadow--inject-42"
          d="M327.42,436.52,263.9,473.14a7.08,7.08,0,0,1-6.4,0l-71.7-41.39a16.23,16.23,0,0,1-5.05-5.05l-2.31-4a3.71,3.71,0,0,1,1.35-5.06l57.3-33.26a7.08,7.08,0,0,1,6.4,0l83.93,48.46C329.19,433.84,329.19,435.5,327.42,436.52Z"
          style="fill: rgb(224, 224, 224); transform-origin: 253.346px 428.76px"
          class="animable"
        ></path>
        <path
          id="freepik--shadow--inject-42"
          d="M409.49,416.12c-12-6.94-31.54-6.94-43.56,0s-12,18.21,0,25.15,31.53,7,43.56,0S421.52,423.07,409.49,416.12Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 387.717px 428.706px;
          "
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Wall--inject-42"
        class="animable"
        style="transform-origin: 132.635px 327.015px"
      >
        <g
          id="freepik--wall--inject-42"
          class="animable"
          style="transform-origin: 132.635px 327.015px"
        >
          <g
            id="freepik--wall--inject-42"
            class="animable"
            style="transform-origin: 132.635px 327.015px"
          >
            <polygon
              points="206.65 364.41 206.65 209.53 201.98 206.81 58.59 289.61 58.59 442.92 63.24 447.27 206.65 364.41"
              style="
                fill: rgb(240, 240, 240);
                transform-origin: 132.62px 327.04px;
              "
              id="elfd0jo01ie9k"
              class="animable"
            ></polygon>
            <polygon
              points="174.29 361.77 206.68 343.07 208.94 344.38 208.94 365.74 176.55 384.44 174.29 383.13 174.29 361.77"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 191.615px 363.755px;
              "
              id="elz6ah4bihms"
              class="animable"
            ></polygon>
            <g id="elabqrk83i9ya">
              <polygon
                points="174.29 361.77 206.68 343.07 208.94 344.38 208.94 365.74 176.55 384.44 174.29 383.13 174.29 361.77"
                style="opacity: 0.1; transform-origin: 191.615px 363.755px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="208.94 365.74 176.55 384.44 176.55 363.08 208.94 344.38 208.94 365.74"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 192.745px 364.41px;
              "
              id="elwz2486jllr"
              class="animable"
            ></polygon>
            <g id="eltz2wexwmwde">
              <polygon
                points="208.94 365.74 176.55 384.44 176.55 363.08 208.94 344.38 208.94 365.74"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 192.745px 364.41px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elsbho7lojcyp">
              <polygon
                points="174.29 361.77 176.55 363.08 176.55 384.44 174.29 383.13 174.29 361.77"
                style="opacity: 0.1; transform-origin: 175.42px 373.105px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="137.29 383.13 169.68 364.43 171.94 365.74 171.94 387.1 139.55 405.8 137.29 404.5 137.29 383.13"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 154.615px 385.115px;
              "
              id="elo0ai3p5wd9f"
              class="animable"
            ></polygon>
            <g id="elpduh5w7dtam">
              <polygon
                points="137.29 383.13 169.68 364.43 171.94 365.74 171.94 387.1 139.55 405.8 137.29 404.5 137.29 383.13"
                style="opacity: 0.1; transform-origin: 154.615px 385.115px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="171.94 387.1 139.55 405.8 139.55 384.44 171.94 365.74 171.94 387.1"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 155.745px 385.77px;
              "
              id="elcxzzmjyvq67"
              class="animable"
            ></polygon>
            <g id="elx8h14vbw2y">
              <polygon
                points="171.94 387.1 139.55 405.8 139.55 384.44 171.94 365.74 171.94 387.1"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 155.745px 385.77px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elp2hh6unioxc">
              <polygon
                points="137.29 383.13 139.55 384.44 139.55 405.8 137.29 404.5 137.29 383.13"
                style="opacity: 0.1; transform-origin: 138.42px 394.465px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="100.29 404.5 132.68 385.79 134.94 387.1 134.94 408.46 102.55 427.16 100.29 425.86 100.29 404.5"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 117.615px 406.475px;
              "
              id="elbosu3un5ycp"
              class="animable"
            ></polygon>
            <g id="elxke4ycv869">
              <polygon
                points="100.29 404.5 132.68 385.79 134.94 387.1 134.94 408.46 102.55 427.16 100.29 425.86 100.29 404.5"
                style="opacity: 0.1; transform-origin: 117.615px 406.475px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="134.94 408.46 102.55 427.16 102.55 405.8 134.94 387.1 134.94 408.46"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 118.745px 407.13px;
              "
              id="el3w4pyoimvb3"
              class="animable"
            ></polygon>
            <g id="elzqzx902nqc">
              <polygon
                points="134.94 408.46 102.55 427.16 102.55 405.8 134.94 387.1 134.94 408.46"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 118.745px 407.13px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elc7p8gonzjq5">
              <polygon
                points="100.29 404.5 102.55 405.8 102.55 427.16 100.29 425.86 100.29 404.5"
                style="opacity: 0.1; transform-origin: 101.42px 415.83px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="63.29 425.86 95.68 407.15 97.94 408.46 97.94 429.82 65.55 448.52 63.29 447.22 63.29 425.86"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 80.615px 427.835px;
              "
              id="el8w5aoq3qb0p"
              class="animable"
            ></polygon>
            <g id="elcgqws9zd29">
              <polygon
                points="63.29 425.86 95.68 407.15 97.94 408.46 97.94 429.82 65.55 448.52 63.29 447.22 63.29 425.86"
                style="opacity: 0.1; transform-origin: 80.615px 427.835px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="97.94 429.82 65.55 448.52 65.55 427.16 97.94 408.46 97.94 429.82"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 81.745px 428.49px;
              "
              id="el684cnvep6c3"
              class="animable"
            ></polygon>
            <g id="elmqwedj4kpq">
              <polygon
                points="97.94 429.82 65.55 448.52 65.55 427.16 97.94 408.46 97.94 429.82"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 81.745px 428.49px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elxhcy6pi8ir">
              <polygon
                points="63.29 425.86 65.55 427.16 65.55 448.52 63.29 447.22 63.29 425.86"
                style="opacity: 0.1; transform-origin: 64.42px 437.19px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="65.55 427.14 65.55 448.52 56.33 443.17 56.33 421.79 65.55 427.14"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 60.94px 435.155px;
              "
              id="elbj1vim0qqrk"
              class="animable"
            ></polygon>
            <g id="elbblw76j32np">
              <polygon
                points="65.55 427.14 65.55 448.52 56.33 443.17 56.33 421.79 65.55 427.14"
                style="opacity: 0.2; transform-origin: 60.94px 435.155px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="58.59 420.48 67.81 425.86 65.55 427.16 56.33 421.79 58.59 420.48"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 62.07px 423.82px;
              "
              id="elkzxavf9c3ws"
              class="animable"
            ></polygon>
            <g id="eldxrul4t0x66">
              <polygon
                points="58.59 420.48 67.81 425.86 65.55 427.16 56.33 421.79 58.59 420.48"
                style="opacity: 0.1; transform-origin: 62.07px 423.82px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="63.3 399.14 74.83 392.45 77.09 393.76 77.09 415.12 65.56 421.81 63.3 420.5 63.3 399.14"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 70.195px 407.13px;
              "
              id="eli7nf9yqvp6"
              class="animable"
            ></polygon>
            <g id="elbtkl7oam0co">
              <polygon
                points="63.3 399.14 74.83 392.45 77.09 393.76 77.09 415.12 65.56 421.81 63.3 420.5 63.3 399.14"
                style="opacity: 0.1; transform-origin: 70.195px 407.13px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="77.09 415.12 65.56 421.81 65.56 400.44 77.09 393.76 77.09 415.12"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 71.325px 407.785px;
              "
              id="eluovemvr9f3m"
              class="animable"
            ></polygon>
            <g id="elztqpxvmrjwq">
              <polygon
                points="77.09 415.12 65.56 421.81 65.56 400.44 77.09 393.76 77.09 415.12"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 71.325px 407.785px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="eliadmwg6ug1">
              <polygon
                points="63.3 399.14 65.56 400.44 65.56 421.81 63.3 420.5 63.3 399.14"
                style="opacity: 0.1; transform-origin: 64.43px 410.475px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="153.44 347.13 185.83 328.43 188.09 329.73 188.09 351.09 155.7 369.79 153.44 368.49 153.44 347.13"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 170.765px 349.11px;
              "
              id="el40tcr7to2fx"
              class="animable"
            ></polygon>
            <g id="eljhfjx2sxn4l">
              <polygon
                points="153.44 347.13 185.83 328.43 188.09 329.73 188.09 351.09 155.7 369.79 153.44 368.49 153.44 347.13"
                style="opacity: 0.1; transform-origin: 170.765px 349.11px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="188.09 351.09 155.7 369.79 155.7 348.43 188.09 329.73 188.09 351.09"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 171.895px 349.76px;
              "
              id="ellkrffdf208j"
              class="animable"
            ></polygon>
            <g id="elrvwn7ezmbse">
              <polygon
                points="188.09 351.09 155.7 369.79 155.7 348.43 188.09 329.73 188.09 351.09"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 171.895px 349.76px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="ele3t1x7f7mbi">
              <polygon
                points="153.44 347.13 155.7 348.43 155.7 369.79 153.44 368.49 153.44 347.13"
                style="opacity: 0.1; transform-origin: 154.57px 358.46px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="116.44 368.49 148.83 349.79 151.09 351.09 151.09 372.45 118.7 391.15 116.44 389.85 116.44 368.49"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 133.765px 370.47px;
              "
              id="elpxb79ncaoqe"
              class="animable"
            ></polygon>
            <g id="el4afow8c1wpt">
              <polygon
                points="116.44 368.49 148.83 349.79 151.09 351.09 151.09 372.45 118.7 391.15 116.44 389.85 116.44 368.49"
                style="opacity: 0.1; transform-origin: 133.765px 370.47px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="151.09 372.45 118.7 391.15 118.7 369.79 151.09 351.09 151.09 372.45"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 134.895px 371.12px;
              "
              id="elu6j43mouebk"
              class="animable"
            ></polygon>
            <g id="el1y1ry44oxgp">
              <polygon
                points="151.09 372.45 118.7 391.15 118.7 369.79 151.09 351.09 151.09 372.45"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 134.895px 371.12px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="eln0pygjmc4sr">
              <polygon
                points="116.44 368.49 118.7 369.79 118.7 391.15 116.44 389.85 116.44 368.49"
                style="opacity: 0.1; transform-origin: 117.57px 379.82px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="79.44 389.85 111.83 371.15 114.09 372.45 114.09 393.81 81.7 412.52 79.44 411.21 79.44 389.85"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 96.765px 391.835px;
              "
              id="el3hwms7tqa05"
              class="animable"
            ></polygon>
            <g id="ell9eunr8w8xg">
              <polygon
                points="79.44 389.85 111.83 371.15 114.09 372.45 114.09 393.81 81.7 412.52 79.44 411.21 79.44 389.85"
                style="opacity: 0.1; transform-origin: 96.765px 391.835px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="114.09 393.81 81.7 412.52 81.7 391.15 114.09 372.45 114.09 393.81"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 97.895px 392.485px;
              "
              id="elcgu3g02kql8"
              class="animable"
            ></polygon>
            <g id="els3vxidndajp">
              <polygon
                points="114.09 393.81 81.7 412.52 81.7 391.15 114.09 372.45 114.09 393.81"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 97.895px 392.485px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="eld9eylp4b01w">
              <polygon
                points="79.44 389.85 81.7 391.15 81.7 412.52 79.44 411.21 79.44 389.85"
                style="opacity: 0.1; transform-origin: 80.57px 401.185px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="65.56 400.43 65.56 421.81 56.33 416.45 56.33 395.07 65.56 400.43"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 60.945px 408.44px;
              "
              id="elw8kzydvz0p"
              class="animable"
            ></polygon>
            <g id="elgzrjzkbfrht">
              <polygon
                points="65.56 400.43 65.56 421.81 56.33 416.45 56.33 395.07 65.56 400.43"
                style="opacity: 0.2; transform-origin: 60.945px 408.44px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="58.59 393.76 67.82 399.14 65.56 400.44 56.33 395.07 58.59 393.76"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 62.075px 397.1px;
              "
              id="elbvlweku3sk"
              class="animable"
            ></polygon>
            <g id="eliifmvrzkj0d">
              <polygon
                points="58.59 393.76 67.82 399.14 65.56 400.44 56.33 395.07 58.59 393.76"
                style="opacity: 0.1; transform-origin: 62.075px 397.1px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="190.44 325.73 206.63 316.38 208.89 317.69 208.89 339.05 192.7 348.4 190.44 347.09 190.44 325.73"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 199.665px 332.39px;
              "
              id="elopikq3el5l"
              class="animable"
            ></polygon>
            <g id="elfzmrw3o5w2k">
              <polygon
                points="190.44 325.73 206.63 316.38 208.89 317.69 208.89 339.05 192.7 348.4 190.44 347.09 190.44 325.73"
                style="opacity: 0.1; transform-origin: 199.665px 332.39px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="208.89 339.05 192.7 348.4 192.7 327.04 208.89 317.69 208.89 339.05"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 200.795px 333.045px;
              "
              id="eldxgmy649qqw"
              class="animable"
            ></polygon>
            <g id="elb0pk5koai5e">
              <polygon
                points="208.89 339.05 192.7 348.4 192.7 327.04 208.89 317.69 208.89 339.05"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 200.795px 333.045px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el5shz6852lbt">
              <polygon
                points="190.44 325.73 192.7 327.04 192.7 348.4 190.44 347.09 190.44 325.73"
                style="opacity: 0.1; transform-origin: 191.57px 337.065px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="174.29 308.37 206.68 289.67 208.94 290.97 208.94 312.33 176.55 331.03 174.29 329.73 174.29 308.37"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 191.615px 310.35px;
              "
              id="el37li26jafow"
              class="animable"
            ></polygon>
            <g id="elqzr4xugi8bh">
              <polygon
                points="174.29 308.37 206.68 289.67 208.94 290.97 208.94 312.33 176.55 331.03 174.29 329.73 174.29 308.37"
                style="opacity: 0.1; transform-origin: 191.615px 310.35px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="208.94 312.33 176.55 331.03 176.55 309.67 208.94 290.97 208.94 312.33"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 192.745px 311px;
              "
              id="elv9n3obvgxse"
              class="animable"
            ></polygon>
            <g id="elulyipi09ht">
              <polygon
                points="208.94 312.33 176.55 331.03 176.55 309.67 208.94 290.97 208.94 312.33"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 192.745px 311px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el0ebaiymiqcv">
              <polygon
                points="174.29 308.37 176.55 309.67 176.55 331.03 174.29 329.73 174.29 308.37"
                style="opacity: 0.1; transform-origin: 175.42px 319.7px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="137.29 329.73 169.68 311.03 171.94 312.33 171.94 333.69 139.55 352.4 137.29 351.09 137.29 329.73"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 154.615px 331.715px;
              "
              id="elgkm787gv9el"
              class="animable"
            ></polygon>
            <g id="elfnqrnq10uga">
              <polygon
                points="137.29 329.73 169.68 311.03 171.94 312.33 171.94 333.69 139.55 352.4 137.29 351.09 137.29 329.73"
                style="opacity: 0.1; transform-origin: 154.615px 331.715px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="171.94 333.69 139.55 352.4 139.55 331.03 171.94 312.33 171.94 333.69"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 155.745px 332.365px;
              "
              id="el3j1sijjur6m"
              class="animable"
            ></polygon>
            <g id="elvedsg0tmtr">
              <polygon
                points="171.94 333.69 139.55 352.4 139.55 331.03 171.94 312.33 171.94 333.69"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 155.745px 332.365px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el1t5ie73a3p6">
              <polygon
                points="137.29 329.73 139.55 331.03 139.55 352.4 137.29 351.09 137.29 329.73"
                style="opacity: 0.1; transform-origin: 138.42px 341.065px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="100.29 351.09 132.68 332.39 134.94 333.69 134.94 355.06 102.55 373.76 100.29 372.45 100.29 351.09"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 117.615px 353.075px;
              "
              id="el3k7tdklk5a3"
              class="animable"
            ></polygon>
            <g id="eleei87ey0rzv">
              <polygon
                points="100.29 351.09 132.68 332.39 134.94 333.69 134.94 355.06 102.55 373.76 100.29 372.45 100.29 351.09"
                style="opacity: 0.1; transform-origin: 117.615px 353.075px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="134.94 355.06 102.55 373.76 102.55 352.4 134.94 333.69 134.94 355.06"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 118.745px 353.725px;
              "
              id="elshbma9s1lv"
              class="animable"
            ></polygon>
            <g id="elv9s6vmme51">
              <polygon
                points="134.94 355.06 102.55 373.76 102.55 352.4 134.94 333.69 134.94 355.06"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 118.745px 353.725px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elujv59j7v8n">
              <polygon
                points="100.29 351.09 102.55 352.4 102.55 373.76 100.29 372.45 100.29 351.09"
                style="opacity: 0.1; transform-origin: 101.42px 362.425px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="63.29 372.45 95.68 353.75 97.94 355.06 97.94 376.42 65.55 395.12 63.29 393.81 63.29 372.45"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 80.615px 374.435px;
              "
              id="el7msc3p4r924"
              class="animable"
            ></polygon>
            <g id="elsat31t77wy">
              <polygon
                points="63.29 372.45 95.68 353.75 97.94 355.06 97.94 376.42 65.55 395.12 63.29 393.81 63.29 372.45"
                style="opacity: 0.1; transform-origin: 80.615px 374.435px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="97.94 376.42 65.55 395.12 65.55 373.76 97.94 355.06 97.94 376.42"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 81.745px 375.09px;
              "
              id="elsn53aftksf"
              class="animable"
            ></polygon>
            <g id="el7x5j0z9k1po">
              <polygon
                points="97.94 376.42 65.55 395.12 65.55 373.76 97.94 355.06 97.94 376.42"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 81.745px 375.09px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elx18c0ht8duc">
              <polygon
                points="63.29 372.45 65.55 373.76 65.55 395.12 63.29 393.81 63.29 372.45"
                style="opacity: 0.1; transform-origin: 64.42px 383.785px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="65.55 373.74 65.55 395.12 56.33 389.76 56.33 368.38 65.55 373.74"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 60.94px 381.75px;
              "
              id="eluobw1cs13gs"
              class="animable"
            ></polygon>
            <g id="elhrx41tb5f65">
              <polygon
                points="65.55 373.74 65.55 395.12 56.33 389.76 56.33 368.38 65.55 373.74"
                style="opacity: 0.2; transform-origin: 60.94px 381.75px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="58.59 367.08 67.81 372.45 65.55 373.76 56.33 368.38 58.59 367.08"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 62.07px 370.42px;
              "
              id="elha3mwzzz1c"
              class="animable"
            ></polygon>
            <g id="els8ea1zta5s">
              <polygon
                points="58.59 367.08 67.81 372.45 65.55 373.76 56.33 368.38 58.59 367.08"
                style="opacity: 0.1; transform-origin: 62.07px 370.42px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="63.3 345.67 74.83 338.98 77.09 340.29 77.09 361.65 65.56 368.34 63.3 367.03 63.3 345.67"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 70.195px 353.66px;
              "
              id="ely3h9w4jykxh"
              class="animable"
            ></polygon>
            <g id="elanlrtbsnafe">
              <polygon
                points="63.3 345.67 74.83 338.98 77.09 340.29 77.09 361.65 65.56 368.34 63.3 367.03 63.3 345.67"
                style="opacity: 0.1; transform-origin: 70.195px 353.66px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="77.09 361.65 65.56 368.34 65.56 346.98 77.09 340.29 77.09 361.65"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 71.325px 354.315px;
              "
              id="el1rhqr16h3tii"
              class="animable"
            ></polygon>
            <g id="elgms53eizrwb">
              <polygon
                points="77.09 361.65 65.56 368.34 65.56 346.98 77.09 340.29 77.09 361.65"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 71.325px 354.315px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="els15rnzixr7">
              <polygon
                points="63.3 345.67 65.56 346.98 65.56 368.34 63.3 367.03 63.3 345.67"
                style="opacity: 0.1; transform-origin: 64.43px 357.005px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="153.44 293.66 185.83 274.96 188.09 276.26 188.09 297.62 155.7 316.32 153.44 315.02 153.44 293.66"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 170.765px 295.64px;
              "
              id="el1xijcxq4rt1"
              class="animable"
            ></polygon>
            <g id="elx4qz3nrnu7f">
              <polygon
                points="153.44 293.66 185.83 274.96 188.09 276.26 188.09 297.62 155.7 316.32 153.44 315.02 153.44 293.66"
                style="opacity: 0.1; transform-origin: 170.765px 295.64px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="188.09 297.62 155.7 316.32 155.7 294.96 188.09 276.26 188.09 297.62"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 171.895px 296.29px;
              "
              id="elfk8k0shvirg"
              class="animable"
            ></polygon>
            <g id="elrqltca07tfr">
              <polygon
                points="188.09 297.62 155.7 316.32 155.7 294.96 188.09 276.26 188.09 297.62"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 171.895px 296.29px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elixva54u7vi">
              <polygon
                points="153.44 293.66 155.7 294.96 155.7 316.32 153.44 315.02 153.44 293.66"
                style="opacity: 0.1; transform-origin: 154.57px 304.99px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="116.44 315.02 148.83 296.32 151.09 297.62 151.09 318.99 118.7 337.69 116.44 336.38 116.44 315.02"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 133.765px 317.005px;
              "
              id="elwrsivax4aze"
              class="animable"
            ></polygon>
            <g id="eltksse9n1uxo">
              <polygon
                points="116.44 315.02 148.83 296.32 151.09 297.62 151.09 318.99 118.7 337.69 116.44 336.38 116.44 315.02"
                style="opacity: 0.1; transform-origin: 133.765px 317.005px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="151.09 318.99 118.7 337.69 118.7 316.32 151.09 297.62 151.09 318.99"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 134.895px 317.655px;
              "
              id="elbhhyi8i3jgi"
              class="animable"
            ></polygon>
            <g id="elarqibs6uxb">
              <polygon
                points="151.09 318.99 118.7 337.69 118.7 316.32 151.09 297.62 151.09 318.99"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 134.895px 317.655px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el7gqmgehlogc">
              <polygon
                points="116.44 315.02 118.7 316.32 118.7 337.69 116.44 336.38 116.44 315.02"
                style="opacity: 0.1; transform-origin: 117.57px 326.355px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="79.44 336.38 111.83 317.68 114.09 318.99 114.09 340.35 81.7 359.05 79.44 357.74 79.44 336.38"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 96.765px 338.365px;
              "
              id="el1doziyzmktx"
              class="animable"
            ></polygon>
            <g id="elndfaut9zyxn">
              <polygon
                points="79.44 336.38 111.83 317.68 114.09 318.99 114.09 340.35 81.7 359.05 79.44 357.74 79.44 336.38"
                style="opacity: 0.1; transform-origin: 96.765px 338.365px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="114.09 340.35 81.7 359.05 81.7 337.69 114.09 318.99 114.09 340.35"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 97.895px 339.02px;
              "
              id="elzc1bmzbn88d"
              class="animable"
            ></polygon>
            <g id="elir0enh2a2v">
              <polygon
                points="114.09 340.35 81.7 359.05 81.7 337.69 114.09 318.99 114.09 340.35"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 97.895px 339.02px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el20t6im7p0ii">
              <polygon
                points="79.44 336.38 81.7 337.69 81.7 359.05 79.44 357.74 79.44 336.38"
                style="opacity: 0.1; transform-origin: 80.57px 347.715px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="65.56 346.96 65.56 368.34 56.33 362.98 56.33 341.6 65.56 346.96"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 60.945px 354.97px;
              "
              id="elzu13rli27c7"
              class="animable"
            ></polygon>
            <g id="elcjfezxddxzw">
              <polygon
                points="65.56 346.96 65.56 368.34 56.33 362.98 56.33 341.6 65.56 346.96"
                style="opacity: 0.2; transform-origin: 60.945px 354.97px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="58.59 340.3 67.82 345.67 65.56 346.98 56.33 341.6 58.59 340.3"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 62.075px 343.64px;
              "
              id="el7xt4as9xyke"
              class="animable"
            ></polygon>
            <g id="elj6qv9kdk73">
              <polygon
                points="58.59 340.3 67.82 345.67 65.56 346.98 56.33 341.6 58.59 340.3"
                style="opacity: 0.1; transform-origin: 62.075px 343.64px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="190.44 272.22 206.63 262.87 208.89 264.17 208.89 285.53 192.7 294.88 190.44 293.58 190.44 272.22"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 199.665px 278.875px;
              "
              id="el5s1lmguxmdf"
              class="animable"
            ></polygon>
            <g id="eltbrzqyrzcu">
              <polygon
                points="190.44 272.22 206.63 262.87 208.89 264.17 208.89 285.53 192.7 294.88 190.44 293.58 190.44 272.22"
                style="opacity: 0.1; transform-origin: 199.665px 278.875px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="208.89 285.53 192.7 294.88 192.7 273.52 208.89 264.17 208.89 285.53"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 200.795px 279.525px;
              "
              id="elhq2r00fbwfv"
              class="animable"
            ></polygon>
            <g id="el9y8a7son3zo">
              <polygon
                points="208.89 285.53 192.7 294.88 192.7 273.52 208.89 264.17 208.89 285.53"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 200.795px 279.525px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elm004f7nakv">
              <polygon
                points="190.44 272.22 192.7 273.52 192.7 294.88 190.44 293.58 190.44 272.22"
                style="opacity: 0.1; transform-origin: 191.57px 283.55px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="174.29 254.97 206.68 236.27 208.94 237.57 208.94 258.93 176.55 277.63 174.29 276.33 174.29 254.97"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 191.615px 256.95px;
              "
              id="elpnrkdny00df"
              class="animable"
            ></polygon>
            <g id="elf0kk0o4fhzs">
              <polygon
                points="174.29 254.97 206.68 236.27 208.94 237.57 208.94 258.93 176.55 277.63 174.29 276.33 174.29 254.97"
                style="opacity: 0.1; transform-origin: 191.615px 256.95px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="208.94 258.93 176.55 277.63 176.55 256.27 208.94 237.57 208.94 258.93"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 192.745px 257.6px;
              "
              id="eld8bhjue7ni"
              class="animable"
            ></polygon>
            <g id="elhre92xj59wa">
              <polygon
                points="208.94 258.93 176.55 277.63 176.55 256.27 208.94 237.57 208.94 258.93"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 192.745px 257.6px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el2vsu64n33ox">
              <polygon
                points="174.29 254.97 176.55 256.27 176.55 277.63 174.29 276.33 174.29 254.97"
                style="opacity: 0.1; transform-origin: 175.42px 266.3px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="137.29 276.33 169.68 257.63 171.94 258.93 171.94 280.3 139.55 299 137.29 297.69 137.29 276.33"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 154.615px 278.315px;
              "
              id="el3jvuf7ymphc"
              class="animable"
            ></polygon>
            <g id="elx8nxz4fje3g">
              <polygon
                points="137.29 276.33 169.68 257.63 171.94 258.93 171.94 280.3 139.55 299 137.29 297.69 137.29 276.33"
                style="opacity: 0.1; transform-origin: 154.615px 278.315px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="171.94 280.3 139.55 299 139.55 277.63 171.94 258.93 171.94 280.3"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 155.745px 278.965px;
              "
              id="elwo3bclvhp7e"
              class="animable"
            ></polygon>
            <g id="elpow32uqzoc">
              <polygon
                points="171.94 280.3 139.55 299 139.55 277.63 171.94 258.93 171.94 280.3"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 155.745px 278.965px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elg9ulsettnku">
              <polygon
                points="137.29 276.33 139.55 277.63 139.55 299 137.29 297.69 137.29 276.33"
                style="opacity: 0.1; transform-origin: 138.42px 287.665px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="100.29 297.69 132.68 278.99 134.94 280.3 134.94 301.66 102.55 320.36 100.29 319.05 100.29 297.69"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 117.615px 299.675px;
              "
              id="elvtf0pwkbgkn"
              class="animable"
            ></polygon>
            <g id="elozdg2ccjp3">
              <polygon
                points="100.29 297.69 132.68 278.99 134.94 280.3 134.94 301.66 102.55 320.36 100.29 319.05 100.29 297.69"
                style="opacity: 0.1; transform-origin: 117.615px 299.675px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="134.94 301.66 102.55 320.36 102.55 299 134.94 280.3 134.94 301.66"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 118.745px 300.33px;
              "
              id="elsj3luzy8s3"
              class="animable"
            ></polygon>
            <g id="eloui5ojlwk0o">
              <polygon
                points="134.94 301.68 102.55 320.38 102.55 299.02 134.94 280.32 134.94 301.68"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 118.745px 300.35px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el7k5i2q3g0ix">
              <polygon
                points="100.29 297.69 102.55 299 102.55 320.36 100.29 319.05 100.29 297.69"
                style="opacity: 0.1; transform-origin: 101.42px 309.025px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="63.29 319.05 95.68 300.35 97.94 301.66 97.94 323.02 65.55 341.72 63.29 340.41 63.29 319.05"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 80.615px 321.035px;
              "
              id="eltvu7ukb3hoi"
              class="animable"
            ></polygon>
            <g id="ellic2rh4prph">
              <polygon
                points="63.29 319.05 95.68 300.35 97.94 301.66 97.94 323.02 65.55 341.72 63.29 340.41 63.29 319.05"
                style="opacity: 0.1; transform-origin: 80.615px 321.035px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="97.94 323.02 65.55 341.72 65.55 320.36 97.94 301.66 97.94 323.02"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 81.745px 321.69px;
              "
              id="el7g7dxqs95j2"
              class="animable"
            ></polygon>
            <g id="elz57aulkzpla">
              <polygon
                points="97.94 323.02 65.55 341.72 65.55 320.36 97.94 301.66 97.94 323.02"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 81.745px 321.69px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elaz6fjo0nfe7">
              <polygon
                points="63.29 319.05 65.55 320.36 65.55 341.72 63.29 340.41 63.29 319.05"
                style="opacity: 0.1; transform-origin: 64.42px 330.385px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="65.55 320.34 65.55 341.72 56.33 336.36 56.33 314.98 65.55 320.34"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 60.94px 328.35px;
              "
              id="el2htam2s4vtp"
              class="animable"
            ></polygon>
            <g id="el0sfr8l91a62g">
              <polygon
                points="65.55 320.34 65.55 341.72 56.33 336.36 56.33 314.98 65.55 320.34"
                style="opacity: 0.2; transform-origin: 60.94px 328.35px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="58.59 313.68 67.81 319.05 65.55 320.36 56.33 314.98 58.59 313.68"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 62.07px 317.02px;
              "
              id="elfsn52vfjpb"
              class="animable"
            ></polygon>
            <g id="eluznqq1z1jj8">
              <polygon
                points="58.59 313.68 67.81 319.05 65.55 320.36 56.33 314.98 58.59 313.68"
                style="opacity: 0.1; transform-origin: 62.07px 317.02px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="63.3 292.23 74.83 285.55 77.09 286.85 77.09 308.21 65.56 314.9 63.3 313.59 63.3 292.23"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 70.195px 300.225px;
              "
              id="elbhkycc4djk7"
              class="animable"
            ></polygon>
            <polygon
              points="67.87 281.48 77.09 286.85 65.56 293.54 56.33 288.16 67.87 281.48"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 66.71px 287.51px;
              "
              id="eldm0pv09p59j"
              class="animable"
            ></polygon>
            <polygon
              points="77.09 308.21 65.56 314.9 65.56 293.54 77.09 286.85 77.09 308.21"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 71.325px 300.875px;
              "
              id="ele5z4wvt3zys"
              class="animable"
            ></polygon>
            <g id="elpuo4xeesxmj">
              <polygon
                points="77.09 308.21 65.56 314.9 65.56 293.54 77.09 286.85 77.09 308.21"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 71.325px 300.875px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="eln98t4725bwa">
              <polygon
                points="63.3 292.23 65.56 293.54 65.56 314.9 63.3 313.59 63.3 292.23"
                style="opacity: 0.1; transform-origin: 64.43px 303.565px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="153.44 240.22 185.83 221.52 188.09 222.82 188.09 244.19 155.7 262.89 153.44 261.58 153.44 240.22"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 170.765px 242.205px;
              "
              id="elsrl6js21gn"
              class="animable"
            ></polygon>
            <polygon
              points="178.87 217.45 188.09 222.82 155.7 241.53 146.48 236.15 178.87 217.45"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 167.285px 229.49px;
              "
              id="elwqm5x3uk9e"
              class="animable"
            ></polygon>
            <polygon
              points="188.09 244.19 155.7 262.89 155.7 241.53 188.09 222.82 188.09 244.19"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 171.895px 242.855px;
              "
              id="eldboe95iy1fo"
              class="animable"
            ></polygon>
            <g id="eldshdvzxrc5r">
              <polygon
                points="188.09 244.19 155.7 262.89 155.7 241.53 188.09 222.82 188.09 244.19"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 171.895px 242.855px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="ely41rmvg9qi">
              <polygon
                points="153.44 240.22 155.7 241.53 155.7 262.89 153.44 261.58 153.44 240.22"
                style="opacity: 0.1; transform-origin: 154.57px 251.555px"
                class="animable"
              ></polygon>
            </g>
            <g id="eljqx875swqf">
              <polygon
                points="153.44 240.22 155.7 241.53 155.7 262.89 153.44 261.58 153.44 240.22"
                style="opacity: 0.1; transform-origin: 154.57px 251.555px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="155.7 241.53 155.7 244.2 146.48 238.85 146.48 236.15 155.7 241.53"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 151.09px 240.175px;
              "
              id="elet4npiqef3p"
              class="animable"
            ></polygon>
            <g id="eljvpy2jpzs9">
              <polygon
                points="155.7 241.53 155.7 244.2 146.48 238.85 146.48 236.15 155.7 241.53"
                style="opacity: 0.2; transform-origin: 151.09px 240.175px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="65.56 293.52 65.56 314.9 56.33 309.54 56.33 288.16 65.56 293.52"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 60.945px 301.53px;
              "
              id="el9tcr8fzk67e"
              class="animable"
            ></polygon>
            <g id="elgkumzcpqxae">
              <polygon
                points="65.56 293.52 65.56 314.9 56.33 309.54 56.33 288.16 65.56 293.52"
                style="opacity: 0.2; transform-origin: 60.945px 301.53px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="58.59 286.86 67.82 292.23 65.56 293.54 56.33 288.16 58.59 286.86"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 62.075px 290.2px;
              "
              id="elpq69iq6wkt"
              class="animable"
            ></polygon>
            <polygon
              points="116.44 261.58 148.83 242.88 151.09 244.19 151.09 265.55 118.7 284.25 116.44 282.94 116.44 261.58"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 133.765px 263.565px;
              "
              id="el2ztjkladwc5"
              class="animable"
            ></polygon>
            <polygon
              points="141.87 238.81 151.09 244.19 118.7 262.89 109.48 257.51 141.87 238.81"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 130.285px 250.85px;
              "
              id="elw7gjo4punvb"
              class="animable"
            ></polygon>
            <polygon
              points="151.09 265.55 118.7 284.25 118.7 262.89 151.09 244.19 151.09 265.55"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 134.895px 264.22px;
              "
              id="el13jrsxh4dl7"
              class="animable"
            ></polygon>
            <g id="el737esyu3fim">
              <polygon
                points="151.09 265.55 118.7 284.25 118.7 262.89 151.09 244.19 151.09 265.55"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 134.895px 264.22px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elf5gvsu48ed">
              <polygon
                points="116.44 261.58 118.7 262.89 118.7 284.25 116.44 282.94 116.44 261.58"
                style="opacity: 0.1; transform-origin: 117.57px 272.915px"
                class="animable"
              ></polygon>
            </g>
            <g id="elfvgxytbpmyj">
              <polygon
                points="116.44 261.58 118.7 262.89 118.7 284.25 116.44 282.94 116.44 261.58"
                style="opacity: 0.1; transform-origin: 117.57px 272.915px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="118.7 262.89 118.7 265.56 109.48 260.21 109.48 257.51 118.7 262.89"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 114.09px 261.535px;
              "
              id="els2q9zgx1api"
              class="animable"
            ></polygon>
            <g id="elxujm1cwghnj">
              <polygon
                points="118.7 262.89 118.7 265.56 109.48 260.21 109.48 257.51 118.7 262.89"
                style="opacity: 0.2; transform-origin: 114.09px 261.535px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="79.44 282.94 111.83 264.24 114.09 265.55 114.09 286.91 81.7 305.61 79.44 304.3 79.44 282.94"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 96.765px 284.925px;
              "
              id="elds6tcz794u"
              class="animable"
            ></polygon>
            <polygon
              points="104.87 260.17 114.09 265.55 81.7 284.25 72.48 278.87 104.87 260.17"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 93.285px 272.21px;
              "
              id="elwr6ucn6y25"
              class="animable"
            ></polygon>
            <polygon
              points="114.09 286.91 81.7 305.61 81.7 284.25 114.09 265.55 114.09 286.91"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 97.895px 285.58px;
              "
              id="el0x3rwee7uof"
              class="animable"
            ></polygon>
            <g id="el75tc0zq11mn">
              <polygon
                points="114.09 286.91 81.7 305.61 81.7 284.25 114.09 265.55 114.09 286.91"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 97.895px 285.58px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="elerxv84sr84t">
              <polygon
                points="79.44 282.94 81.7 284.25 81.7 305.61 79.44 304.3 79.44 282.94"
                style="opacity: 0.1; transform-origin: 80.57px 294.275px"
                class="animable"
              ></polygon>
            </g>
            <g id="elc8uqthckcd">
              <polygon
                points="79.44 282.94 81.7 284.25 81.7 305.61 79.44 304.3 79.44 282.94"
                style="opacity: 0.1; transform-origin: 80.57px 294.275px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="81.7 284.25 81.7 286.93 72.48 281.57 72.48 278.87 81.7 284.25"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 77.09px 282.9px;
              "
              id="el7hm9pui17f2"
              class="animable"
            ></polygon>
            <g id="elo1jrjyht2p">
              <polygon
                points="81.7 284.25 81.7 286.93 72.48 281.57 72.48 278.87 81.7 284.25"
                style="opacity: 0.2; transform-origin: 77.09px 282.9px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="190.44 218.92 206.63 209.58 208.89 210.88 208.89 232.24 192.7 241.59 190.44 240.28 190.44 218.92"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 199.665px 225.585px;
              "
              id="elu1ckhvow4fi"
              class="animable"
            ></polygon>
            <g id="elvxgw4tptysr">
              <polygon
                points="190.44 218.92 206.63 209.58 208.89 210.88 208.89 232.24 192.7 241.59 190.44 240.28 190.44 218.92"
                style="opacity: 0.1; transform-origin: 199.665px 225.585px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="208.89 232.24 192.7 241.59 192.7 220.22 208.89 210.88 208.89 232.24"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 200.795px 226.235px;
              "
              id="elyh5edkpr0i"
              class="animable"
            ></polygon>
            <g id="el6dzdth7f23t">
              <polygon
                points="208.89 232.24 192.7 241.59 192.7 220.22 208.89 210.88 208.89 232.24"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 200.795px 226.235px;
                "
                class="animable"
              ></polygon>
            </g>
            <g id="el9u3a3wbq89p">
              <polygon
                points="190.44 218.92 192.7 220.22 192.7 241.59 190.44 240.28 190.44 218.92"
                style="opacity: 0.1; transform-origin: 191.57px 230.255px"
                class="animable"
              ></polygon>
            </g>
            <polygon
              points="199.67 205.51 208.89 210.88 192.7 220.22 183.47 214.85 199.67 205.51"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 196.18px 212.865px;
              "
              id="ele86ao60rnou"
              class="animable"
            ></polygon>
            <polygon
              points="192.7 220.22 192.7 222.9 183.47 217.55 183.47 214.85 192.7 220.22"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 188.085px 218.875px;
              "
              id="el7gv8ff974m2"
              class="animable"
            ></polygon>
            <g id="elkujcmvm0jai">
              <polygon
                points="192.7 220.22 192.7 222.9 183.47 217.55 183.47 214.85 192.7 220.22"
                style="opacity: 0.2; transform-origin: 188.085px 218.875px"
                class="animable"
              ></polygon>
            </g>
          </g>
          <g
            id="freepik--Bills--inject-42"
            class="animable"
            style="transform-origin: 126.805px 335.75px"
          >
            <g id="elzt3veek33al">
              <path
                d="M109,325,86.75,337.88A6.47,6.47,0,0,1,84,343.07V351c1.51-.87,2.72,0,2.72,2.06L109,340.16a6.52,6.52,0,0,1,2.72-5.2v-7.9C110.26,327.93,109,327,109,325ZM89,346.8c-1.1.64-2,0-2-1.52a4.83,4.83,0,0,1,2-3.83c1.12-.64,2,.05,2,1.52A4.78,4.78,0,0,1,89,346.8Zm8.86-2.25c-2.29,1.32-4.15-.08-4.15-3.14s1.86-6.61,4.15-7.93,4.15.08,4.15,3.14S100.19,343.23,97.9,344.55Zm8.85-8c-1.1.64-2-.05-2-1.52a4.83,4.83,0,0,1,2-3.83c1.11-.64,2,0,2,1.52A4.8,4.8,0,0,1,106.75,336.58Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.3;
                  transform-origin: 97.86px 339.03px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M97.31,337.59a1,1,0,0,1,.59-.8,1.65,1.65,0,0,1,1.48-.16l.92-1.95a1.73,1.73,0,0,0-1.76-.2v-1.9l-1.22.71v1.91a4.57,4.57,0,0,0-1.79,3.45c0,2.73,3.07.78,3.07,1.77,0,.29-.21.56-.61.79a2.55,2.55,0,0,1-1.94.25l-.81,2.14a2.45,2.45,0,0,0,2.08-.05v2.13l1.22-.7v-2.2a4.78,4.78,0,0,0,1.84-3.56C100.38,336.57,97.31,338.4,97.31,337.59Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 97.81px 339.13px;
              "
              id="el2rpt9559ocq"
              class="animable"
            ></path>
            <g id="elju13sl2m6e7">
              <path
                d="M166.89,318.36,144.6,331.23a6.5,6.5,0,0,1-2.72,5.19v7.9c1.5-.87,2.72.05,2.72,2.06l22.29-12.87a6.52,6.52,0,0,1,2.72-5.2v-7.9C168.11,321.28,166.89,320.37,166.89,318.36Zm-20,21.79c-1.11.64-2,0-2-1.51a4.81,4.81,0,0,1,2-3.84c1.11-.64,2,.05,2,1.52A4.8,4.8,0,0,1,146.89,340.15Zm8.85-2.25c-2.28,1.32-4.14-.08-4.14-3.14s1.86-6.61,4.14-7.93,4.15.08,4.15,3.14S158,336.58,155.74,337.9Zm8.85-8c-1.1.64-2-.05-2-1.52a4.82,4.82,0,0,1,2-3.83c1.11-.64,2,.05,2,1.52A4.81,4.81,0,0,1,164.59,329.93Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 155.745px 332.37px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M155.16,330.94a1,1,0,0,1,.58-.8,1.67,1.67,0,0,1,1.49-.16l.91-2a1.71,1.71,0,0,0-1.75-.2v-1.9l-1.22.71v1.91a4.58,4.58,0,0,0-1.8,3.45c0,2.73,3.07.78,3.07,1.77,0,.29-.21.56-.61.79a2.55,2.55,0,0,1-1.94.25l-.8,2.14a2.45,2.45,0,0,0,2.08-.05V339l1.22-.7v-2.2a4.82,4.82,0,0,0,1.84-3.56C158.23,329.92,155.16,331.75,155.16,330.94Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 155.66px 332.44px;
              "
              id="elcdimdp4h81s"
              class="animable"
            ></path>
            <g id="elfwz6gr10z3c">
              <path
                d="M109,378.48,86.75,391.35A6.47,6.47,0,0,1,84,396.54v7.9c1.51-.87,2.72.05,2.72,2.06L109,393.63a6.52,6.52,0,0,1,2.72-5.2v-7.9C110.26,381.4,109,380.49,109,378.48ZM89,400.27c-1.1.64-2,0-2-1.51a4.83,4.83,0,0,1,2-3.84c1.11-.64,2,.05,2,1.52A4.81,4.81,0,0,1,89,400.27ZM97.89,398c-2.28,1.32-4.14-.08-4.14-3.14s1.86-6.61,4.14-7.93,4.15.08,4.15,3.14S100.19,396.7,97.89,398Zm8.86-8c-1.11.64-2-.05-2-1.52a4.8,4.8,0,0,1,2-3.83c1.11-.64,2,.05,2,1.52A4.8,4.8,0,0,1,106.75,390.05Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 97.86px 392.49px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M97.31,391.06a1,1,0,0,1,.59-.8,1.64,1.64,0,0,1,1.48-.16l.91-2a1.7,1.7,0,0,0-1.75-.2v-1.9l-1.22.71v1.91a4.58,4.58,0,0,0-1.8,3.45c0,2.73,3.07.78,3.07,1.77,0,.29-.2.56-.6.79a2.56,2.56,0,0,1-1.94.25l-.81,2.15a2.49,2.49,0,0,0,2.08-.06v2.14l1.22-.71v-2.2a4.8,4.8,0,0,0,1.84-3.56C100.38,390,97.31,391.87,97.31,391.06Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 97.81px 392.555px;
              "
              id="elyqudyuhl4w"
              class="animable"
            ></path>
            <g id="elp39ysb1fgb">
              <path
                d="M166.89,265,144.6,277.82a6.49,6.49,0,0,1-2.72,5.2v7.9c1.51-.87,2.72,0,2.72,2.05l22.29-12.86a6.5,6.5,0,0,1,2.72-5.2V267C168.11,267.88,166.89,267,166.89,265Zm-20,21.8c-1.1.64-2-.05-2-1.52a4.83,4.83,0,0,1,2-3.83c1.11-.64,2,.05,2,1.52A4.81,4.81,0,0,1,146.89,286.75Zm8.85-2.25c-2.28,1.32-4.14-.09-4.14-3.14s1.86-6.61,4.14-7.93,4.15.08,4.15,3.14S158,283.17,155.74,284.5Zm8.86-8c-1.11.63-2,0-2-1.52a4.8,4.8,0,0,1,2-3.83c1.11-.64,2,0,2,1.52A4.8,4.8,0,0,1,164.6,276.53Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 155.745px 278.985px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M155.16,277.54a1,1,0,0,1,.59-.8,1.64,1.64,0,0,1,1.48-.16l.91-1.95a1.68,1.68,0,0,0-1.75-.2v-1.9l-1.22.71v1.91a4.58,4.58,0,0,0-1.8,3.45c0,2.73,3.07.78,3.07,1.77,0,.29-.2.55-.61.79a2.48,2.48,0,0,1-1.93.24l-.81,2.15a2.45,2.45,0,0,0,2.08-.06v2.14l1.22-.7v-2.2a4.84,4.84,0,0,0,1.84-3.57C158.23,276.52,155.16,278.35,155.16,277.54Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 155.66px 279.08px;
              "
              id="elaav6pr2z29d"
              class="animable"
            ></path>
            <g id="el30k39o5x0rq">
              <path
                d="M166.89,371.76,144.6,384.63a6.52,6.52,0,0,1-2.72,5.2v7.89c1.5-.87,2.72,0,2.72,2.06l22.29-12.87a6.52,6.52,0,0,1,2.72-5.2v-7.89C168.11,374.68,166.89,373.77,166.89,371.76Zm-20,21.79c-1.11.64-2,0-2-1.51a4.79,4.79,0,0,1,2-3.83c1.11-.64,2,0,2,1.51A4.8,4.8,0,0,1,146.89,393.55Zm8.85-2.25c-2.28,1.32-4.14-.08-4.14-3.14s1.86-6.6,4.14-7.92,4.15.08,4.15,3.13S158,390,155.74,391.3Zm8.85-8c-1.1.64-2-.05-2-1.52a4.78,4.78,0,0,1,2-3.82c1.12-.65,2,0,2,1.51A4.78,4.78,0,0,1,164.59,383.33Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 155.745px 385.77px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M155.16,384.35a1,1,0,0,1,.58-.81,1.67,1.67,0,0,1,1.49-.16l.91-1.95a1.69,1.69,0,0,0-1.75-.19v-1.9l-1.22.7V382a4.55,4.55,0,0,0-1.8,3.44c0,2.73,3.07.79,3.07,1.77,0,.29-.2.56-.61.79a2.51,2.51,0,0,1-1.94.25l-.8,2.15a2.45,2.45,0,0,0,2.08-.06v2.14l1.22-.7v-2.21a4.8,4.8,0,0,0,1.84-3.56C158.23,383.33,155.16,385.16,155.16,384.35Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 155.66px 385.91px;
              "
              id="elvp5f77g40i"
              class="animable"
            ></path>
            <g id="elrgnckub35np">
              <path
                d="M109,271.57,86.75,284.44a6.51,6.51,0,0,1-2.72,5.2v7.89c1.51-.87,2.72.05,2.72,2.06L109,286.72a6.52,6.52,0,0,1,2.72-5.2v-7.9C110.26,274.49,109,273.58,109,271.57ZM89,293.36c-1.1.64-2,0-2-1.51A4.81,4.81,0,0,1,89,288c1.12-.65,2,0,2,1.51A4.78,4.78,0,0,1,89,293.36Zm8.86-2.25c-2.29,1.32-4.15-.08-4.15-3.14s1.86-6.61,4.15-7.93,4.15.08,4.15,3.14S100.19,289.79,97.9,291.11Zm8.85-8c-1.1.64-2-.05-2-1.52a4.83,4.83,0,0,1,2-3.83c1.11-.64,2,0,2,1.52A4.8,4.8,0,0,1,106.75,283.14Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.25;
                  transform-origin: 97.875px 285.58px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M97.31,284.15a1,1,0,0,1,.59-.8,1.65,1.65,0,0,1,1.48-.16l.92-1.95a1.73,1.73,0,0,0-1.76-.2v-1.89l-1.22.7v1.91a4.57,4.57,0,0,0-1.79,3.45c0,2.73,3.07.78,3.07,1.77,0,.29-.21.56-.61.79a2.55,2.55,0,0,1-1.94.25l-.81,2.15a2.45,2.45,0,0,0,2.08-.06v2.14l1.22-.71v-2.2a4.77,4.77,0,0,0,1.84-3.56C100.38,283.14,97.31,285,97.31,284.15Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 97.81px 285.7px;
              "
              id="elond1lcsjybq"
              class="animable"
            ></path>
          </g>
        </g>
      </g>
      <g
        id="freepik--Crane--inject-42"
        class="animable"
        style="transform-origin: 207.225px 206.66px"
      >
        <g
          id="freepik--crane--inject-42"
          class="animable"
          style="transform-origin: 207.225px 206.66px"
        >
          <g
            id="freepik--crane--inject-42"
            class="animable"
            style="transform-origin: 214.504px 206.66px"
          >
            <path
              d="M156.41,152.42a.53.53,0,0,1-.53-.53V126.68a.53.53,0,0,1,.53-.53.52.52,0,0,1,.52.53v25.21A.52.52,0,0,1,156.41,152.42Z"
              style="
                fill: rgb(240, 240, 240);
                transform-origin: 156.405px 139.285px;
              "
              id="el2nbvug7p2nc"
              class="animable"
            ></path>
            <path
              d="M159.23,151.89a2.82,2.82,0,1,1-2.82-2.82A2.82,2.82,0,0,1,159.23,151.89Z"
              style="
                fill: rgb(240, 240, 240);
                transform-origin: 156.41px 151.89px;
              "
              id="elkverl93xywp"
              class="animable"
            ></path>
            <path
              d="M155.24,169a2.44,2.44,0,0,1-1.23-.33c-1.1-.63-1.73-2.1-1.73-4a.82.82,0,0,1,.81-.82.82.82,0,0,1,.82.82c0,1.53.48,2.35.92,2.61a1.14,1.14,0,0,0,1.17-.13c1.57-.91,2.9-3.8,2.9-6.31,0-1.53-.47-2.35-.92-2.61a1.14,1.14,0,0,0-1.16.13.8.8,0,0,1-.82,0,.79.79,0,0,1-.41-.71v-4.75a.82.82,0,0,1,.82-.81.81.81,0,0,1,.81.81v3.61a2.48,2.48,0,0,1,1.58.31c1.1.63,1.74,2.1,1.74,4,0,3.13-1.64,6.52-3.72,7.72A3.14,3.14,0,0,1,155.24,169Z"
              style="
                fill: rgb(240, 240, 240);
                transform-origin: 156.41px 160.545px;
              "
              id="el70zvcscqgkh"
              class="animable"
            ></path>
            <polygon
              points="260.15 110.78 275.75 101.77 260.15 92.77 244.56 101.77 260.15 110.78"
              style="
                fill: rgb(240, 240, 240);
                transform-origin: 260.155px 101.775px;
              "
              id="el6q001o5a94y"
              class="animable"
            ></polygon>
            <polygon
              points="275.75 101.77 260.15 110.78 260.15 140.8 275.75 131.79 275.75 101.77"
              style="
                fill: rgb(230, 230, 230);
                transform-origin: 267.95px 121.285px;
              "
              id="el15riv5wcn8"
              class="animable"
            ></polygon>
            <polygon
              points="244.56 101.77 244.56 131.79 260.15 140.8 260.15 110.78 244.56 101.77"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 252.355px 121.285px;
              "
              id="elsvcatgypw48"
              class="animable"
            ></polygon>
            <polygon
              points="273.15 106.41 262.75 112.42 262.75 127.27 273.15 121.26 273.15 106.41"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 267.95px 116.84px;
              "
              id="el0cl5cs1dfkti"
              class="animable"
            ></polygon>
            <g id="elwiir7qpkyc">
              <polygon
                points="247.16 106.41 257.56 112.42 257.56 127.27 247.16 121.26 247.16 106.41"
                style="opacity: 0.1; transform-origin: 252.36px 116.84px"
                class="animable"
              ></polygon>
            </g>
            <path
              d="M240.07,157.49l-12.87-7.43a.39.39,0,0,1-.19-.34V134.86a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0L240.47,142a.39.39,0,0,1,.19.34v14.85a.39.39,0,0,1-.19.35.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,157.49ZM227.8,135.55v13.94l12.07,7V142.52Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 146.009px;
              "
              id="el1zul7ihrzrk"
              class="animable"
            ></path>
            <path
              d="M240.27,157.54a.49.49,0,0,0,.2,0,.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,157.54Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 146.044px;
              "
              id="eln924eumez7l"
              class="animable"
            ></path>
            <path
              d="M227.4,150.11a.46.46,0,0,0,.2-.05l12.87-7.43a.39.39,0,0,0-.4-.68l-12.87,7.42a.39.39,0,0,0-.14.54A.39.39,0,0,0,227.4,150.11Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.834px 146.003px;
              "
              id="el80oywm82itd"
              class="animable"
            ></path>
            <path
              d="M240.07,172.34l-12.87-7.43a.39.39,0,0,1-.19-.34V149.72a.38.38,0,0,1,.2-.35.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34V172a.39.39,0,0,1-.19.34.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,172.34ZM227.8,150.4v13.94l12.07,7V157.37Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 160.859px;
              "
              id="eluwz94njoe6"
              class="animable"
            ></path>
            <path
              d="M240.27,172.4a.39.39,0,0,0,.2-.06.39.39,0,0,0,.14-.54l-12.86-22.28a.41.41,0,0,0-.54-.15.4.4,0,0,0-.15.54l12.87,22.29A.4.4,0,0,0,240.27,172.4Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 160.862px;
              "
              id="elufpthivl74a"
              class="animable"
            ></path>
            <path
              d="M227.4,165a.36.36,0,0,0,.2-.06l12.87-7.42a.4.4,0,0,0-.4-.69l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.827px 160.895px;
              "
              id="elq7iyxb5qrys"
              class="animable"
            ></path>
            <path
              d="M240.07,187.2l-12.87-7.43a.39.39,0,0,1-.19-.34V164.57a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,187.2ZM227.8,165.26V179.2l12.07,7V172.23Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 175.714px;
              "
              id="elunlxiur3ln"
              class="animable"
            ></path>
            <path
              d="M240.27,187.25a.49.49,0,0,0,.2-.05.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,187.25Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 175.724px;
              "
              id="eli4ceq7wk0go"
              class="animable"
            ></path>
            <path
              d="M227.4,179.82a.35.35,0,0,0,.2,0l12.87-7.43a.39.39,0,0,0-.4-.68l-12.87,7.43a.39.39,0,0,0-.14.54A.4.4,0,0,0,227.4,179.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.834px 175.745px;
              "
              id="elpdiuibas689"
              class="animable"
            ></path>
            <path
              d="M240.07,202.06l-12.87-7.43a.39.39,0,0,1-.19-.35V179.43a.38.38,0,0,1,.2-.34.35.35,0,0,1,.39,0l12.87,7.42a.39.39,0,0,1,.19.35v14.85a.39.39,0,0,1-.19.35.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,202.06ZM227.8,180.11v13.95l12.07,7V187.08Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 190.57px;
              "
              id="el6mh2n7hfvyx"
              class="animable"
            ></path>
            <path
              d="M240.27,202.11a.49.49,0,0,0,.2-.05.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,202.11Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 190.584px;
              "
              id="el9lz3m3ornd"
              class="animable"
            ></path>
            <path
              d="M227.4,194.68a.46.46,0,0,0,.2-.05l12.87-7.43a.39.39,0,0,0,.14-.54.4.4,0,0,0-.54-.15l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 190.57px;
              "
              id="elfevhhbrlz5"
              class="animable"
            ></path>
            <path
              d="M240.07,216.91l-12.87-7.43a.39.39,0,0,1-.19-.34V194.28a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,216.91ZM227.8,195v13.94l12.07,7V201.94Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 205.429px;
              "
              id="elj8jemo85nq"
              class="animable"
            ></path>
            <path
              d="M240.27,217a.39.39,0,0,0,.2-.06.39.39,0,0,0,.14-.54l-12.86-22.28a.4.4,0,0,0-.69.39l12.87,22.29A.4.4,0,0,0,240.27,217Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.849px 205.481px;
              "
              id="eldj9mr8tdh0b"
              class="animable"
            ></path>
            <path
              d="M227.4,209.54a.36.36,0,0,0,.2-.06l12.87-7.43a.39.39,0,1,0-.4-.68L227.2,208.8a.39.39,0,0,0-.14.54A.41.41,0,0,0,227.4,209.54Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 205.427px;
              "
              id="elh037mcswq66"
              class="animable"
            ></path>
            <path
              d="M240.07,231.77l-12.87-7.43A.39.39,0,0,1,227,224V209.14a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,231.77ZM227.8,209.83v13.94l12.07,7V216.8Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.825px 220.264px;
              "
              id="eluzx6j0iamkf"
              class="animable"
            ></path>
            <path
              d="M240.27,231.82a.49.49,0,0,0,.2,0,.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,231.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 220.324px;
              "
              id="el6pkl7ysty9h"
              class="animable"
            ></path>
            <path
              d="M227.4,224.39a.35.35,0,0,0,.2,0l12.87-7.43a.39.39,0,0,0-.4-.68l-12.87,7.42a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.837px 220.333px;
              "
              id="elwvbw4m4q35o"
              class="animable"
            ></path>
            <path
              d="M240.07,246.62,227.2,239.2a.39.39,0,0,1-.19-.35V224a.41.41,0,0,1,.2-.35.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.35v14.85a.39.39,0,0,1-.19.34.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,246.62ZM227.8,224.68v13.94l12.07,7V231.65Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 235.139px;
              "
              id="elid0mvqwtjak"
              class="animable"
            ></path>
            <path
              d="M240.27,246.68a.39.39,0,0,0,.2-.06.39.39,0,0,0,.14-.54L227.75,223.8a.4.4,0,1,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,246.68Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.828px 235.126px;
              "
              id="el222zyvujrpx"
              class="animable"
            ></path>
            <path
              d="M227.4,239.25a.46.46,0,0,0,.2-.05l12.87-7.43a.4.4,0,0,0-.4-.69l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.827px 235.145px;
              "
              id="elyi5bksksuzf"
              class="animable"
            ></path>
            <path
              d="M240.07,261.48l-12.87-7.43a.39.39,0,0,1-.19-.34V238.85a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.37.37,0,0,1-.2,0A.35.35,0,0,1,240.07,261.48ZM227.8,239.54v13.94l12.07,7V246.51Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 249.976px;
              "
              id="el5cqeaoz2qnt"
              class="animable"
            ></path>
            <path
              d="M240.27,261.53a.37.37,0,0,0,.2,0,.39.39,0,0,0,.14-.54l-12.86-22.28a.4.4,0,1,0-.69.39l12.87,22.29A.39.39,0,0,0,240.27,261.53Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.822px 250.001px;
              "
              id="elyjfn6zcicuo"
              class="animable"
            ></path>
            <path
              d="M227.4,254.11a.36.36,0,0,0,.2-.06l12.87-7.43a.39.39,0,0,0-.4-.68l-12.87,7.43a.39.39,0,0,0-.14.54A.41.41,0,0,0,227.4,254.11Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.834px 249.998px;
              "
              id="el8rmfc9q9oz"
              class="animable"
            ></path>
            <path
              d="M240.07,157.49a.4.4,0,0,1-.2-.35V142.29a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,157.49Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 145.984px;
              "
              id="el3hg3j8rp92s"
              class="animable"
            ></path>
            <path
              d="M240.27,157.54a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4L239.93,157a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,157.54Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 146.019px;
              "
              id="eloh6srk1u3sj"
              class="animable"
            ></path>
            <path
              d="M253.13,150.11a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54L240.47,142a.39.39,0,1,0-.4.68l12.87,7.43A.45.45,0,0,0,253.13,150.11Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.703px 146.033px;
              "
              id="elbmks0vv47ow"
              class="animable"
            ></path>
            <path
              d="M240.07,172.34a.4.4,0,0,1-.2-.34V157.14a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.35v14.85a.4.4,0,0,1-.2.34l-12.86,7.43a.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,172.34Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 160.859px;
              "
              id="el4fjweerlnno"
              class="animable"
            ></path>
            <path
              d="M240.27,172.4a.41.41,0,0,0,.34-.2l12.87-22.29a.4.4,0,0,0-.15-.54.4.4,0,0,0-.54.15L239.93,171.8a.39.39,0,0,0,.14.54A.36.36,0,0,0,240.27,172.4Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 160.86px;
              "
              id="elt3mau65dmml"
              class="animable"
            ></path>
            <path
              d="M253.13,165a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.4.69l12.87,7.42A.35.35,0,0,0,253.13,165Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.714px 160.895px;
              "
              id="elu5wfp4f955d"
              class="animable"
            ></path>
            <path
              d="M240.07,187.2a.4.4,0,0,1-.2-.34V172a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,187.2Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 175.714px;
              "
              id="el1bz5jj7veyf"
              class="animable"
            ></path>
            <path
              d="M240.27,187.25a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,187.25Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 175.723px;
              "
              id="el1taasnq8vxh"
              class="animable"
            ></path>
            <path
              d="M253.13,179.82a.42.42,0,0,0,.35-.19.39.39,0,0,0-.15-.54l-12.86-7.43a.39.39,0,1,0-.4.68l12.87,7.43A.34.34,0,0,0,253.13,179.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.704px 175.712px;
              "
              id="el129u5a5ku0an"
              class="animable"
            ></path>
            <path
              d="M240.07,202.06a.4.4,0,0,1-.2-.35V186.86a.4.4,0,0,1,.2-.35l12.87-7.42a.35.35,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.85a.4.4,0,0,1-.2.35l-12.86,7.43a.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,202.06Zm12.67-21.95-12.08,7V201l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 190.57px;
              "
              id="el513fjkzd2mk"
              class="animable"
            ></path>
            <path
              d="M240.27,202.11a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,202.11Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 190.583px;
              "
              id="ela0216kck9ms"
              class="animable"
            ></path>
            <path
              d="M253.13,194.68a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.54.15.39.39,0,0,0,.14.54l12.87,7.43A.45.45,0,0,0,253.13,194.68Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 190.57px;
              "
              id="elsb3gdkrlyc"
              class="animable"
            ></path>
            <path
              d="M240.07,216.91a.4.4,0,0,1-.2-.34V201.71a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,216.91ZM252.74,195l-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 205.429px;
              "
              id="el6drms9qg5zk"
              class="animable"
            ></path>
            <path
              d="M240.27,217a.41.41,0,0,0,.34-.2l12.87-22.29a.4.4,0,0,0-.69-.39l-12.86,22.28a.39.39,0,0,0,.14.54A.36.36,0,0,0,240.27,217Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.691px 205.481px;
              "
              id="el7xclh59gj06"
              class="animable"
            ></path>
            <path
              d="M253.13,209.54a.43.43,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.39.39,0,1,0-.4.68l12.87,7.43A.35.35,0,0,0,253.13,209.54Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 205.427px;
              "
              id="el9ce0z84xaof"
              class="animable"
            ></path>
            <path
              d="M240.07,231.77a.4.4,0,0,1-.2-.34V216.57a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34V224a.4.4,0,0,1-.2.34l-12.86,7.43a.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,231.77Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 220.264px;
              "
              id="elo3ww0mpat8i"
              class="animable"
            ></path>
            <path
              d="M240.27,231.82a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,231.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 220.293px;
              "
              id="eldmzhsnib89"
              class="animable"
            ></path>
            <path
              d="M253.13,224.39a.42.42,0,0,0,.35-.19.41.41,0,0,0-.15-.55l-12.86-7.42a.39.39,0,1,0-.4.68l12.87,7.43A.34.34,0,0,0,253.13,224.39Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.703px 220.283px;
              "
              id="el1p1z01sk2w6h"
              class="animable"
            ></path>
            <path
              d="M240.07,246.62a.4.4,0,0,1-.2-.34V231.43a.4.4,0,0,1,.2-.35l12.87-7.43a.39.39,0,0,1,.39,0,.43.43,0,0,1,.2.35v14.85a.4.4,0,0,1-.2.35l-12.86,7.42a.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,246.62Zm12.67-21.94-12.08,7V245.6l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 235.139px;
              "
              id="elebl9gkz8g6"
              class="animable"
            ></path>
            <path
              d="M240.27,246.68a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,1,0-.69-.4l-12.86,22.28a.39.39,0,0,0,.14.54A.36.36,0,0,0,240.27,246.68Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.712px 235.127px;
              "
              id="el97bmjajf61q"
              class="animable"
            ></path>
            <path
              d="M253.13,239.25a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.4.69l12.87,7.43A.45.45,0,0,0,253.13,239.25Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.714px 235.145px;
              "
              id="elxnedw9v29yg"
              class="animable"
            ></path>
            <path
              d="M240.07,261.48a.4.4,0,0,1-.2-.34V246.28a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.37.37,0,0,1-.2,0A.35.35,0,0,1,240.07,261.48Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 249.976px;
              "
              id="ele32lk5cu63m"
              class="animable"
            ></path>
            <path
              d="M240.27,261.53a.4.4,0,0,0,.34-.19l12.87-22.29a.4.4,0,0,0-.69-.39l-12.86,22.28a.39.39,0,0,0,.14.54A.35.35,0,0,0,240.27,261.53Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.691px 250.016px;
              "
              id="elalw7z616j2"
              class="animable"
            ></path>
            <path
              d="M253.13,254.11a.43.43,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.39.39,0,1,0-.4.68l12.87,7.43A.35.35,0,0,0,253.13,254.11Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.703px 249.998px;
              "
              id="elyffl33a8m3e"
              class="animable"
            ></path>
            <path
              d="M240.07,276.34l-12.87-7.43a.39.39,0,0,1-.19-.34V253.71a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34V276a.39.39,0,0,1-.19.34.37.37,0,0,1-.2.05A.35.35,0,0,1,240.07,276.34ZM227.8,254.4v13.94l12.07,7V261.37Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 264.854px;
              "
              id="ell0c8lhtof0m"
              class="animable"
            ></path>
            <path
              d="M240.27,276.39a.37.37,0,0,0,.2-.05.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.54-.14.4.4,0,0,0-.15.54l12.87,22.28A.38.38,0,0,0,240.27,276.39Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 264.854px;
              "
              id="elwno5thwwhp"
              class="animable"
            ></path>
            <path
              d="M227.4,269a.35.35,0,0,0,.2,0l12.87-7.43a.39.39,0,1,0-.4-.68l-12.87,7.43a.39.39,0,0,0-.14.54A.4.4,0,0,0,227.4,269Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 264.924px;
              "
              id="elk07nqu0ryj9"
              class="animable"
            ></path>
            <path
              d="M240.07,291.2l-12.87-7.43a.39.39,0,0,1-.19-.35V268.57a.38.38,0,0,1,.2-.34.35.35,0,0,1,.39,0l12.87,7.42a.39.39,0,0,1,.19.35v14.85a.42.42,0,0,1-.19.35.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,291.2ZM227.8,269.26V283.2l12.07,7V276.23Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 279.71px;
              "
              id="eljriw1f4jwvg"
              class="animable"
            ></path>
            <path
              d="M240.27,291.25a.49.49,0,0,0,.2-.05.4.4,0,0,0,.14-.55l-12.86-22.28a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,291.25Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.844px 279.724px;
              "
              id="elmrgmyi7z7s"
              class="animable"
            ></path>
            <path
              d="M227.4,283.82a.46.46,0,0,0,.2-.05l12.87-7.43a.39.39,0,0,0,.14-.54.4.4,0,0,0-.54-.15l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 279.71px;
              "
              id="elrf6grxbv1e"
              class="animable"
            ></path>
            <path
              d="M240.07,306.05l-12.87-7.43a.39.39,0,0,1-.19-.34V283.42a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.37.37,0,0,1-.2.05A.35.35,0,0,1,240.07,306.05ZM227.8,284.11v13.94l12.07,7V291.08Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 294.564px;
              "
              id="elq59612ciq6o"
              class="animable"
            ></path>
            <path
              d="M240.27,306.1a.37.37,0,0,0,.2-.05.39.39,0,0,0,.14-.54l-12.86-22.28a.4.4,0,1,0-.69.39l12.87,22.29A.39.39,0,0,0,240.27,306.1Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.822px 294.539px;
              "
              id="elvdxerxwuj4n"
              class="animable"
            ></path>
            <path
              d="M227.4,298.68a.36.36,0,0,0,.2-.06l12.87-7.42a.4.4,0,0,0-.4-.69l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.827px 294.575px;
              "
              id="elftm9n80t44n"
              class="animable"
            ></path>
            <path
              d="M240.07,320.91l-12.87-7.43a.39.39,0,0,1-.19-.34V298.28a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,320.91ZM227.8,299v13.94l12.07,7V305.94Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 309.404px;
              "
              id="elmvg29jarvys"
              class="animable"
            ></path>
            <path
              d="M240.27,321a.49.49,0,0,0,.2,0,.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,321Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 309.504px;
              "
              id="el2vt3p0kanel"
              class="animable"
            ></path>
            <path
              d="M227.4,313.53a.46.46,0,0,0,.2,0l12.87-7.43a.39.39,0,1,0-.4-.68l-12.87,7.42a.39.39,0,0,0-.14.54A.39.39,0,0,0,227.4,313.53Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.834px 309.453px;
              "
              id="el7d81l3zu7tp"
              class="animable"
            ></path>
            <path
              d="M240.07,276.34a.4.4,0,0,1-.2-.34V261.14a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.37.37,0,0,1-.2.05A.35.35,0,0,1,240.07,276.34Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 264.854px;
              "
              id="eluu8c1xf0br"
              class="animable"
            ></path>
            <path
              d="M240.27,276.39a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.15-.54.4.4,0,0,0-.54.14L239.93,275.8a.39.39,0,0,0,.14.54A.35.35,0,0,0,240.27,276.39Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 264.854px;
              "
              id="el94g8fyp0gzl"
              class="animable"
            ></path>
            <path
              d="M253.13,269a.42.42,0,0,0,.35-.19.39.39,0,0,0-.15-.54l-12.86-7.43a.39.39,0,1,0-.4.68l12.87,7.43A.34.34,0,0,0,253.13,269Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.705px 264.893px;
              "
              id="elgigeioxb4vr"
              class="animable"
            ></path>
            <path
              d="M240.07,291.2a.43.43,0,0,1-.2-.35V276a.4.4,0,0,1,.2-.35l12.87-7.42a.35.35,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.85a.4.4,0,0,1-.2.35l-12.86,7.43a.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,291.2Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 279.71px;
              "
              id="eliuh5w05vd2"
              class="animable"
            ></path>
            <path
              d="M240.27,291.25a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.28a.4.4,0,0,0,.14.55A.46.46,0,0,0,240.27,291.25Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.696px 279.723px;
              "
              id="el0ufoa2wmaql"
              class="animable"
            ></path>
            <path
              d="M253.13,283.82a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.54.15.39.39,0,0,0,.14.54l12.87,7.43A.45.45,0,0,0,253.13,283.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 279.71px;
              "
              id="el1igobh7e028j"
              class="animable"
            ></path>
            <path
              d="M240.07,306.05a.4.4,0,0,1-.2-.34V290.85a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.37.37,0,0,1-.2.05A.35.35,0,0,1,240.07,306.05Zm12.67-21.94-12.08,7V305l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 294.564px;
              "
              id="el6uhur9jbtdg"
              class="animable"
            ></path>
            <path
              d="M240.27,306.1a.4.4,0,0,0,.34-.19l12.87-22.29a.4.4,0,0,0-.69-.39l-12.86,22.28a.39.39,0,0,0,.14.54A.35.35,0,0,0,240.27,306.1Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.691px 294.586px;
              "
              id="el5zof2qw2mjb"
              class="animable"
            ></path>
            <path
              d="M253.13,298.68a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.54.14.4.4,0,0,0,.14.55l12.87,7.42A.35.35,0,0,0,253.13,298.68Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 294.569px;
              "
              id="elay13u1q6fg"
              class="animable"
            ></path>
            <path
              d="M240.07,320.91a.4.4,0,0,1-.2-.34V305.71a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,320.91ZM252.74,299l-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 309.404px;
              "
              id="elich6tfu2cf"
              class="animable"
            ></path>
            <path
              d="M240.27,321a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,321Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 309.473px;
              "
              id="elsg3tdas537f"
              class="animable"
            ></path>
            <path
              d="M253.13,313.53a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.42a.39.39,0,1,0-.4.68l12.87,7.43A.45.45,0,0,0,253.13,313.53Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.703px 309.423px;
              "
              id="elfbg3alnf44"
              class="animable"
            ></path>
            <path
              d="M240.07,335.76l-12.87-7.43A.39.39,0,0,1,227,328V313.14a.38.38,0,0,1,.2-.35.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,335.76ZM227.8,313.82v13.94l12.07,7V320.79Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.825px 324.279px;
              "
              id="elmmxn7uirqjg"
              class="animable"
            ></path>
            <path
              d="M240.27,335.82a.39.39,0,0,0,.2-.06.39.39,0,0,0,.14-.54l-12.86-22.28a.41.41,0,0,0-.54-.15.4.4,0,0,0-.15.54l12.87,22.29A.4.4,0,0,0,240.27,335.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 324.282px;
              "
              id="elk7zt1wz5tps"
              class="animable"
            ></path>
            <path
              d="M227.4,328.39a.36.36,0,0,0,.2-.06l12.87-7.42a.4.4,0,0,0-.4-.69l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.827px 324.285px;
              "
              id="elh268dtjnoac"
              class="animable"
            ></path>
            <path
              d="M240.07,350.62l-12.87-7.43a.39.39,0,0,1-.19-.34V328a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,350.62ZM227.8,328.68v13.94l12.07,7V335.65Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 339.144px;
              "
              id="el3pvuos1fhwo"
              class="animable"
            ></path>
            <path
              d="M240.27,350.67a.49.49,0,0,0,.2-.05.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,350.67Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 339.144px;
              "
              id="elclvmw5lopsa"
              class="animable"
            ></path>
            <path
              d="M227.4,343.24a.35.35,0,0,0,.2-.05l12.87-7.43a.39.39,0,1,0-.4-.68L227.2,342.5a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.839px 339.132px;
              "
              id="elibfeutg3yp"
              class="animable"
            ></path>
            <path
              d="M240.07,365.47l-12.87-7.42a.41.41,0,0,1-.19-.35V342.85a.39.39,0,0,1,.2-.35.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.35v14.85a.39.39,0,0,1-.19.34.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,365.47ZM227.8,343.53v13.94l12.07,7V350.5Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 353.989px;
              "
              id="elyhe90q3id5"
              class="animable"
            ></path>
            <path
              d="M240.27,365.53a.39.39,0,0,0,.2-.06.39.39,0,0,0,.14-.54l-12.86-22.28a.4.4,0,1,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,365.53Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.828px 353.976px;
              "
              id="el0e8tppxpnclf"
              class="animable"
            ></path>
            <path
              d="M227.4,358.1a.46.46,0,0,0,.2-.05l12.87-7.43a.39.39,0,0,0,.14-.54.4.4,0,0,0-.54-.15l-12.87,7.43a.4.4,0,0,0,.2.74Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 353.99px;
              "
              id="el3vqygjl7rc9"
              class="animable"
            ></path>
            <path
              d="M240.07,335.76a.4.4,0,0,1-.2-.34V320.56a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.35V328a.4.4,0,0,1-.2.34l-12.86,7.43a.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,335.76Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 324.284px;
              "
              id="el3ek4ckbedbk"
              class="animable"
            ></path>
            <path
              d="M240.27,335.82a.41.41,0,0,0,.34-.2l12.87-22.29a.4.4,0,0,0-.15-.54.4.4,0,0,0-.54.15l-12.86,22.28a.39.39,0,0,0,.14.54A.36.36,0,0,0,240.27,335.82Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 324.28px;
              "
              id="el7epl3noss5w"
              class="animable"
            ></path>
            <path
              d="M253.13,328.39a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.54.14.4.4,0,0,0,.14.55l12.87,7.42A.35.35,0,0,0,253.13,328.39Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 324.279px;
              "
              id="elbwd890vlme"
              class="animable"
            ></path>
            <path
              d="M240.07,350.62a.4.4,0,0,1-.2-.34V335.42a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.49.49,0,0,1-.2.05A.46.46,0,0,1,240.07,350.62Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 339.134px;
              "
              id="elgpxxp03ml2p"
              class="animable"
            ></path>
            <path
              d="M240.27,350.67a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,350.67Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 339.143px;
              "
              id="elyzljk7wda2b"
              class="animable"
            ></path>
            <path
              d="M253.13,343.24a.42.42,0,0,0,.35-.19.41.41,0,0,0-.15-.55l-12.86-7.42a.39.39,0,0,0-.4.68l12.87,7.43A.34.34,0,0,0,253.13,343.24Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.703px 339.133px;
              "
              id="el2htxmtlcrq5"
              class="animable"
            ></path>
            <path
              d="M240.07,365.47a.4.4,0,0,1-.2-.34V350.28a.4.4,0,0,1,.2-.35l12.87-7.43a.39.39,0,0,1,.39,0,.42.42,0,0,1,.2.35V357.7a.42.42,0,0,1-.2.35l-12.86,7.42a.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,365.47Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 353.989px;
              "
              id="elqfa76kcqny"
              class="animable"
            ></path>
            <path
              d="M240.27,365.53a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,1,0-.69-.4l-12.86,22.28a.39.39,0,0,0,.14.54A.36.36,0,0,0,240.27,365.53Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.712px 353.977px;
              "
              id="elylkvyzw94h"
              class="animable"
            ></path>
            <path
              d="M253.13,358.1a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.54.15.39.39,0,0,0,.14.54l12.87,7.43A.45.45,0,0,0,253.13,358.1Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 353.99px;
              "
              id="elcbg8tqgr3du"
              class="animable"
            ></path>
            <polygon
              points="240.27 142.29 261.71 129.91 240.27 117.53 218.82 129.91 240.27 142.29"
              style="
                fill: rgb(230, 230, 230);
                transform-origin: 240.265px 129.91px;
              "
              id="ellxcfurg5djb"
              class="animable"
            ></polygon>
            <polygon
              points="261.71 129.91 240.27 142.29 240.27 147.24 261.71 134.86 261.71 129.91"
              style="
                fill: rgb(240, 240, 240);
                transform-origin: 250.99px 138.575px;
              "
              id="elmxigynz7o8c"
              class="animable"
            ></polygon>
            <polygon
              points="218.82 129.91 218.82 134.86 240.27 147.24 240.27 142.29 218.82 129.91"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 229.545px 138.575px;
              "
              id="ellek8of590pi"
              class="animable"
            ></polygon>
            <path
              d="M240.07,108l-12.86-7.43a.38.38,0,0,1-.2-.34V85.34a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.85a.39.39,0,0,1-.19.35.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,108ZM227.8,86V100l12.07,7V93Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 96.4745px;
              "
              id="elp2sbxq173y"
              class="animable"
            ></path>
            <path
              d="M240.27,108a.49.49,0,0,0,.2,0,.39.39,0,0,0,.14-.54L227.75,85.14a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,108Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 96.4887px;
              "
              id="elli35n8h3n"
              class="animable"
            ></path>
            <path
              d="M227.4,100.59a.46.46,0,0,0,.2,0l12.87-7.43a.39.39,0,1,0-.4-.68l-12.86,7.42a.4.4,0,0,0-.15.54A.39.39,0,0,0,227.4,100.59Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.837px 96.5133px;
              "
              id="elyjplqq0qab"
              class="animable"
            ></path>
            <path
              d="M240.07,122.82l-12.86-7.43a.38.38,0,0,1-.2-.34V100.2a.38.38,0,0,1,.2-.35.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,122.82ZM227.8,100.88v13.94l12.07,7V107.85Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 111.339px;
              "
              id="eltp7atzgvdbo"
              class="animable"
            ></path>
            <path
              d="M240.27,122.88a.39.39,0,0,0,.2-.06.39.39,0,0,0,.14-.54L227.75,100a.41.41,0,0,0-.54-.15.4.4,0,0,0-.15.54l12.87,22.29A.4.4,0,0,0,240.27,122.88Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.838px 111.342px;
              "
              id="el04u5p93qg3ly"
              class="animable"
            ></path>
            <path
              d="M227.4,115.45a.36.36,0,0,0,.2-.06L240.47,108a.4.4,0,0,0-.4-.69l-12.86,7.43a.4.4,0,0,0-.15.54A.39.39,0,0,0,227.4,115.45Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.826px 111.361px;
              "
              id="eli6kwom719s"
              class="animable"
            ></path>
            <path
              d="M240.07,137.68l-12.86-7.43a.38.38,0,0,1-.2-.34V115.05a.38.38,0,0,1,.2-.34.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,.19.34v14.86a.39.39,0,0,1-.19.34.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,137.68ZM227.8,115.74v13.94l12.07,7V122.71Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 126.174px;
              "
              id="elkdyxofv48j"
              class="animable"
            ></path>
            <path
              d="M240.27,137.73a.49.49,0,0,0,.2,0,.39.39,0,0,0,.14-.54l-12.86-22.29a.4.4,0,0,0-.69.4l12.87,22.28A.38.38,0,0,0,240.27,137.73Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 126.234px;
              "
              id="elik6p7ybltt"
              class="animable"
            ></path>
            <path
              d="M227.4,130.3a.35.35,0,0,0,.2-.05l12.87-7.43a.39.39,0,1,0-.4-.68l-12.86,7.43a.39.39,0,0,0-.15.54A.4.4,0,0,0,227.4,130.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.835px 126.193px;
              "
              id="el0csgicvnqsf"
              class="animable"
            ></path>
            <path
              d="M240.07,108a.4.4,0,0,1-.2-.35V92.77a.4.4,0,0,1,.2-.34L252.94,85a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34V100.2a.4.4,0,0,1-.2.34L240.47,108a.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,108ZM252.74,86l-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 96.4794px;
              "
              id="elbg42ykqgbi"
              class="animable"
            ></path>
            <path
              d="M240.27,108a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,108Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 96.4735px;
              "
              id="eljw30w6xkc18"
              class="animable"
            ></path>
            <path
              d="M253.13,100.59a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.42a.39.39,0,1,0-.4.68l12.87,7.43A.45.45,0,0,0,253.13,100.59Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 96.4823px;
              "
              id="eleaquyphcamv"
              class="animable"
            ></path>
            <path
              d="M240.07,122.82a.4.4,0,0,1-.2-.34V107.62a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.35v14.85a.4.4,0,0,1-.2.34l-12.86,7.43a.39.39,0,0,1-.2.06A.36.36,0,0,1,240.07,122.82Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 111.339px;
              "
              id="elz6pal8l0am"
              class="animable"
            ></path>
            <path
              d="M240.27,122.88a.41.41,0,0,0,.34-.2l12.87-22.29a.4.4,0,0,0-.15-.54.4.4,0,0,0-.54.15l-12.86,22.28a.39.39,0,0,0,.14.54A.36.36,0,0,0,240.27,122.88Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.702px 111.34px;
              "
              id="elhstbvf9slw"
              class="animable"
            ></path>
            <path
              d="M253.13,115.45a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.4.69l12.87,7.42A.35.35,0,0,0,253.13,115.45Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.714px 111.345px;
              "
              id="elykklbc2vxhg"
              class="animable"
            ></path>
            <path
              d="M240.07,137.68a.4.4,0,0,1-.2-.34V122.48a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34l-12.86,7.43a.49.49,0,0,1-.2,0A.46.46,0,0,1,240.07,137.68Zm12.67-21.94-12.08,7v13.94l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 126.174px;
              "
              id="el0qddgj7d3on"
              class="animable"
            ></path>
            <path
              d="M240.27,137.73a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,240.27,137.73Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.697px 126.203px;
              "
              id="elbse6ocis7e6"
              class="animable"
            ></path>
            <path
              d="M253.13,130.3a.42.42,0,0,0,.35-.19.39.39,0,0,0-.15-.54l-12.86-7.43a.39.39,0,0,0-.4.68l12.87,7.43A.34.34,0,0,0,253.13,130.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.705px 126.193px;
              "
              id="elu43l5r6ffce"
              class="animable"
            ></path>
            <path
              d="M162.87,137.67a.39.39,0,0,1-.19-.34V122.47a.39.39,0,0,1,.19-.34l12.87-7.43a.4.4,0,0,1,.6.35V129.9a.39.39,0,0,1-.19.34l-12.87,7.43a.36.36,0,0,1-.2.06A.41.41,0,0,1,162.87,137.67Zm12.67-21.94-12.07,7v13.94l12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 169.51px 126.188px;
              "
              id="eln00ccs8irph"
              class="animable"
            ></path>
            <path
              d="M163.07,137.73a.43.43,0,0,0,.35-.2l12.86-22.29a.39.39,0,0,0-.14-.54.4.4,0,0,0-.54.15l-12.87,22.28a.4.4,0,0,0,.14.54A.41.41,0,0,0,163.07,137.73Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 169.507px 126.19px;
              "
              id="elitm3h3tqn8c"
              class="animable"
            ></path>
            <path
              d="M175.94,130.3a.39.39,0,0,0,.34-.2.4.4,0,0,0-.14-.54l-12.87-7.43a.4.4,0,0,0-.54.15.4.4,0,0,0,.14.54l12.87,7.42A.36.36,0,0,0,175.94,130.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 169.505px 126.19px;
              "
              id="elzyom8vagw4h"
              class="animable"
            ></path>
            <path
              d="M175.74,130.24a.4.4,0,0,1-.2-.34V115.05a.4.4,0,0,1,.2-.35l12.87-7.42a.35.35,0,0,1,.39,0,.4.4,0,0,1,.2.34v14.85a.4.4,0,0,1-.2.35l-12.86,7.42a.39.39,0,0,1-.2.06A.36.36,0,0,1,175.74,130.24Zm12.67-21.94-12.08,7v14l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 182.37px 118.76px;
              "
              id="elqf6wxomf7as"
              class="animable"
            ></path>
            <path
              d="M175.94,130.3a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4L175.6,129.7a.39.39,0,0,0,.14.54A.36.36,0,0,0,175.94,130.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 182.367px 118.773px;
              "
              id="el1wcd5n5558a"
              class="animable"
            ></path>
            <path
              d="M188.8,122.87a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.86-7.43a.4.4,0,0,0-.54.15.39.39,0,0,0,.14.54l12.87,7.43A.45.45,0,0,0,188.8,122.87Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 182.372px 118.76px;
              "
              id="elwylskfctzg"
              class="animable"
            ></path>
            <path
              d="M188.61,122.82a.38.38,0,0,1-.2-.35V107.62a.38.38,0,0,1,.2-.34l12.86-7.43a.41.41,0,0,1,.4,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34L189,122.82a.46.46,0,0,1-.2.05A.45.45,0,0,1,188.61,122.82Zm12.66-21.94-12.07,7v13.94l12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 195.24px 111.334px;
              "
              id="elv9jrahqn7id"
              class="animable"
            ></path>
            <path
              d="M188.8,122.87a.41.41,0,0,0,.35-.2L202,100.39a.39.39,0,1,0-.68-.4l-12.87,22.29a.4.4,0,0,0,.15.54A.45.45,0,0,0,188.8,122.87Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 195.227px 111.333px;
              "
              id="el2kfmjkewz82"
              class="animable"
            ></path>
            <path
              d="M201.67,115.44a.38.38,0,0,0,.34-.2.39.39,0,0,0-.14-.54L189,107.28a.39.39,0,1,0-.39.68l12.86,7.43A.46.46,0,0,0,201.67,115.44Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 195.24px 111.334px;
              "
              id="eldpck2sdm0pv"
              class="animable"
            ></path>
            <path
              d="M162.87,122.82,150,115.39a.4.4,0,0,1,0-.69l12.86-7.42a.36.36,0,0,1,.4,0l12.87,7.42a.4.4,0,0,1,0,.69l-12.87,7.43a.46.46,0,0,1-.2.05A.53.53,0,0,1,162.87,122.82Zm.2-14.74-12.07,7,12.07,7,12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 163.065px 115.045px;
              "
              id="elutyolh98as9"
              class="animable"
            ></path>
            <path
              d="M150.21,115.44h25.73a.4.4,0,1,0,0-.79H150.21a.4.4,0,1,0,0,.79Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 163.075px 115.045px;
              "
              id="eli1qqsk3bbgn"
              class="animable"
            ></path>
            <path
              d="M163.07,122.87a.4.4,0,0,0,.4-.4V107.62a.4.4,0,0,0-.4-.4.39.39,0,0,0-.39.4v14.85A.39.39,0,0,0,163.07,122.87Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 163.075px 115.045px;
              "
              id="elhxchr0nbsz"
              class="animable"
            ></path>
            <path
              d="M175.74,115.39,162.87,108a.4.4,0,0,1,0-.68l12.87-7.43a.41.41,0,0,1,.4,0L189,107.28a.39.39,0,0,1,0,.68l-12.86,7.43a.49.49,0,0,1-.2,0A.46.46,0,0,1,175.74,115.39Zm.2-14.74-12.07,7,12.07,7,12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 175.94px 107.619px;
              "
              id="el1myokv21j55"
              class="animable"
            ></path>
            <path
              d="M163.07,108H188.8a.39.39,0,0,0,.4-.39.4.4,0,0,0-.4-.4H163.07a.39.39,0,0,0-.39.4A.38.38,0,0,0,163.07,108Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 175.94px 107.605px;
              "
              id="el08m02ub1erch"
              class="animable"
            ></path>
            <path
              d="M175.94,115.44a.39.39,0,0,0,.39-.39V100.19a.39.39,0,0,0-.39-.4.4.4,0,0,0-.4.4v14.86A.4.4,0,0,0,175.94,115.44Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 175.935px 107.615px;
              "
              id="elim84lkqm8o"
              class="animable"
            ></path>
            <path
              d="M188.61,108l-12.87-7.43a.39.39,0,0,1,0-.68l12.87-7.43a.39.39,0,0,1,.39,0l12.87,7.43a.39.39,0,0,1,0,.68L189,108a.35.35,0,0,1-.2.05A.34.34,0,0,1,188.61,108Zm.19-14.74-12.07,7,12.07,7,12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 188.805px 100.229px;
              "
              id="elh6xy4jl06cq"
              class="animable"
            ></path>
            <path
              d="M175.94,100.59h25.73a.4.4,0,0,0,0-.8H175.94a.4.4,0,0,0,0,.8Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 188.805px 100.19px;
              "
              id="el0ttmznd7yf3"
              class="animable"
            ></path>
            <path
              d="M188.8,108a.39.39,0,0,0,.4-.39V92.76a.4.4,0,0,0-.79,0v14.86A.39.39,0,0,0,188.8,108Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 188.805px 100.212px;
              "
              id="el72kqky7lv3o"
              class="animable"
            ></path>
            <path
              d="M201.47,115.39a.4.4,0,0,1-.2-.34V100.19a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.38.38,0,0,1,.2.34v14.86a.38.38,0,0,1-.2.34l-12.86,7.43a.43.43,0,0,1-.4,0Zm12.67-21.94-12.07,7v13.94l12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 208.1px 103.904px;
              "
              id="el0wtptjujppjj"
              class="animable"
            ></path>
            <path
              d="M201.67,115.44a.38.38,0,0,0,.34-.2L214.88,93a.4.4,0,0,0-.15-.54.4.4,0,0,0-.54.14l-12.86,22.29a.39.39,0,0,0,.14.54A.46.46,0,0,0,201.67,115.44Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 208.102px 103.927px;
              "
              id="el8irx11uqjaq"
              class="animable"
            ></path>
            <path
              d="M214.54,108a.4.4,0,0,0,.34-.19.39.39,0,0,0-.15-.54l-12.86-7.43a.39.39,0,1,0-.4.68L214.34,108A.35.35,0,0,0,214.54,108Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 208.105px 103.9px;
              "
              id="elrnfl1ucjfo"
              class="animable"
            ></path>
            <path
              d="M214.34,108a.4.4,0,0,1-.2-.34V92.76a.4.4,0,0,1,.2-.34L227.2,85a.41.41,0,0,1,.4,0,.4.4,0,0,1,.2.34v14.86a.4.4,0,0,1-.2.34L214.73,108a.34.34,0,0,1-.19.05A.35.35,0,0,1,214.34,108ZM227,86l-12.08,7v13.94L227,100Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 220.97px 96.4992px;
              "
              id="el8xc3ntrj5tp"
              class="animable"
            ></path>
            <path
              d="M214.54,108a.4.4,0,0,0,.34-.19l12.86-22.29a.39.39,0,1,0-.68-.39l-12.87,22.28a.4.4,0,0,0,.15.54A.35.35,0,0,0,214.54,108Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 220.966px 96.4668px;
              "
              id="ely9ztddplts9"
              class="animable"
            ></path>
            <path
              d="M227.4,100.59a.38.38,0,0,0,.34-.2.39.39,0,0,0-.14-.54l-12.87-7.43a.4.4,0,0,0-.54.14.39.39,0,0,0,.15.54l12.86,7.43A.39.39,0,0,0,227.4,100.59Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 220.966px 96.4788px;
              "
              id="elizruclrr4xj"
              class="animable"
            ></path>
            <path
              d="M227.2,100.53a.4.4,0,0,1-.2-.34V85.33a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.38.38,0,0,1,.2.35V92.76a.38.38,0,0,1-.2.34l-12.86,7.43a.36.36,0,0,1-.4,0Zm12.67-21.94-12.07,7V99.5l12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.83px 89.0492px;
              "
              id="elhulz4oe47f"
              class="animable"
            ></path>
            <path
              d="M227.4,100.59a.38.38,0,0,0,.34-.2L240.61,78.1a.4.4,0,0,0-.15-.54.41.41,0,0,0-.54.15L227.06,100a.39.39,0,0,0,.14.54A.39.39,0,0,0,227.4,100.59Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.832px 89.0517px;
              "
              id="el438al88hnm8"
              class="animable"
            ></path>
            <path
              d="M240.27,93.16a.39.39,0,0,0,.34-.2.4.4,0,0,0-.15-.54L227.6,85a.4.4,0,0,0-.4.69l12.87,7.42A.36.36,0,0,0,240.27,93.16Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 233.843px 89.0605px;
              "
              id="eli1wyclaz6ma"
              class="animable"
            ></path>
            <path
              d="M201.47,100.53,188.61,93.1a.39.39,0,0,1,0-.68L201.47,85a.41.41,0,0,1,.4,0l12.86,7.43a.39.39,0,0,1,0,.68l-12.86,7.43a.36.36,0,0,1-.4,0Zm.2-14.74-12.07,7,12.07,7,12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 201.67px 92.7743px;
              "
              id="elx8bmh333o6"
              class="animable"
            ></path>
            <path
              d="M188.8,93.16h25.74a.4.4,0,0,0,.39-.4.39.39,0,0,0-.39-.39H188.8a.39.39,0,0,0-.39.39A.4.4,0,0,0,188.8,93.16Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 201.67px 92.765px;
              "
              id="el22sgef5c6cj"
              class="animable"
            ></path>
            <path
              d="M201.67,100.59a.4.4,0,0,0,.4-.4V85.33a.4.4,0,0,0-.8,0v14.86A.4.4,0,0,0,201.67,100.59Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 201.67px 92.76px;
              "
              id="elaz25ls3uqe"
              class="animable"
            ></path>
            <path
              d="M214.34,93.1l-12.87-7.42a.4.4,0,0,1,0-.69l12.87-7.43a.39.39,0,0,1,.39,0L227.6,85a.4.4,0,0,1,0,.69L214.73,93.1a.35.35,0,0,1-.19.06A.36.36,0,0,1,214.34,93.1Zm.2-14.74-12.08,7,12.08,7,12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 214.535px 85.3339px;
              "
              id="elnbruasbwhw"
              class="animable"
            ></path>
            <path
              d="M201.67,85.73H227.4a.4.4,0,1,0,0-.79H201.67a.4.4,0,1,0,0,.79Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 214.535px 85.335px;
              "
              id="el4xqkd0lq9wu"
              class="animable"
            ></path>
            <path
              d="M214.54,93.16a.4.4,0,0,0,.39-.4V77.91a.4.4,0,1,0-.79,0V92.76A.4.4,0,0,0,214.54,93.16Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 214.535px 85.3035px;
              "
              id="elz3qwcrmaefl"
              class="animable"
            ></path>
            <path
              d="M227.2,85.68l-12.86-7.43a.4.4,0,0,1,0-.69l12.86-7.42a.36.36,0,0,1,.4,0l12.86,7.42a.38.38,0,0,1,.2.35.38.38,0,0,1-.2.34L227.6,85.68a.42.42,0,0,1-.4,0Zm.2-14.74-12.07,7,12.07,7,12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 227.401px 77.905px;
              "
              id="elh0flc5m6oyu"
              class="animable"
            ></path>
            <path
              d="M214.54,78.3h25.73a.4.4,0,0,0,0-.79H214.54a.4.4,0,1,0,0,.79Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 227.342px 77.905px;
              "
              id="el4d1dvd8p9lx"
              class="animable"
            ></path>
            <path
              d="M227.4,85.73a.4.4,0,0,0,.4-.4V70.48a.4.4,0,0,0-.8,0V85.33A.4.4,0,0,0,227.4,85.73Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 227.4px 77.905px;
              "
              id="elkci03xr0kl"
              class="animable"
            ></path>
            <path
              d="M240.07,93.1a.4.4,0,0,1-.2-.34V77.91a.4.4,0,0,1,.2-.35l12.86-7.43a.41.41,0,0,1,.4,0,.42.42,0,0,1,.2.35V85.33a.4.4,0,0,1-.2.35L240.46,93.1a.35.35,0,0,1-.19.06A.36.36,0,0,1,240.07,93.1Zm12.67-21.94-12.08,7V92.08l12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 81.619px;
              "
              id="elfx5q020dvdn"
              class="animable"
            ></path>
            <path
              d="M240.27,93.16a.39.39,0,0,0,.34-.2l12.87-22.28a.4.4,0,1,0-.69-.4L239.92,92.56a.39.39,0,0,0,.15.54A.36.36,0,0,0,240.27,93.16Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.708px 81.6065px;
              "
              id="el9emopo1nzsr"
              class="animable"
            ></path>
            <path
              d="M253.13,85.73a.41.41,0,0,0,.35-.2.4.4,0,0,0-.15-.54l-12.87-7.43a.41.41,0,0,0-.54.15.4.4,0,0,0,.15.54l12.86,7.43A.49.49,0,0,0,253.13,85.73Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 246.7px 81.6217px;
              "
              id="el26mcdag4grfh"
              class="animable"
            ></path>
            <path
              d="M252.93,85.68a.39.39,0,0,1-.19-.35V70.48a.41.41,0,0,1,.19-.35l12.87-7.42a.41.41,0,0,1,.4,0,.4.4,0,0,1,.2.34V77.91a.4.4,0,0,1-.2.34l-12.87,7.43a.46.46,0,0,1-.2,0A.49.49,0,0,1,252.93,85.68ZM265.6,63.74l-12.07,7V84.65l12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 259.57px 74.1745px;
              "
              id="elr4g5evjzw7n"
              class="animable"
            ></path>
            <path
              d="M253.13,85.73a.41.41,0,0,0,.35-.2l12.86-22.28a.39.39,0,1,0-.68-.4L252.79,85.14a.4.4,0,0,0,.14.54A.49.49,0,0,0,253.13,85.73Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 259.566px 74.1928px;
              "
              id="el82lbcx5glfj"
              class="animable"
            ></path>
            <path
              d="M266,78.3a.38.38,0,0,0,.34-.2.39.39,0,0,0-.14-.54l-12.87-7.43a.4.4,0,0,0-.4.69l12.87,7.43A.46.46,0,0,0,266,78.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 259.577px 74.1954px;
              "
              id="elhtyvhmk805"
              class="animable"
            ></path>
            <path
              d="M265.8,78.25a.4.4,0,0,1-.2-.34V63.05a.4.4,0,0,1,.2-.34l12.87-7.43a.39.39,0,0,1,.39,0,.38.38,0,0,1,.2.34V70.48a.38.38,0,0,1-.2.34L266.2,78.25a.43.43,0,0,1-.4,0Zm12.67-21.94-12.07,7V77.22l12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 272.43px 66.7635px;
              "
              id="elri7xjb7x2fs"
              class="animable"
            ></path>
            <path
              d="M266,78.3a.38.38,0,0,0,.34-.2l12.87-22.28a.4.4,0,0,0-.69-.4L265.66,77.71a.39.39,0,0,0,.14.54A.46.46,0,0,0,266,78.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 272.427px 66.7736px;
              "
              id="el1l95mrt3a4p"
              class="animable"
            ></path>
            <path
              d="M278.86,70.87a.42.42,0,0,0,.35-.19.41.41,0,0,0-.15-.55L266.2,62.71a.39.39,0,0,0-.4.68l12.87,7.43A.34.34,0,0,0,278.86,70.87Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 272.433px 66.7629px;
              "
              id="el4lb2m6temhx"
              class="animable"
            ></path>
            <path
              d="M240.07,78.25,227.2,70.82a.39.39,0,0,1,0-.68l12.87-7.43a.39.39,0,0,1,.39,0l12.87,7.42a.42.42,0,0,1,.2.35.4.4,0,0,1-.2.34l-12.87,7.43a.41.41,0,0,1-.19,0A.46.46,0,0,1,240.07,78.25Zm.2-14.74-12.08,7,12.08,7,12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 240.266px 70.4594px;
              "
              id="ellku92hk8p1p"
              class="animable"
            ></path>
            <path
              d="M227.4,70.87h25.73a.39.39,0,0,0,.4-.39.4.4,0,0,0-.4-.4H227.4a.4.4,0,0,0-.4.4A.39.39,0,0,0,227.4,70.87Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 240.265px 70.4751px;
              "
              id="elqm0659ycgci"
              class="animable"
            ></path>
            <path
              d="M240.27,78.3a.39.39,0,0,0,.39-.39V63.05a.39.39,0,0,0-.39-.4.4.4,0,0,0-.4.4V77.91A.4.4,0,0,0,240.27,78.3Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 240.265px 70.475px;
              "
              id="elm9q5g9cgxei"
              class="animable"
            ></path>
            <path
              d="M252.93,70.82l-12.86-7.43a.39.39,0,0,1,0-.68l12.86-7.43a.41.41,0,0,1,.4,0l12.87,7.43a.39.39,0,0,1,0,.68l-12.87,7.43a.35.35,0,0,1-.2.05A.37.37,0,0,1,252.93,70.82Zm.2-14.74-12.07,7,12.07,7,12.08-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 253.135px 63.0492px;
              "
              id="elr6xsbhehpka"
              class="animable"
            ></path>
            <path
              d="M240.27,63.45H266a.4.4,0,0,0,0-.8H240.27a.4.4,0,1,0,0,.8Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 253.135px 63.05px;
              "
              id="elo5071h6ylr"
              class="animable"
            ></path>
            <path
              d="M253.13,70.87a.39.39,0,0,0,.4-.39V55.62a.39.39,0,0,0-.4-.39.38.38,0,0,0-.39.39V70.48A.38.38,0,0,0,253.13,70.87Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 253.135px 63.05px;
              "
              id="el51tuubimhra"
              class="animable"
            ></path>
            <path
              d="M265.8,63.39,252.93,56a.4.4,0,0,1,0-.68l12.87-7.43a.41.41,0,0,1,.4,0l12.86,7.43a.39.39,0,0,1,0,.68L266.2,63.39a.36.36,0,0,1-.4,0Zm.2-14.74-12.07,7,12.07,7,12.07-7Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 266px 55.6443px;
              "
              id="elnxgy29s6keb"
              class="animable"
            ></path>
            <path
              d="M253.13,56h25.73a.4.4,0,0,0,.4-.4.39.39,0,0,0-.4-.39H253.13a.38.38,0,0,0-.39.39A.39.39,0,0,0,253.13,56Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 266px 55.6049px;
              "
              id="el6r7c1jo7djy"
              class="animable"
            ></path>
            <path
              d="M266,63.45a.4.4,0,0,0,.4-.4V48.19a.4.4,0,0,0-.8,0V63.05A.4.4,0,0,0,266,63.45Z"
              style="fill: rgb(224, 224, 224); transform-origin: 266px 55.62px"
              id="el04fi73tjbe46"
              class="animable"
            ></path>
            <polygon
              points="278.86 55.62 266 48.19 266 63.05 278.86 70.48 278.86 55.62"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 272.43px 59.335px;
              "
              id="elmvqq02dkmqk"
              class="animable"
            ></polygon>
            <polygon
              points="163 122.5 149.81 114.88 149.81 130.11 163 137.73 163 122.5"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 156.405px 126.305px;
              "
              id="elrkybwpi17y"
              class="animable"
            ></polygon>
          </g>
          <polygon
            points="167.58 170.87 176.8 176.24 144.41 194.94 135.19 189.57 167.58 170.87"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 155.995px 182.905px;
            "
            id="elgztrcj3xtyn"
            class="animable"
          ></polygon>
          <polygon
            points="176.8 197.6 144.41 216.3 144.41 194.94 176.8 176.24 176.8 197.6"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 160.605px 196.27px;
            "
            id="elpnzjfcskuim"
            class="animable"
          ></polygon>
          <g id="elm3ked8cz1c8">
            <polygon
              points="176.8 197.6 144.41 216.3 144.41 194.94 176.8 176.24 176.8 197.6"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.25;
                transform-origin: 160.605px 196.27px;
              "
              class="animable"
            ></polygon>
          </g>
          <polygon
            points="144.41 194.94 144.41 216.3 135.19 210.95 135.19 189.57 144.41 194.94"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 139.8px 202.935px;
            "
            id="elo8ulz52h2hs"
            class="animable"
          ></polygon>
          <g id="elmhcd6d2zije">
            <polygon
              points="144.41 194.94 144.41 216.3 135.19 210.95 135.19 189.57 144.41 194.94"
              style="opacity: 0.2; transform-origin: 139.8px 202.935px"
              class="animable"
            ></polygon>
          </g>
          <g id="elt3itzpzd74">
            <path
              d="M171.75,182.26l-22.29,12.87a6.49,6.49,0,0,1-2.72,5.2v7.9c1.51-.87,2.72,0,2.72,2.05l22.29-12.87a6.48,6.48,0,0,1,2.72-5.19v-7.9C173,185.18,171.75,184.27,171.75,182.26Zm-20,21.8c-1.1.63-2-.05-2-1.52a4.83,4.83,0,0,1,2-3.83c1.12-.64,2,0,2,1.52A4.8,4.8,0,0,1,151.75,204.06Zm8.86-2.25c-2.29,1.31-4.15-.09-4.15-3.15s1.86-6.6,4.15-7.92,4.15.08,4.15,3.14S162.9,200.48,160.61,201.81Zm8.85-8c-1.1.64-2,0-2-1.52a4.79,4.79,0,0,1,2-3.82c1.11-.64,2,0,2,1.51A4.8,4.8,0,0,1,169.46,193.83Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.25;
                transform-origin: 160.605px 196.27px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M160,194.85a1,1,0,0,1,.59-.81,1.61,1.61,0,0,1,1.48-.15l.92-2a1.7,1.7,0,0,0-1.76-.19v-1.9l-1.22.7v1.92a4.54,4.54,0,0,0-1.79,3.45c0,2.73,3.07.78,3.07,1.77,0,.29-.21.55-.61.78a2.51,2.51,0,0,1-1.94.25l-.81,2.15a2.45,2.45,0,0,0,2.08-.06v2.14l1.22-.7V200a4.77,4.77,0,0,0,1.84-3.56C163.09,193.83,160,195.66,160,194.85Z"
            style="fill: rgb(255, 255, 255); transform-origin: 160.5px 196.35px"
            id="el59gy8um4uxk"
            class="animable"
          ></path>
          <path
            d="M139.8,214.13a.5.5,0,0,1-.5-.5V192.25a.5.5,0,0,1,.08-.26l16.2-25.67a.49.49,0,0,1,.62-.19l16.76,7.3a.49.49,0,0,1,.26.66.5.5,0,0,1-.66.26l-16.37-7.13L140.3,192.4v21.23A.5.5,0,0,1,139.8,214.13Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 156.283px 190.108px;
            "
            id="elmgwfa80eqy9"
            class="animable"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Device--inject-42"
        class="animable"
        style="transform-origin: 250.448px 413.676px"
      >
        <g
          id="freepik--Calculator--inject-42"
          class="animable"
          style="transform-origin: 250.448px 413.676px"
        >
          <path
            d="M178.44,399v8.32a4.44,4.44,0,0,0,.51,1.41l7.47,12.92a19.22,19.22,0,0,0,6,6l63.45,36.65a8.37,8.37,0,0,0,7.58,0l57.5-33.21a2.66,2.66,0,0,0,1.57-2.18v-6.84a2.67,2.67,0,0,0-1.57-2.18l-63.45-36.65a19.19,19.19,0,0,1-6-6L244,364.34a4.38,4.38,0,0,0-6-1.6L180.55,396A4.29,4.29,0,0,0,178.44,399Z"
            style="fill: rgb(38, 50, 56); transform-origin: 250.48px 413.676px"
            id="elb4tevl73lld"
            class="animable"
          ></path>
          <path
            d="M259.6,384.46l61.32,35.42a2.67,2.67,0,0,1,1.57,2.18v6.84a2.66,2.66,0,0,1-1.57,2.18l-57.5,33.21a7.7,7.7,0,0,1-3.82.9Z"
            style="fill: rgb(55, 71, 79); transform-origin: 291.045px 424.826px"
            id="elwhyl0ayfmhn"
            class="animable"
          ></path>
          <path
            d="M178.44,400.51a4.44,4.44,0,0,0,.51,1.41l.83,1.44,6.64,11.48a19.22,19.22,0,0,0,6,6l63.45,36.65a8.37,8.37,0,0,0,7.58,0l55.37-32,2.13-1.24a2.31,2.31,0,0,0,0-4.37l-63.45-36.65a19.19,19.19,0,0,1-6-6L244,364.34a4.38,4.38,0,0,0-6-1.6L180.55,396a4.29,4.29,0,0,0-2.11,3.07A4.14,4.14,0,0,0,178.44,400.51Z"
            style="
              fill: rgb(69, 90, 100);
              transform-origin: 250.444px 410.271px;
            "
            id="el7ey7bws2ttl"
            class="animable"
          ></path>
          <path
            d="M244.89,378.72l-4.31-7.13a3,3,0,0,0-4-1l-48.2,27.83a2.84,2.84,0,0,0-1,3.95l4.31,7.13a3,3,0,0,0,4,1l48.2-27.83A2.85,2.85,0,0,0,244.89,378.72Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 216.133px 390.545px;
            "
            id="elit0eh1xncx"
            class="animable"
          ></path>
          <g id="elfygouyrudbj">
            <path
              d="M244.89,378.72l-4.31-7.13a3,3,0,0,0-4-1l-48.2,27.83a2.84,2.84,0,0,0-1,3.95l4.31,7.13a3,3,0,0,0,4,1l48.2-27.83A2.85,2.85,0,0,0,244.89,378.72Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.75;
                transform-origin: 216.133px 390.545px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M216.14,417.8l-6,3.49a2.65,2.65,0,0,1-2.39,0l-3.23-1.86a.83.83,0,0,1-.47-.67V417a.81.81,0,0,1,.47-.66l6-3.49a2.65,2.65,0,0,1,2.39,0l3.23,1.86a.84.84,0,0,1,.47.66v1.75A.81.81,0,0,1,216.14,417.8Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 210.33px 417.07px;
            "
            id="elmeyxut8e52"
            class="animable"
          ></path>
          <g id="elbxu31rmosa">
            <path
              d="M216.14,417.8l-6,3.49a2.65,2.65,0,0,1-2.39,0l-3.23-1.86a.83.83,0,0,1-.47-.67V417a.81.81,0,0,1,.47-.66l6-3.49a2.65,2.65,0,0,1,2.39,0l3.23,1.86a.84.84,0,0,1,.47.66v1.75A.81.81,0,0,1,216.14,417.8Z"
              style="opacity: 0.2; transform-origin: 210.33px 417.07px"
              class="animable"
            ></path>
          </g>
          <path
            d="M210.52,412.87l-6,3.49a.73.73,0,0,0,0,1.38l3.23,1.86a2.65,2.65,0,0,0,2.39,0l6-3.48a.74.74,0,0,0,0-1.39l-3.23-1.86A2.65,2.65,0,0,0,210.52,412.87Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 210.327px 416.235px;
            "
            id="eleag4pwcrqi"
            class="animable"
          ></path>
          <g id="el2abnk6xjuuh">
            <path
              d="M216.61,415.61v1.52a.8.8,0,0,1-.47.67l-6,3.49a2.42,2.42,0,0,1-1.2.28v-1.68a2.45,2.45,0,0,0,1.2-.29l6-3.48A1.12,1.12,0,0,0,216.61,415.61Z"
              style="opacity: 0.1; transform-origin: 212.775px 418.59px"
              class="animable"
            ></path>
          </g>
          <path
            d="M230.19,409.69l-6,3.48a2.61,2.61,0,0,1-2.4,0l-3.22-1.86a.79.79,0,0,1-.47-.66v-1.74a.81.81,0,0,1,.47-.67l6-3.48a2.67,2.67,0,0,1,2.4,0l3.22,1.86a.8.8,0,0,1,.47.66V409A.81.81,0,0,1,230.19,409.69Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 224.38px 408.969px;
            "
            id="elym13glpyo4"
            class="animable"
          ></path>
          <g id="elppim48fqmt9">
            <path
              d="M230.19,409.69l-6,3.48a2.61,2.61,0,0,1-2.4,0l-3.22-1.86a.79.79,0,0,1-.47-.66v-1.74a.81.81,0,0,1,.47-.67l6-3.48a2.67,2.67,0,0,1,2.4,0l3.22,1.86a.8.8,0,0,1,.47.66V409A.81.81,0,0,1,230.19,409.69Z"
              style="opacity: 0.2; transform-origin: 224.38px 408.969px"
              class="animable"
            ></path>
          </g>
          <path
            d="M224.57,404.76l-6,3.48a.74.74,0,0,0,0,1.39l3.22,1.86a2.67,2.67,0,0,0,2.4,0l6-3.48a.74.74,0,0,0,0-1.39L227,404.76A2.67,2.67,0,0,0,224.57,404.76Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 224.38px 408.121px;
            "
            id="elm0kbhi2zi6b"
            class="animable"
          ></path>
          <g id="elarxapyw3ev5">
            <path
              d="M230.66,407.5V409a.8.8,0,0,1-.47.67l-6,3.48a2.28,2.28,0,0,1-1.19.29v-1.69a2.41,2.41,0,0,0,1.19-.28l6-3.49A1,1,0,0,0,230.66,407.5Z"
              style="opacity: 0.1; transform-origin: 226.83px 410.471px"
              class="animable"
            ></path>
          </g>
          <path
            d="M244.25,401.58l-6,3.48a2.65,2.65,0,0,1-2.39,0l-3.23-1.86a.83.83,0,0,1-.47-.67V400.8a.82.82,0,0,1,.47-.67l6-3.48a2.65,2.65,0,0,1,2.39,0l3.23,1.86a.81.81,0,0,1,.47.66v1.74A.81.81,0,0,1,244.25,401.58Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 238.44px 400.855px;
            "
            id="elr9l0tmoj5i"
            class="animable"
          ></path>
          <g id="elp5fwcxuhry">
            <path
              d="M244.25,401.58l-6,3.48a2.65,2.65,0,0,1-2.39,0l-3.23-1.86a.83.83,0,0,1-.47-.67V400.8a.82.82,0,0,1,.47-.67l6-3.48a2.65,2.65,0,0,1,2.39,0l3.23,1.86a.81.81,0,0,1,.47.66v1.74A.81.81,0,0,1,244.25,401.58Z"
              style="opacity: 0.2; transform-origin: 238.44px 400.855px"
              class="animable"
            ></path>
          </g>
          <path
            d="M238.63,396.65l-6,3.48a.74.74,0,0,0,0,1.39l3.23,1.86a2.65,2.65,0,0,0,2.39,0l6-3.49a.73.73,0,0,0,0-1.38L241,396.65A2.65,2.65,0,0,0,238.63,396.65Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 238.443px 400.018px;
            "
            id="el9pgzobe7xpk"
            class="animable"
          ></path>
          <g id="eln6fjrzvw0ba">
            <path
              d="M244.72,399.38v1.53a.81.81,0,0,1-.47.67l-6,3.48a2.32,2.32,0,0,1-1.2.29v-1.69a2.45,2.45,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,244.72,399.38Z"
              style="opacity: 0.1; transform-origin: 240.885px 402.366px"
              class="animable"
            ></path>
          </g>
          <path
            d="M258.3,393.46l-6,3.49a2.67,2.67,0,0,1-2.4,0l-3.22-1.86a.81.81,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l3.22,1.87a.79.79,0,0,1,.47.66v1.74A.79.79,0,0,1,258.3,393.46Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 252.49px 392.74px;
            "
            id="eltepol8an0m"
            class="animable"
          ></path>
          <g id="el1j5yxq1yonzh">
            <path
              d="M258.3,393.46l-6,3.49a2.67,2.67,0,0,1-2.4,0l-3.22-1.86a.81.81,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l3.22,1.87a.79.79,0,0,1,.47.66v1.74A.79.79,0,0,1,258.3,393.46Z"
              style="opacity: 0.2; transform-origin: 252.49px 392.74px"
              class="animable"
            ></path>
          </g>
          <path
            d="M252.68,388.53l-6,3.49a.73.73,0,0,0,0,1.38l3.22,1.86a2.61,2.61,0,0,0,2.4,0l6-3.48a.72.72,0,0,0,0-1.38l-3.22-1.87A2.67,2.67,0,0,0,252.68,388.53Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 252.501px 391.899px;
            "
            id="el8e0o1lkukld"
            class="animable"
          ></path>
          <g id="el4eyjsifmez5">
            <path
              d="M258.77,391.27v1.52a.82.82,0,0,1-.47.68l-6,3.48a2.45,2.45,0,0,1-1.2.28v-1.68a2.37,2.37,0,0,0,1.2-.29l6-3.48A1,1,0,0,0,258.77,391.27Z"
              style="opacity: 0.1; transform-origin: 254.935px 394.25px"
              class="animable"
            ></path>
          </g>
          <path
            d="M230.22,425.93l-6,3.49a2.65,2.65,0,0,1-2.39,0l-6.07-3.51a.77.77,0,0,1-.46-.66c0-.07,0-1.67,0-1.74a.77.77,0,0,1,.46-.66l6-3.49a2.65,2.65,0,0,1,2.39,0l6.07,3.5a.82.82,0,0,1,.47.66v1.75A.79.79,0,0,1,230.22,425.93Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 222.995px 424.39px;
            "
            id="elsw29gzrexwj"
            class="animable"
          ></path>
          <path
            d="M221.76,419.36l-6,3.49a.73.73,0,0,0,0,1.38l6.07,3.5a2.65,2.65,0,0,0,2.39,0l6-3.48a.74.74,0,0,0,0-1.39l-6.07-3.5A2.65,2.65,0,0,0,221.76,419.36Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 222.987px 423.545px;
            "
            id="el7oyh7rewh7a"
            class="animable"
          ></path>
          <path
            d="M230.69,423.74v1.52a.8.8,0,0,1-.47.67l-6,3.49a2.42,2.42,0,0,1-1.2.28V428a2.45,2.45,0,0,0,1.2-.29l6-3.48A1.07,1.07,0,0,0,230.69,423.74Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 226.855px 426.72px;
            "
            id="eloje6y6h3xn"
            class="animable"
          ></path>
          <path
            d="M230.69,423.74v-.2a.73.73,0,0,0-.25-.52A.66.66,0,0,1,230.69,423.74Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 230.578px 423.38px;
            "
            id="elr83lb523o9"
            class="animable"
          ></path>
          <path
            d="M244.25,417.78l-6,3.48a2.61,2.61,0,0,1-2.4,0l-6.06-3.5a.79.79,0,0,1-.47-.66v-1.74a.81.81,0,0,1,.47-.67l6-3.48a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.8.8,0,0,1,.47.66v1.74A.81.81,0,0,1,244.25,417.78Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 237.02px 416.239px;
            "
            id="eltqokslvtzk"
            class="animable"
          ></path>
          <path
            d="M235.79,411.21l-6,3.48a.74.74,0,0,0,0,1.39l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.48c.67-.39.67-1,0-1.39l-6.06-3.5A2.67,2.67,0,0,0,235.79,411.21Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 237.028px 415.395px;
            "
            id="elhbhr1bhcm7d"
            class="animable"
          ></path>
          <path
            d="M244.72,415.59v1.52a.8.8,0,0,1-.47.67l-6,3.48a2.35,2.35,0,0,1-1.2.29v-1.69a2.47,2.47,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,244.72,415.59Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 240.885px 418.57px;
            "
            id="elynupz4t7j2"
            class="animable"
          ></path>
          <path
            d="M244.72,415.59v-.21a.69.69,0,0,0-.25-.51A.66.66,0,0,1,244.72,415.59Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 244.608px 415.23px;
            "
            id="elpod6thrjs1c"
            class="animable"
          ></path>
          <path
            d="M258.29,409.63l-6,3.48a2.65,2.65,0,0,1-2.39,0l-6.07-3.5a.77.77,0,0,1-.46-.67c0-.06,0-1.66,0-1.73a.8.8,0,0,1,.46-.67l6-3.48a2.65,2.65,0,0,1,2.39,0l6.07,3.5a.81.81,0,0,1,.47.66V409A.81.81,0,0,1,258.29,409.63Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 251.065px 408.085px;
            "
            id="elmp57tivhd0g"
            class="animable"
          ></path>
          <path
            d="M249.83,403.06l-6,3.48a.74.74,0,0,0,0,1.39l6.07,3.5a2.65,2.65,0,0,0,2.39,0l6-3.49a.73.73,0,0,0,0-1.38l-6.07-3.5A2.65,2.65,0,0,0,249.83,403.06Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 251.063px 407.245px;
            "
            id="elatio8p8cx9t"
            class="animable"
          ></path>
          <path
            d="M258.76,407.44V409a.81.81,0,0,1-.47.67l-6,3.48a2.32,2.32,0,0,1-1.2.29v-1.69a2.45,2.45,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,258.76,407.44Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 254.925px 410.441px;
            "
            id="elfizj6umk5ov"
            class="animable"
          ></path>
          <path
            d="M258.76,407.44v-.21a.71.71,0,0,0-.25-.51A.66.66,0,0,1,258.76,407.44Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 258.648px 407.08px;
            "
            id="elb1m1oort908"
            class="animable"
          ></path>
          <path
            d="M242.84,433.22l-6,3.48a2.65,2.65,0,0,1-2.39,0l-6.07-3.5a.83.83,0,0,1-.47-.67v-1.74a.82.82,0,0,1,.47-.66l6-3.48a2.65,2.65,0,0,1,2.39,0l6.07,3.5a.81.81,0,0,1,.47.66v1.74A.83.83,0,0,1,242.84,433.22Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 235.61px 431.675px;
            "
            id="elzp6zz7izz3c"
            class="animable"
          ></path>
          <path
            d="M234.38,426.65l-6,3.48a.73.73,0,0,0,0,1.38l6.07,3.51a2.65,2.65,0,0,0,2.39,0l6-3.49a.73.73,0,0,0,0-1.38l-6.07-3.5A2.62,2.62,0,0,0,234.38,426.65Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 235.61px 430.833px;
            "
            id="elbcqa8ap71i9"
            class="animable"
          ></path>
          <path
            d="M243.31,431v1.52a.82.82,0,0,1-.47.68l-6,3.48a2.42,2.42,0,0,1-1.2.29V435.3a2.35,2.35,0,0,0,1.2-.29l6-3.48A1.07,1.07,0,0,0,243.31,431Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 239.475px 433.985px;
            "
            id="elii2yo1uugp"
            class="animable"
          ></path>
          <path
            d="M243.31,431v-.2a.75.75,0,0,0-.25-.52A.66.66,0,0,1,243.31,431Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 243.198px 430.64px;
            "
            id="elja6q6lbozzt"
            class="animable"
          ></path>
          <path
            d="M256.87,425.06l-6,3.49a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.81.81,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l6.06,3.51a.79.79,0,0,1,.47.66v1.74A.79.79,0,0,1,256.87,425.06Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 249.64px 423.52px;
            "
            id="elb3fuq5x6425"
            class="animable"
          ></path>
          <path
            d="M248.41,418.49l-6,3.49a.73.73,0,0,0,0,1.38l6.06,3.5a2.61,2.61,0,0,0,2.4,0l6-3.48a.73.73,0,0,0,0-1.38l-6.06-3.51A2.67,2.67,0,0,0,248.41,418.49Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 249.64px 422.679px;
            "
            id="elcwsyc84nxat"
            class="animable"
          ></path>
          <path
            d="M257.34,422.87v1.52a.82.82,0,0,1-.47.68l-6,3.48a2.38,2.38,0,0,1-1.19.28v-1.68a2.31,2.31,0,0,0,1.19-.29l6-3.48A1,1,0,0,0,257.34,422.87Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 253.51px 425.85px;
            "
            id="el220l0h491yv"
            class="animable"
          ></path>
          <path
            d="M257.34,422.87v-.2a.7.7,0,0,0-.25-.52A.66.66,0,0,1,257.34,422.87Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 257.228px 422.51px;
            "
            id="eljg7hos4c7ti"
            class="animable"
          ></path>
          <path
            d="M270.91,416.91l-6,3.49a2.65,2.65,0,0,1-2.39,0l-6.07-3.5a.83.83,0,0,1-.47-.67v-1.74a.81.81,0,0,1,.47-.66l6-3.49a2.65,2.65,0,0,1,2.39,0l6.07,3.5a.82.82,0,0,1,.47.66v1.74A.81.81,0,0,1,270.91,416.91Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 263.68px 415.37px;
            "
            id="elmmglck74ded"
            class="animable"
          ></path>
          <path
            d="M262.45,410.34l-6,3.49a.73.73,0,0,0,0,1.38l6.07,3.5a2.65,2.65,0,0,0,2.39,0l6-3.48a.74.74,0,0,0,0-1.39l-6.07-3.5A2.65,2.65,0,0,0,262.45,410.34Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 263.677px 414.525px;
            "
            id="el7msmaqjg3ex"
            class="animable"
          ></path>
          <path
            d="M271.38,414.72v1.52a.8.8,0,0,1-.47.67l-6,3.49a2.42,2.42,0,0,1-1.2.28V419a2.45,2.45,0,0,0,1.2-.29l6-3.48A1.12,1.12,0,0,0,271.38,414.72Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 267.545px 417.7px;
            "
            id="ello8ubez5pn"
            class="animable"
          ></path>
          <path
            d="M271.38,414.72v-.2a.73.73,0,0,0-.25-.52A.66.66,0,0,1,271.38,414.72Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 271.268px 414.36px;
            "
            id="el2dzpfzkk6pn"
            class="animable"
          ></path>
          <path
            d="M255.46,440.5l-6,3.48a2.59,2.59,0,0,1-2.39,0l-6.07-3.5a.79.79,0,0,1-.47-.66v-1.74a.81.81,0,0,1,.47-.66l6-3.49a2.65,2.65,0,0,1,2.39,0l6.07,3.5a.8.8,0,0,1,.46.66c0,.07,0,1.68,0,1.74A.79.79,0,0,1,255.46,440.5Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 248.225px 438.959px;
            "
            id="eltetl6jiuqse"
            class="animable"
          ></path>
          <path
            d="M247,433.93l-6,3.49a.73.73,0,0,0,0,1.38l6.07,3.5a2.65,2.65,0,0,0,2.39,0l6-3.48a.74.74,0,0,0,0-1.39l-6.07-3.5A2.65,2.65,0,0,0,247,433.93Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 248.227px 438.115px;
            "
            id="el6h8x4re0ns8"
            class="animable"
          ></path>
          <path
            d="M255.92,438.31v1.52a.78.78,0,0,1-.47.67l-6,3.48a2.32,2.32,0,0,1-1.2.29v-1.69a2.45,2.45,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,255.92,438.31Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 252.085px 441.291px;
            "
            id="el2xbtjnd1ugf"
            class="animable"
          ></path>
          <path
            d="M255.92,438.31v-.2a.72.72,0,0,0-.24-.52A.65.65,0,0,1,255.92,438.31Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 255.815px 437.95px;
            "
            id="elmiygpxnng3"
            class="animable"
          ></path>
          <path
            d="M269.49,432.35l-6,3.48a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.79.79,0,0,1-.47-.67v-1.73a.81.81,0,0,1,.47-.67l6-3.48a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.79.79,0,0,1,.47.66v1.74A.81.81,0,0,1,269.49,432.35Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 262.26px 430.805px;
            "
            id="elebq77to6mdc"
            class="animable"
          ></path>
          <path
            d="M261,425.78l-6,3.48c-.67.39-.67,1,0,1.39l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.49a.73.73,0,0,0,0-1.38l-6.06-3.5A2.67,2.67,0,0,0,261,425.78Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 262.225px 429.965px;
            "
            id="el60pfjwep23w"
            class="animable"
          ></path>
          <path
            d="M270,430.16v1.52a.81.81,0,0,1-.47.67l-6,3.48a2.3,2.3,0,0,1-1.2.29v-1.69a2.42,2.42,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,270,430.16Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 266.165px 433.141px;
            "
            id="el2rnqqwwuqf1"
            class="animable"
          ></path>
          <path
            d="M270,430.16V430a.68.68,0,0,0-.25-.51A.66.66,0,0,1,270,430.16Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 269.883px 429.825px;
            "
            id="elxm5goi6xwyo"
            class="animable"
          ></path>
          <path
            d="M283.53,424.2l-6,3.48a2.65,2.65,0,0,1-2.39,0l-6.07-3.5a.81.81,0,0,1-.47-.67v-1.74a.82.82,0,0,1,.47-.66l6-3.48a2.59,2.59,0,0,1,2.39,0l6.07,3.5a.83.83,0,0,1,.47.66v1.74A.83.83,0,0,1,283.53,424.2Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 276.3px 422.651px;
            "
            id="el3s4vru2cuw6"
            class="animable"
          ></path>
          <path
            d="M275.07,417.63l-6,3.48a.73.73,0,0,0,0,1.38L275.1,426a2.65,2.65,0,0,0,2.39,0l6-3.49a.73.73,0,0,0,0-1.38l-6.07-3.5A2.59,2.59,0,0,0,275.07,417.63Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 276.28px 421.816px;
            "
            id="elk9dwn72imwf"
            class="animable"
          ></path>
          <path
            d="M284,422v1.52a.8.8,0,0,1-.47.68l-6,3.48a2.42,2.42,0,0,1-1.2.29v-1.69a2.35,2.35,0,0,0,1.2-.29l6-3.48A1,1,0,0,0,284,422Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 280.165px 424.985px;
            "
            id="elaugnwn8mhc4"
            class="animable"
          ></path>
          <path
            d="M284,422v-.2a.74.74,0,0,0-.24-.52A.66.66,0,0,1,284,422Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 283.894px 421.64px;
            "
            id="el3cz1zm23sct"
            class="animable"
          ></path>
          <path
            d="M268.08,447.78l-6,3.49a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.81.81,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.48a2.61,2.61,0,0,1,2.4,0l6.07,3.5a.79.79,0,0,1,.46.66c0,.07,0,1.67,0,1.74A.77.77,0,0,1,268.08,447.78Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 260.85px 446.241px;
            "
            id="elqt49bhqwn8"
            class="animable"
          ></path>
          <path
            d="M259.61,441.22l-6,3.48a.73.73,0,0,0,0,1.38l6.06,3.5a2.61,2.61,0,0,0,2.4,0l6-3.48a.73.73,0,0,0,0-1.38l-6.07-3.5A2.61,2.61,0,0,0,259.61,441.22Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 260.84px 445.401px;
            "
            id="elvizsplbbpd"
            class="animable"
          ></path>
          <path
            d="M268.54,445.59v1.52a.8.8,0,0,1-.47.68l-6,3.48a2.45,2.45,0,0,1-1.2.28v-1.68a2.37,2.37,0,0,0,1.2-.29l6-3.48A1,1,0,0,0,268.54,445.59Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 264.705px 448.57px;
            "
            id="ellrz3if9n49f"
            class="animable"
          ></path>
          <path
            d="M268.54,445.59v-.2a.7.7,0,0,0-.25-.52A.65.65,0,0,1,268.54,445.59Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 268.429px 445.23px;
            "
            id="eluc3a2qh8ohq"
            class="animable"
          ></path>
          <path
            d="M282.11,439.63l-6,3.49a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.79.79,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.82.82,0,0,1,.47.66V439A.79.79,0,0,1,282.11,439.63Z"
            style="
              fill: rgb(224, 224, 224);
              transform-origin: 274.88px 438.09px;
            "
            id="elvppieibha7l"
            class="animable"
          ></path>
          <path
            d="M273.65,433.06l-6,3.49a.72.72,0,0,0,0,1.38l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.48a.74.74,0,0,0,0-1.39l-6.06-3.5A2.67,2.67,0,0,0,273.65,433.06Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 274.866px 437.245px;
            "
            id="elxnycimpt7m"
            class="animable"
          ></path>
          <path
            d="M282.58,437.44V439a.8.8,0,0,1-.47.67l-6,3.49a2.39,2.39,0,0,1-1.2.28v-1.68a2.42,2.42,0,0,0,1.2-.29l6-3.48A1.07,1.07,0,0,0,282.58,437.44Z"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 278.745px 440.441px;
            "
            id="elmae5e684f8"
            class="animable"
          ></path>
          <path
            d="M282.58,437.44v-.2a.7.7,0,0,0-.25-.52A.66.66,0,0,1,282.58,437.44Z"
            style="
              fill: rgb(250, 250, 250);
              transform-origin: 282.468px 437.08px;
            "
            id="el4g6nu0atnz5"
            class="animable"
          ></path>
          <path
            d="M296.15,431.48l-6,3.48a2.61,2.61,0,0,1-2.4,0l-6.06-3.5a.79.79,0,0,1-.47-.66v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l6.07,3.5a.8.8,0,0,1,.46.66c0,.07,0,1.68,0,1.74A.79.79,0,0,1,296.15,431.48Z"
            style="
              fill: rgb(242, 143, 143);
              transform-origin: 288.92px 429.939px;
            "
            id="elg2fli3enn6l"
            class="animable"
          ></path>
          <path
            d="M287.68,424.91l-6,3.49a.73.73,0,0,0,0,1.38l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.48a.74.74,0,0,0,0-1.39l-6.07-3.5A2.67,2.67,0,0,0,287.68,424.91Z"
            style="
              fill: rgb(255, 168, 167);
              transform-origin: 288.907px 429.096px;
            "
            id="elah6v6it4f1f"
            class="animable"
          ></path>
          <g id="elubc4835eqc">
            <path
              d="M296.61,429.29v1.52a.78.78,0,0,1-.47.67l-6,3.49a2.45,2.45,0,0,1-1.2.28v-1.69a2.47,2.47,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,296.61,429.29Z"
              style="opacity: 0.1; transform-origin: 292.775px 432.27px"
              class="animable"
            ></path>
          </g>
          <path
            d="M272.32,401.48l-6,3.48a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.81.81,0,0,1-.47-.67v-1.74a.8.8,0,0,1,.47-.66l6-3.48a2.61,2.61,0,0,1,2.4,0l6.06,3.5a.79.79,0,0,1,.47.66v1.74A.81.81,0,0,1,272.32,401.48Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 265.09px 399.931px;
            "
            id="elvcaybs8uy5"
            class="animable"
          ></path>
          <g id="elkygc4mq7k5">
            <path
              d="M272.32,401.48l-6,3.48a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.81.81,0,0,1-.47-.67v-1.74a.8.8,0,0,1,.47-.66l6-3.48a2.61,2.61,0,0,1,2.4,0l6.06,3.5a.79.79,0,0,1,.47.66v1.74A.81.81,0,0,1,272.32,401.48Z"
              style="opacity: 0.3; transform-origin: 265.09px 399.931px"
              class="animable"
            ></path>
          </g>
          <path
            d="M263.86,394.91l-6,3.48a.73.73,0,0,0,0,1.38l6.06,3.51a2.67,2.67,0,0,0,2.4,0l6-3.49a.72.72,0,0,0,0-1.38l-6.06-3.5A2.61,2.61,0,0,0,263.86,394.91Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 265.101px 399.091px;
            "
            id="ela90wf09d2is"
            class="animable"
          ></path>
          <g id="elxsqqds8vm1">
            <path
              d="M263.86,394.91l-6,3.48a.73.73,0,0,0,0,1.38l6.06,3.51a2.67,2.67,0,0,0,2.4,0l6-3.49a.72.72,0,0,0,0-1.38l-6.06-3.5A2.61,2.61,0,0,0,263.86,394.91Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.2;
                transform-origin: 265.101px 399.091px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elbr73s761wrc">
            <path
              d="M272.79,399.28v1.52a.82.82,0,0,1-.47.68l-6,3.48a2.45,2.45,0,0,1-1.2.29v-1.69a2.37,2.37,0,0,0,1.2-.29l6-3.48A1,1,0,0,0,272.79,399.28Z"
              style="opacity: 0.1; transform-origin: 268.955px 402.265px"
              class="animable"
            ></path>
          </g>
          <path
            d="M284.94,408.76l-6,3.48a2.61,2.61,0,0,1-2.4,0l-6.06-3.5a.79.79,0,0,1-.47-.66v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.8.8,0,0,1,.47.66v1.74A.8.8,0,0,1,284.94,408.76Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 277.71px 407.219px;
            "
            id="elns9p8pit7jg"
            class="animable"
          ></path>
          <g id="elvymqbwuko8p">
            <path
              d="M284.94,408.76l-6,3.48a2.61,2.61,0,0,1-2.4,0l-6.06-3.5a.79.79,0,0,1-.47-.66v-1.74a.79.79,0,0,1,.47-.66l6-3.49a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.8.8,0,0,1,.47.66v1.74A.8.8,0,0,1,284.94,408.76Z"
              style="opacity: 0.3; transform-origin: 277.71px 407.219px"
              class="animable"
            ></path>
          </g>
          <path
            d="M276.48,402.19l-6,3.49a.73.73,0,0,0,0,1.38l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.48a.74.74,0,0,0,0-1.39l-6.06-3.5A2.67,2.67,0,0,0,276.48,402.19Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 277.707px 406.375px;
            "
            id="ellkhh6brnxng"
            class="animable"
          ></path>
          <g id="el0iq32ckzxt95">
            <path
              d="M276.48,402.19l-6,3.49a.73.73,0,0,0,0,1.38l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.48a.74.74,0,0,0,0-1.39l-6.06-3.5A2.67,2.67,0,0,0,276.48,402.19Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.2;
                transform-origin: 277.707px 406.375px;
              "
              class="animable"
            ></path>
          </g>
          <g id="eln4qihenq7t">
            <path
              d="M285.41,406.57v1.52a.8.8,0,0,1-.47.67l-6,3.48a2.28,2.28,0,0,1-1.19.29v-1.69a2.41,2.41,0,0,0,1.19-.28l6-3.49A1,1,0,0,0,285.41,406.57Z"
              style="opacity: 0.1; transform-origin: 281.58px 409.551px"
              class="animable"
            ></path>
          </g>
          <path
            d="M297.56,416l-6,3.49a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.81.81,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.48a2.61,2.61,0,0,1,2.4,0l6.06,3.5a.79.79,0,0,1,.47.66v1.74A.79.79,0,0,1,297.56,416Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 290.33px 414.461px;
            "
            id="eluajgyngyni9"
            class="animable"
          ></path>
          <g id="ellfyql4s4ez">
            <path
              d="M297.56,416l-6,3.49a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.81.81,0,0,1-.47-.67v-1.74a.79.79,0,0,1,.47-.66l6-3.48a2.61,2.61,0,0,1,2.4,0l6.06,3.5a.79.79,0,0,1,.47.66v1.74A.79.79,0,0,1,297.56,416Z"
              style="opacity: 0.3; transform-origin: 290.33px 414.461px"
              class="animable"
            ></path>
          </g>
          <path
            d="M289.1,409.48l-6,3.48a.73.73,0,0,0,0,1.38l6.06,3.5a2.61,2.61,0,0,0,2.4,0l6-3.48a.73.73,0,0,0,0-1.38l-6.06-3.5A2.61,2.61,0,0,0,289.1,409.48Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 290.33px 413.66px;
            "
            id="elnndi25eocsr"
            class="animable"
          ></path>
          <g id="elasxnm4bigik">
            <path
              d="M289.1,409.48l-6,3.48a.73.73,0,0,0,0,1.38l6.06,3.5a2.61,2.61,0,0,0,2.4,0l6-3.48a.73.73,0,0,0,0-1.38l-6.06-3.5A2.61,2.61,0,0,0,289.1,409.48Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.2;
                transform-origin: 290.33px 413.66px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el18q0ygp07oi">
            <path
              d="M298,413.85v1.52a.82.82,0,0,1-.47.68l-6,3.48a2.39,2.39,0,0,1-1.2.28v-1.68a2.32,2.32,0,0,0,1.2-.29l6-3.48A1,1,0,0,0,298,413.85Z"
              style="opacity: 0.1; transform-origin: 294.165px 416.831px"
              class="animable"
            ></path>
          </g>
          <path
            d="M310.18,423.33l-6,3.48a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.77.77,0,0,1-.47-.67v-1.73a.81.81,0,0,1,.47-.67l6-3.48a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.81.81,0,0,1,.47.66v1.74A.81.81,0,0,1,310.18,423.33Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 302.95px 421.785px;
            "
            id="elc4hr1qzl96g"
            class="animable"
          ></path>
          <g id="ell7q445pknj">
            <path
              d="M310.18,423.33l-6,3.48a2.67,2.67,0,0,1-2.4,0l-6.06-3.5a.77.77,0,0,1-.47-.67v-1.73a.81.81,0,0,1,.47-.67l6-3.48a2.67,2.67,0,0,1,2.4,0l6.06,3.5a.81.81,0,0,1,.47.66v1.74A.81.81,0,0,1,310.18,423.33Z"
              style="opacity: 0.3; transform-origin: 302.95px 421.785px"
              class="animable"
            ></path>
          </g>
          <path
            d="M301.72,416.76l-6,3.48c-.67.39-.67,1,0,1.39l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.49a.73.73,0,0,0,0-1.38l-6.06-3.5A2.67,2.67,0,0,0,301.72,416.76Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 302.945px 420.945px;
            "
            id="elww51x91wl3"
            class="animable"
          ></path>
          <g id="el8z3kc0j7hg3">
            <path
              d="M301.72,416.76l-6,3.48c-.67.39-.67,1,0,1.39l6.06,3.5a2.67,2.67,0,0,0,2.4,0l6-3.49a.73.73,0,0,0,0-1.38l-6.06-3.5A2.67,2.67,0,0,0,301.72,416.76Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.2;
                transform-origin: 302.945px 420.945px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el9tmz8ldua37">
            <path
              d="M310.65,421.14v1.52a.8.8,0,0,1-.47.67l-6,3.48a2.3,2.3,0,0,1-1.2.29v-1.69a2.42,2.42,0,0,0,1.2-.28l6-3.49A1,1,0,0,0,310.65,421.14Z"
              style="opacity: 0.1; transform-origin: 306.815px 424.121px"
              class="animable"
            ></path>
          </g>
        </g>
      </g>
      <g
        id="freepik--Character--inject-42"
        class="animable"
        style="transform-origin: 309.786px 260.261px"
      >
        <g
          id="freepik--Chartacter--inject-42"
          class="animable"
          style="transform-origin: 309.786px 260.261px"
        >
          <g
            id="freepik--Bottom--inject-42"
            class="animable"
            style="transform-origin: 320.449px 316.276px"
          >
            <polygon
              points="307.7 378.61 302.93 376.78 304.55 373.64 307.47 376.36 307.7 378.61"
              id="elp6uibl4iqx"
              class="animable"
              style="transform-origin: 305.315px 376.125px"
            ></polygon>
            <path
              d="M312.82,370.27s.14,3,.17,4-4.23,3.86-5.29,4.34l-.23-2.25A16.81,16.81,0,0,0,305,380c-.84,1.35-1.91,3.92-4.17,5.64-3.35,2.56-8.52,3.8-13.77,3.9s-8.24-1.19-9.72-2.32-1.92-2.43-1.53-3.72S312.82,370.27,312.82,370.27Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 294.333px 379.908px;
              "
              id="eljsfropl7as"
              class="animable"
            ></path>
            <path
              d="M298.94,359.68l0-1.76c-1-.05-1.13,2.5-2.58,4.72a45.69,45.69,0,0,1-7.54,9.05c-5.41,4.63-10.21,7.51-12.62,9.59-3.23,2.78,2.28,7.38,10.86,7.05,4.26-.16,11.85-2.12,14-4.52s3.53-6.19,5.26-7.93,5.41-3.48,6.51-5.61c.6-1.17.34-3.94,0-6.29-.28-2.15-.29-5.45-1.54-5.2l-.11,1.1c-.53.93-1.74,2.47-5.18,3C304.3,363.11,299.19,363.18,298.94,359.68Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 294.227px 373.133px;
              "
              id="el0f4kohvrl1oh"
              class="animable"
            ></path>
            <g id="el6q4x3qehekf">
              <path
                d="M303.26,362.9c-1.63-.16-3.38-.72-4.05-2.16,0,0,0,0,0-.05a4,4,0,0,1-1.17,3.37c-1.56,1.72-11.45,13.83-11.45,13.83s9.18-10,10.94-11.85c2.61-2.78,3.42-3.1,5.76-3.14Z"
                style="opacity: 0.15; transform-origin: 294.94px 369.29px"
                class="animable"
              ></path>
            </g>
            <g id="elg5a55fq9yfu">
              <path
                d="M301.07,366.12a.53.53,0,0,0,.57.46.51.51,0,1,0-.57-.46Z"
                style="opacity: 0.15; transform-origin: 301.578px 366.073px"
                class="animable"
              ></path>
            </g>
            <g id="el9gn9k0khhn">
              <path
                d="M298.49,368.79a.53.53,0,0,0,1.05-.1.53.53,0,0,0-1.05.1Z"
                style="opacity: 0.15; transform-origin: 299.015px 368.74px"
                class="animable"
              ></path>
            </g>
            <g id="elbnhouymvbf5">
              <path
                d="M295.92,371.46a.51.51,0,0,0,.57.46.51.51,0,0,0,.47-.55.52.52,0,0,0-.57-.46A.5.5,0,0,0,295.92,371.46Z"
                style="opacity: 0.15; transform-origin: 296.44px 371.416px"
                class="animable"
              ></path>
            </g>
            <g id="elv3qci4y2o2t">
              <path
                d="M293.34,374.14a.53.53,0,0,0,1.05-.1.53.53,0,0,0-.58-.46A.52.52,0,0,0,293.34,374.14Z"
                style="opacity: 0.15; transform-origin: 293.864px 374.072px"
                class="animable"
              ></path>
            </g>
            <g id="elphnuf30tv6b">
              <path
                d="M290.77,376.81a.52.52,0,0,0,1-.1.53.53,0,0,0-.57-.46A.52.52,0,0,0,290.77,376.81Z"
                style="opacity: 0.15; transform-origin: 291.269px 376.697px"
                class="animable"
              ></path>
            </g>
            <path
              d="M301.6,366.27a.2.2,0,0,0,.14-.06.19.19,0,0,0,0-.28,8.64,8.64,0,0,0-5.89-2.37h0a.2.2,0,1,0,0,.4,8.47,8.47,0,0,1,5.57,2.25A.18.18,0,0,0,301.6,366.27Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 298.725px 364.917px;
              "
              id="elk9ah7who6v9"
              class="animable"
            ></path>
            <path
              d="M299,368.94a.26.26,0,0,0,.13-.06.19.19,0,0,0,0-.29,8.73,8.73,0,0,0-5.88-2.37h0a.21.21,0,1,0,0,.41,8.36,8.36,0,0,1,5.57,2.25A.27.27,0,0,0,299,368.94Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 296.096px 367.578px;
              "
              id="el74t2lnoqy39"
              class="animable"
            ></path>
            <path
              d="M296.45,371.62a.2.2,0,0,0,.14-.06.21.21,0,0,0,0-.29,8.77,8.77,0,0,0-5.89-2.37h0a.2.2,0,0,0-.2.21.2.2,0,0,0,.22.2,8.45,8.45,0,0,1,5.57,2.25A.23.23,0,0,0,296.45,371.62Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 293.574px 370.26px;
              "
              id="elt09eiepzj1m"
              class="animable"
            ></path>
            <path
              d="M293.88,374.29a.18.18,0,0,0,.13-.06.19.19,0,0,0,0-.29,8.73,8.73,0,0,0-5.88-2.37h0a.22.22,0,0,0-.2.22.21.21,0,0,0,.22.19,8.47,8.47,0,0,1,5.57,2.25A.19.19,0,0,0,293.88,374.29Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 291.004px 372.931px;
              "
              id="elwevuote1uz"
              class="animable"
            ></path>
            <path
              d="M291.3,377a.24.24,0,0,0,.14,0,.21.21,0,0,0,0-.29,8.69,8.69,0,0,0-5.89-2.37h0a.2.2,0,0,0-.2.21.21.21,0,0,0,.22.2,8.49,8.49,0,0,1,5.57,2.24A.17.17,0,0,0,291.3,377Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 288.424px 375.678px;
              "
              id="elmo0riayfkyg"
              class="animable"
            ></path>
            <path
              d="M346.82,393.73c.69,4,1.62,6.58,3.35,8.77s4.87,2.49,7.21,2a9.9,9.9,0,0,0,7.86-7.36c.79-2.73.31-5.88-.74-9.53Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 356.226px 396.156px;
              "
              id="el17m4exrmp4y"
              class="animable"
            ></path>
            <path
              d="M356.32,363.3c1.16,0,2.1,5.31,3.36,10.63,1.35,5.71,3.53,8.75,4.55,12.58,1.42,5.32,1.3,7.79-.29,11.26s-9.71,7.65-13.64,3.32c-3.2-3.54-3.79-7.76-4.13-13.19a133.44,133.44,0,0,0-1.26-13.78c-.67-4-1.4-7.93-.44-8.48.14.59.3,1.33.44,1.91s.47,2.62,1.12,2.58a7.86,7.86,0,0,0-.15-1.53,1.77,1.77,0,0,1,.17-1.34,2.22,2.22,0,0,1,1.22-.69,32.2,32.2,0,0,1,3.38-.87,16.19,16.19,0,0,1,1.72-.25,19.27,19.27,0,0,1,3-.19,1,1,0,0,1,.69.48,1.59,1.59,0,0,1,.13.61,4.12,4.12,0,0,0,.27,1.1c.06.15.19.34.35.28s.17-.21.15-.35c-.06-.65-.2-1.11-.32-1.87-.05-.35-.16-.82-.21-1.17S356.38,363.64,356.32,363.3Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 354.613px 383.119px;
              "
              id="el8ojfd3ebeiq"
              class="animable"
            ></path>
            <g id="el3z62i7pqc85">
              <path
                d="M360.15,388.29a.41.41,0,0,1-.32.47.42.42,0,0,1-.48-.31.4.4,0,0,1,.32-.46A.41.41,0,0,1,360.15,388.29Z"
                style="opacity: 0.15; transform-origin: 359.75px 388.374px"
                class="animable"
              ></path>
            </g>
            <g id="elmzseayfkc7">
              <path
                d="M359.35,384.6a.4.4,0,0,1-.32.46.39.39,0,1,1-.16-.77A.41.41,0,0,1,359.35,384.6Z"
                style="opacity: 0.15; transform-origin: 358.956px 384.675px"
                class="animable"
              ></path>
            </g>
            <g id="el51zxcqasnt5">
              <path
                d="M358.59,381.11a.4.4,0,0,1-.31.47.41.41,0,0,1-.48-.31.4.4,0,0,1,.31-.47A.41.41,0,0,1,358.59,381.11Z"
                style="opacity: 0.15; transform-origin: 358.195px 381.19px"
                class="animable"
              ></path>
            </g>
            <g id="eli62m9zvz7km">
              <path
                d="M357.85,377.67a.41.41,0,0,1-.8.16.4.4,0,0,1,.32-.46A.4.4,0,0,1,357.85,377.67Z"
                style="opacity: 0.15; transform-origin: 357.448px 377.74px"
                class="animable"
              ></path>
            </g>
            <g id="elhsaxit40sdn">
              <path
                d="M348.77,379.17a.39.39,0,0,1-.31.47.41.41,0,0,1-.48-.3.39.39,0,0,1,.31-.47A.4.4,0,0,1,348.77,379.17Z"
                style="opacity: 0.15; transform-origin: 348.375px 379.254px"
                class="animable"
              ></path>
            </g>
            <g id="elyknt5y1nyq">
              <path
                d="M349.52,382.64a.38.38,0,0,1-.31.46.4.4,0,0,1-.48-.3.39.39,0,0,1,.31-.47A.4.4,0,0,1,349.52,382.64Z"
                style="opacity: 0.15; transform-origin: 349.125px 382.715px"
                class="animable"
              ></path>
            </g>
            <g id="eltsqvbzd1j1r">
              <path
                d="M350.28,386.12a.41.41,0,0,1-.32.47.41.41,0,0,1-.48-.31.4.4,0,0,1,.32-.46A.4.4,0,0,1,350.28,386.12Z"
                style="opacity: 0.15; transform-origin: 349.88px 386.204px"
                class="animable"
              ></path>
            </g>
            <g id="elwb7fg0k13bo">
              <path
                d="M351.08,389.82a.4.4,0,0,1-.32.46.39.39,0,1,1-.16-.77A.42.42,0,0,1,351.08,389.82Z"
                style="opacity: 0.15; transform-origin: 350.685px 389.896px"
                class="animable"
              ></path>
            </g>
            <g id="eluwgybdi0a5">
              <polygon
                points="354.67 391.23 348.69 365.49 350.18 365.3 354.67 391.23"
                style="opacity: 0.15; transform-origin: 351.68px 378.265px"
                class="animable"
              ></polygon>
            </g>
            <path
              d="M359.79,388.57h-.06c-2.73-.3-7.26.44-9,1.47a.21.21,0,0,1-.28-.06.2.2,0,0,1,.07-.28c1.79-1.09,6.35-1.84,9.2-1.53a.2.2,0,0,1,.18.22A.21.21,0,0,1,359.79,388.57Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 355.16px 389.085px;
              "
              id="elkbdpm6ithhq"
              class="animable"
            ></path>
            <path
              d="M359,384.87a.14.14,0,0,1-.11,0c-2.14-.72-7,.31-8.89,1.49a.22.22,0,0,1-.29-.06.2.2,0,0,1,.07-.28c2-1.23,7-2.29,9.25-1.52a.19.19,0,0,1,.12.25A.18.18,0,0,1,359,384.87Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 354.421px 385.315px;
              "
              id="elasma26p8klr"
              class="animable"
            ></path>
            <path
              d="M358.23,381.39h-.1c-2.14-.72-7,.31-8.9,1.48a.21.21,0,0,1-.28-.06.21.21,0,0,1,.06-.28c2-1.23,7-2.28,9.25-1.52a.2.2,0,0,1,.13.26A.23.23,0,0,1,358.23,381.39Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 353.661px 381.826px;
              "
              id="el6ffa2g43uqb"
              class="animable"
            ></path>
            <path
              d="M357.49,378a.22.22,0,0,1-.11,0c-2.14-.72-7,.31-8.89,1.48a.2.2,0,0,1-.29-.06.19.19,0,0,1,.07-.27c2-1.23,7-2.29,9.25-1.53a.2.2,0,0,1,0,.39Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 352.923px 378.441px;
              "
              id="elyhl0z2yanh"
              class="animable"
            ></path>
            <path
              d="M351.65,232.38c2.29,33.1,3.4,78.16,3.4,78.16.21,2.51,2.57,13.26,3.67,25.66,1.16,13.09,1.94,37.58,1.94,37.58s-9.49,4.81-17.09,2.25c0,0-7.69-41.32-11.42-56.77-3.53-14.62-9-42.31-9-42.31l-4.28,27.29s.42,9.43.05,16c-.43,7.66-5.92,44.47-5.92,44.47s-10.32,5.74-18.18-.24c0,0-.76-50.86-.87-60.09-.22-18.11,2.39-53.17,3.35-76.53Z"
              style="
                fill: rgb(38, 50, 56);
                transform-origin: 327.298px 302.315px;
              "
              id="eliso5zdf1l4"
              class="animable"
            ></path>
            <path
              d="M337.33,270.28q.93,11.91,2.1,23.79Q340,300,340.62,306l.63,5.94.32,3c.1,1,.29,2,.44,3,1.25,7.86,3.15,22.13,4.52,30s2.84,15.67,4.41,23.48q-1.45-11.85-3.21-23.65c-1.17-7.87-3-22.15-4.27-30-.14-1-.35-2-.45-2.95l-.34-3-.7-5.93q-.71-5.92-1.45-11.84Q339.05,282.11,337.33,270.28Z"
              id="eltulypsy5ypr"
              class="animable"
              style="transform-origin: 344.135px 320.85px"
            ></path>
            <path
              d="M299.6,267.61c-.94,7.39-1.48,14.81-1.9,22.24s-.81,14.88-.61,22.31.14,19.9.34,27.34.45,14.87.82,22.3q.31-11.16.34-22.3c0-7.44.05-19.9,0-27.33l-.08-5.57,0-1.4,0-1.38.07-2.78.28-11.14.32-11.15C299.26,275,299.43,271.33,299.6,267.61Z"
              id="elxb6pfkbnueb"
              class="animable"
              style="transform-origin: 298.318px 314.705px"
            ></path>
            <path
              d="M323.16,277l-3.11-18.16s-9.23-1.41-13.93-5.59c0,0,3,5.49,11.7,7.73l3.11,16-2.05,27.27Z"
              id="elelssungh2ew"
              class="animable"
              style="transform-origin: 314.64px 278.75px"
            ></path>
          </g>
          <g
            id="freepik--Top--inject-42"
            class="animable"
            style="transform-origin: 309.786px 186.51px"
          >
            <g
              id="freepik--Arm--inject-42"
              class="animable"
              style="transform-origin: 277.566px 177.284px"
            >
              <path
                d="M309,165.73c-4.7,1.33-5.9,2.83-16.14,11.48-8.35,7-12.82,10-12.82,10s-8.71,10.34-9.09,12.36-1.14,6.62,2.86,9.24c2.55,1.67,5.56.22,7.14.39,3.32.37,5.4-2.31,6.79-3.12,9.43-5.47,17-10.47,17-10.47Z"
                style="
                  fill: rgb(230, 230, 230);
                  transform-origin: 289.816px 187.676px;
                "
                id="el8m29yxphtyn"
                class="animable"
              ></path>
              <path
                d="M249.49,159.18c1.41-.81,1.64.49,2.12.67,0,0-1.42-1.68,1-3.09,1.5-.87,2.2,1.23,2.23,1s-1.73-4.41-3.16-8.05c-1.34-3.45-.12-4.41.44-4.68s1.13,0,2.38,2.49,5,10.05,5.81,11.61,1.45,2.31,2,.29,1-3.67,2.14-4.82c1.86-1.91,3.28-1,3,.44-.43,2.09-.44,4.54-.78,7.95-.18,1.77-.24,3.44-.46,4.66-.68,4.28,1.78,6.27,4.7,9.68,2.39,2.78,4.85,5.07,10.34,10.67,5.21,5.31,4.84,17.63-.41,19.73-6,2.4-8.36-.79-12.61-7.51-3.6-5.69-8.53-16-9.88-18.44s-1.83-3.86-3.72-5.06c-2.57-1.62-3.71-3.34-5.11-5.87-.67-1.21-1.94-3.44-2.76-5.24s-.9-3.08.13-3.84c1.4-1,1.79.53,1.93.37S247.48,160.34,249.49,159.18Z"
                style="
                  fill: rgb(255, 168, 167);
                  transform-origin: 265.552px 176.735px;
                "
                id="elkajzmqps08"
                class="animable"
              ></path>
              <path
                d="M251.32,168.2c.48-.69,0-1.94,0-1.94a2.37,2.37,0,0,0,1.13,1,1.74,1.74,0,0,0,2-.87,2.53,2.53,0,0,0-.26-1.64,2.78,2.78,0,0,0,1.21.68c.76.22,1.45-.26,1.75-1.19s-.76-3.25-1.87-5.38a10.6,10.6,0,0,1,1.68,2.38,5.68,5.68,0,0,1,1,2.89,5.93,5.93,0,0,1,2.25-1.82c-2,1.88-3.41,7-2.62,10.44a7.55,7.55,0,0,1-.65-6.77,1.51,1.51,0,0,1-1.79-.15,1.84,1.84,0,0,1-1.08,1.9,1.78,1.78,0,0,1-2,0s.23.77-.78,1.29-1.82-.06-2.37-1A1.59,1.59,0,0,0,251.32,168.2Z"
                style="
                  fill: rgb(242, 143, 143);
                  transform-origin: 254.565px 165.805px;
                "
                id="elgzt39icckvh"
                class="animable"
              ></path>
            </g>
            <g
              id="freepik--Chest--inject-42"
              class="animable"
              style="transform-origin: 326.962px 204.355px"
            >
              <path
                d="M353.22,169.8s5.91,5,2.94,17.12a178.06,178.06,0,0,0-4.05,24c-.51,6.4-.05,28-.05,28-9.81,11.44-50.23,8-55.13-2.57,0,0-.09-40.93,3.74-55.38,2.17-8.18,5.14-12.92,8.28-15.21l13.79-3.09h12.49Z"
                style="
                  fill: rgb(245, 245, 245);
                  transform-origin: 326.962px 204.355px;
                "
                id="elak5clnvk1wg"
                class="animable"
              ></path>
            </g>
            <g
              id="freepik--Head--inject-42"
              class="animable"
              style="transform-origin: 328.753px 148.19px"
            >
              <path
                d="M338.29,157.75c.81-4.1,5-8.3,7-11.85s3-6.08,2.9-9.35c-.07-1.93-1.21-4.58-2.63-5.17.37-7.06-7.1-11.22-16.46-12.4s-14.34,3.15-16.7,5.79a10.39,10.39,0,0,0-2.72,6.55c0,.91,0,2.53.77,3.14.17.15.55,0,.71.13s.13.74.17,1a4.67,4.67,0,0,0,.66,1.85,6.69,6.69,0,0,0,2.31,2.21c3,1.53,6.37,2.1,9.63,2.87l3.87.91c.23,0,1.4.16,1.53.36Z"
                style="
                  fill: rgb(38, 50, 56);
                  transform-origin: 328.938px 138.265px;
                "
                id="elvr0y5ozcvro"
                class="animable"
              ></path>
              <path
                d="M343.28,152.59a4,4,0,0,1-4.87-.27l.76,12.42c-2.44,5.57-10.59,6.69-16.9,12.86,0,0-2.46-4.06-.44-12.08l-.37-4.18a40.4,40.4,0,0,1-4.9-.35,8.46,8.46,0,0,1-6.71-6.61c-.86-3.69-.64-9.67.28-14a12.54,12.54,0,0,0,.27-3.4,15.94,15.94,0,0,1,1.21-7.26c3.64-2.77,11.9-3.52,19.45,1.36-1,4.55.66,8.22,3.94,10.05-.19,1.23-.43,4.34,1.6,4.34,1.46,0,1.61-1.83,3.16-3.41s5.05-1.91,6.34.51A7.88,7.88,0,0,1,343.28,152.59Z"
                style="
                  fill: rgb(255, 168, 167);
                  transform-origin: 328.092px 152.563px;
                "
                id="el8aoiwiw7xjg"
                class="animable"
              ></path>
              <polygon
                points="320.11 137.73 313.87 146.11 319.61 147.61 320.11 137.73"
                style="
                  fill: rgb(242, 143, 143);
                  transform-origin: 316.99px 142.67px;
                "
                id="elkajv4kxvlcp"
                class="animable"
              ></polygon>
              <path
                d="M324,136.39l3.17,1.56a1.85,1.85,0,0,0-.84-2.42A1.71,1.71,0,0,0,324,136.39Z"
                style="
                  fill: rgb(38, 50, 56);
                  transform-origin: 325.669px 136.651px;
                "
                id="eligznv9i9vi"
                class="animable"
              ></path>
              <path
                d="M311.85,137.12l3.14-1.67a1.71,1.71,0,0,0-2.35-.78A1.87,1.87,0,0,0,311.85,137.12Z"
                style="
                  fill: rgb(38, 50, 56);
                  transform-origin: 313.328px 135.795px;
                "
                id="el58q63yieyzw"
                class="animable"
              ></path>
              <path
                d="M321.46,161.34c5.15-.46,9.23-1,12-4.6a32.3,32.3,0,0,0,3.26-6,25.12,25.12,0,0,1-2.41,7.57c-1.35,2.33-4.62,4.41-12.69,4.88Z"
                style="
                  fill: rgb(242, 143, 143);
                  transform-origin: 329.09px 156.965px;
                "
                id="ellp5zi02mpxl"
                class="animable"
              ></path>
              <path
                d="M323.53,140.69a1.45,1.45,0,1,0,1.56-1.39A1.47,1.47,0,0,0,323.53,140.69Z"
                style="
                  fill: rgb(38, 50, 56);
                  transform-origin: 324.979px 140.746px;
                "
                id="elp4yq0ty4d5"
                class="animable"
              ></path>
              <path
                d="M324.86,149.7l-5.76,1.53a3.12,3.12,0,0,0,3.75,2.16A2.92,2.92,0,0,0,324.86,149.7Z"
                style="
                  fill: rgb(177, 102, 104);
                  transform-origin: 322.047px 151.591px;
                "
                id="elucvkw0g8hqk"
                class="animable"
              ></path>
              <path
                d="M321.21,153.34a3.52,3.52,0,0,1,3.77-2.8,2.88,2.88,0,0,1-2.13,2.85A3,3,0,0,1,321.21,153.34Z"
                style="
                  fill: rgb(242, 143, 143);
                  transform-origin: 323.095px 152.003px;
                "
                id="els4lg2ph7sg"
                class="animable"
              ></path>
              <path
                d="M314.39,138.57A1.49,1.49,0,0,0,313,140.1a1.45,1.45,0,1,0,1.43-1.53"
                style="
                  fill: rgb(38, 50, 56);
                  transform-origin: 314.449px 140.02px;
                "
                id="elq2n2rnshmwl"
                class="animable"
              ></path>
            </g>
            <g
              id="freepik--Tie--inject-42"
              class="animable"
              style="transform-origin: 328.648px 195.664px"
            >
              <path
                d="M320.22,176.81s-2.3-4.46-2.29-6.2l2.85-2.89.83,7.25Z"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 319.77px 172.265px;
                "
                id="elea45b9siwo4"
                class="animable"
              ></path>
              <g id="el9rupfljy3tr">
                <path
                  d="M320.22,176.81s-2.3-4.46-2.29-6.2l2.85-2.89.83,7.25Z"
                  style="opacity: 0.2; transform-origin: 319.77px 172.265px"
                  class="animable"
                ></path>
              </g>
              <polygon
                points="324.1 175.28 328.94 172.04 332.93 174.77 325.51 178.49 324.1 175.28"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 328.515px 175.265px;
                "
                id="el152e9c994h8"
                class="animable"
              ></polygon>
              <g id="elm7b2bzrd1z9">
                <polygon
                  points="324.1 175.28 328.94 172.04 332.93 174.77 325.51 178.49 324.1 175.28"
                  style="opacity: 0.2; transform-origin: 328.515px 175.265px"
                  class="animable"
                ></polygon>
              </g>
              <path
                d="M326.18,220c-2.44-13.76-2.73-35.32-2.41-39.09l-2.64-.27c-1.86,4.51-7.36,26.52-7.3,37.24-.15,3.74,4.6,13.79,7.12,13.89S326.57,222.21,326.18,220Z"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 320.02px 206.205px;
                "
                id="el4024hdgkxz4"
                class="animable"
              ></path>
              <g id="elgu6d9araa89">
                <path
                  d="M326.18,220c-2.44-13.76-2.73-35.32-2.41-39.09l-2.64-.27c-1.86,4.51-7.36,26.52-7.3,37.24-.15,3.74,4.6,13.79,7.12,13.89S326.57,222.21,326.18,220Z"
                  style="
                    fill: rgb(255, 255, 255);
                    opacity: 0.1;
                    transform-origin: 320.02px 206.205px;
                  "
                  class="animable"
                ></path>
              </g>
              <g id="eljcurl7gl6xh">
                <path
                  d="M320.92,181.18c.08-.24.14-.39.21-.57h0l2.64.27h0a8.54,8.54,0,0,0,0,1.16A4.59,4.59,0,0,1,320.92,181.18Z"
                  style="opacity: 0.5; transform-origin: 322.345px 181.327px"
                  class="animable"
                ></path>
              </g>
              <path
                d="M324.17,174.91a11.1,11.1,0,0,0-2.49-.12,6,6,0,0,0-1.46,2,9.08,9.08,0,0,0,.91,3.8,3.16,3.16,0,0,0,2.64.27,5.47,5.47,0,0,0,1.91-2.52A5,5,0,0,0,324.17,174.91Z"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 322.95px 177.899px;
                "
                id="eln4i862oigbe"
                class="animable"
              ></path>
              <g id="elmwj3r24weba">
                <path
                  d="M324.17,174.91a11.1,11.1,0,0,0-2.49-.12,6,6,0,0,0-1.46,2,9.08,9.08,0,0,0,.91,3.8,3.16,3.16,0,0,0,2.64.27,5.47,5.47,0,0,0,1.91-2.52A5,5,0,0,0,324.17,174.91Z"
                  style="
                    fill: rgb(255, 255, 255);
                    opacity: 0.1;
                    transform-origin: 322.95px 177.899px;
                  "
                  class="animable"
                ></path>
              </g>
              <path
                d="M324.11,174.9c.09,0,.2.2.28.22,1.84-1,2.13-1.33,2.62-1.19.92.27,3.1,4.11,3.64,5.35s.44,1.23,2.13.11a30.24,30.24,0,0,0,7.69-7.41,22.33,22.33,0,0,0,3-6.1s-1.93-2.67-2.5-3.69c-1.09-2-1-2.78-2.17-2.61V161c-.19,1.72-2.67,3.26-5.07,5.34s-6.29,4-7.2,4.93A18.17,18.17,0,0,0,324.11,174.9Z"
                style="
                  fill: rgb(230, 230, 230);
                  transform-origin: 333.79px 169.889px;
                "
                id="elxx3tx3hmp5"
                class="animable"
              ></path>
              <path
                d="M315.39,176.36c.27.7,2.5-4.78,3.59-4.67.53,0,1.71,1.53,2.7,3.1l.74,0a16.71,16.71,0,0,1-1.13-4.18,19.43,19.43,0,0,1,.54-5.07l-.37-4.18c-.29,0-.62,0-1,0a16.74,16.74,0,0,0-1.53,1.85c-1.07,1.75-3,4.93-3.39,7A18.11,18.11,0,0,0,315.39,176.36Z"
                style="
                  fill: rgb(230, 230, 230);
                  transform-origin: 318.808px 168.891px;
                "
                id="ell1sz9czvhuc"
                class="animable"
              ></path>
            </g>
            <polygon
              points="267.91 195 311.37 206.68 326.88 256.12 326.01 257.2 284.64 245.27 267.05 196.08 267.91 195"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 296.965px 226.1px;
              "
              id="elif4vaxlmr58"
              class="animable"
            ></polygon>
            <g id="el7hrn5jhiyxx">
              <polygon
                points="310.5 207.75 326.01 257.2 326.88 256.12 311.37 206.68 310.5 207.75"
                style="opacity: 0.35; transform-origin: 318.69px 231.94px"
                class="animable"
              ></polygon>
            </g>
            <g id="elrs1qad4dasb">
              <polygon
                points="267.05 196.08 310.5 207.75 326.01 257.2 284.64 245.27 267.05 196.08"
                style="opacity: 0.25; transform-origin: 296.53px 226.64px"
                class="animable"
              ></polygon>
            </g>
            <g id="el9ibuj5vq8ao">
              <g
                style="opacity: 0.1; transform-origin: 290.455px 207.068px"
                class="animable"
              >
                <path
                  d="M280.58,205.41s-.21-.74-.48-1.66,2.26-.62,5.63.67l7.82,1.72c3.37,1.29,6.53,2.2,6.8,3.12l.48,1.66Z"
                  id="eliaou88swmhk"
                  class="animable"
                  style="transform-origin: 290.455px 207.068px"
                ></path>
              </g>
            </g>
            <path
              d="M277.48,198c-.16-.57.81-.53,2.19-.27l2.5.64s-1.18-2.37,4.7-1.25c6.48,1.23,6.86,4.39,6.86,4.39a27.21,27.21,0,0,0,3.13.87c2.16.53,2.43.92,2.64,1.5l.4,1L277.75,199S277.65,198.55,277.48,198Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 288.681px 200.85px;
              "
              id="el9ejkd06vrad"
              class="animable"
            ></path>
            <path
              d="M280,201c2.06.76,2.93,1.14,3.34,2.55.46,1.61.59,2.29.59,2.29l11.57,3.57s-.8-2.37-1-3.16,1.28-.92,3.29-.17l-.62-2-18-5.15Z"
              style="fill: rgb(55, 71, 79); transform-origin: 288.48px 204.17px"
              id="elh4e5qsfffbp"
              class="animable"
            ></path>
            <path
              d="M280,205.49s-.22-.75-.48-1.67,2.25-.61,5.63.67l7.81,1.73c3.37,1.28,6.54,2.19,6.8,3.11l.48,1.66Z"
              style="
                fill: rgb(55, 71, 79);
                transform-origin: 289.871px 207.14px;
              "
              id="elab178m5wg0f"
              class="animable"
            ></path>
            <path
              d="M366.36,187.37c-2.1-13.06-5.95-15.25-13.28-16.69-6.65,10.35-2.58,25.76-2.58,25.76l4,21.4c-1.21,1-19.8,11.77-23,12.85-1.17.4-3.37,0-4.54-.43-3.14-1-5.33-.69-8.36-.52l1.35,4.31c-4.81,1.68-9.93,1.58-14.95,1.69-.48,0-1.07.08-1.26.53a1,1,0,0,0,.42,1.14,4.49,4.49,0,0,1,1,.86c.37.57.22,1.32.32,2a5.15,5.15,0,0,0,1.54,2.54,11.86,11.86,0,0,0,2.51,2.29c3.08,1.89,7.8,2.07,11.28,1.47a37.53,37.53,0,0,0,6.93-2.14c2.12-.81,15-4.78,16.56-5.33,11.39-4.13,27.06-9.21,27.92-14.64S368.58,201.11,366.36,187.37Z"
              style="
                fill: rgb(255, 168, 167);
                transform-origin: 338.012px 208.778px;
              "
              id="elozzmevjrjna"
              class="animable"
            ></path>
            <path
              d="M353.22,169.8c8.61,3.5,9.95,3.85,12.27,10.51,2.24,6.45,7.12,35.59,6.81,43.27-5.06-4.28-12-5.69-17.78-5.74L350,196.61C348.72,190.82,345.55,178.8,353.22,169.8Z"
              style="
                fill: rgb(230, 230, 230);
                transform-origin: 360.284px 196.69px;
              "
              id="eleutg0136mgd"
              class="animable"
            ></path>
            <path
              d="M353.86,215.07c-.5.25.27,1,0,2s-1.46,1.47-1.26,2c0,0,6.91-.79,11.48,2.68s4.36,7.14,4.36,7.14l-1,.67s1.28,1.92,3.33-.19,3-6.11,2.57-9.46,0-4.93-1.59-5.46S360.35,211.91,353.86,215.07Z"
              style="
                fill: rgb(235, 235, 235);
                transform-origin: 363.009px 221.846px;
              "
              id="eleeug5rl1fsv"
              class="animable"
            ></path>
            <path
              d="M353.73,217.37h0c1.81-1.2,9.55-2.15,13.89,1.23-2-2.57-7.27-4.5-13.74-2.25h0a1.81,1.81,0,0,1-.05.76A2,2,0,0,1,353.73,217.37Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 360.675px 216.979px;
              "
              id="el6qwo38goewd"
              class="animable"
            ></path>
            <path
              d="M371.16,225.37c-1.16-3.32-5.36-6.54-9.5-7.34C366.65,218.62,370.33,220.58,371.16,225.37Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 366.41px 221.7px;
              "
              id="eljv6jjzjd3r"
              class="animable"
            ></path>
            <path
              d="M348.32,137.9a17.81,17.81,0,0,1-1.63,5.15,19.69,19.69,0,0,0-1.28-1.54,33.78,33.78,0,0,0-9.26-6.9h0c-8.05-4.2-18.1-6.09-26.31-4.48a17.31,17.31,0,0,1,1.74-4.31,19.6,19.6,0,0,1,20.21-9.41C342.52,118.08,349.92,127.71,348.32,137.9Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 329.189px 129.59px;
              "
              id="elzmu6u43p44n"
              class="animable"
            ></path>
            <path
              d="M340.87,118.79c-1-.51-5.82-2.24-6.21-2.38-2.52-.9-6.64-1.12-11.05,1.91a18.53,18.53,0,0,0-7.17,9.73l5.17,1.91,1.67.26c.82-3.63,6.34-11.21,13.51-11a8.94,8.94,0,0,1,1,.08,9.45,9.45,0,0,1,2.73.72,26.32,26.32,0,0,1,2.66,2.29A4.21,4.21,0,0,0,340.87,118.79Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 329.81px 123.02px;
              "
              id="ell7jx360gs4"
              class="animable"
            ></path>
            <g id="elg2qnkxnxfh">
              <path
                d="M340.87,118.79c-1-.51-5.82-2.24-6.21-2.38-2.52-.9-6.64-1.12-11.05,1.91a18.53,18.53,0,0,0-7.17,9.73l5.17,1.91,1.67.26c.82-3.63,6.34-11.21,13.51-11a8.94,8.94,0,0,1,1,.08,9.45,9.45,0,0,1,2.73.72,26.32,26.32,0,0,1,2.66,2.29A4.21,4.21,0,0,0,340.87,118.79Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.45;
                  transform-origin: 329.81px 123.02px;
                "
                class="animable"
              ></path>
            </g>
            <path
              d="M323.27,130.22c1-4.22,8.37-13.89,17.26-10.26a27.84,27.84,0,0,1,2.67,2.3,4.26,4.26,0,0,0-2.33-3.47c-2.3-1.22-7-2.06-12.1,1.45a18.5,18.5,0,0,0-7.16,9.72Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 332.405px 123.977px;
              "
              id="elhitelnsf5qw"
              class="animable"
            ></path>
            <g id="el9ujpibkrv24">
              <path
                d="M323.27,130.22c1-4.22,8.37-13.89,17.26-10.26a27.84,27.84,0,0,1,2.67,2.3,4.26,4.26,0,0,0-2.33-3.47c-2.3-1.22-7-2.06-12.1,1.45a18.5,18.5,0,0,0-7.16,9.72Z"
                style="opacity: 0.3; transform-origin: 332.405px 123.977px"
                class="animable"
              ></path>
            </g>
            <path
              d="M336.36,133.15c-.36-.35-2-4.21-8.33-7.94-3.91-2.31-12.16-2.94-15.86-3.12-6.48-.32-8.1.89-8.23,1.79,0,.21-.18,1.21-.22,1.43a.89.89,0,0,0,.1.59c.9,1.46,6,4.22,6,4.22,8.21-1.61,18.26.28,26.31,4.48Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 320.033px 128.319px;
              "
              id="elt50zl2bv5y"
              class="animable"
            ></path>
            <g id="elmgdg1fzkmxc">
              <path
                d="M336.36,133.15c-.36-.35-2-4.21-8.33-7.94-3.91-2.31-12.16-2.94-15.86-3.12-6.48-.32-8.1.89-8.23,1.79,0,.21-.18,1.21-.22,1.43a.89.89,0,0,0,.1.59c.9,1.46,6,4.22,6,4.22,8.21-1.61,18.26.28,26.31,4.48Z"
                style="opacity: 0.15; transform-origin: 320.033px 128.319px"
                class="animable"
              ></path>
            </g>
            <path
              d="M336.13,134.6c-8.05-4.2-18.1-6.09-26.31-4.48,0,0-5.1-2.76-6-4.22-.48-.8.1-2.75,8.12-2.36,3.71.18,11.95.82,15.86,3.12A21.78,21.78,0,0,1,336.13,134.6Z"
              style="
                fill: rgb(159, 209, 255);
                transform-origin: 319.913px 129.045px;
              "
              id="elfmfnz6agbxb"
              class="animable"
            ></path>
            <g id="el8vb4wluznoy">
              <path
                d="M336.13,134.6c-8.05-4.2-18.1-6.09-26.31-4.48,0,0-5.1-2.76-6-4.22-.48-.8.1-2.75,8.12-2.36,3.71.18,11.95.82,15.86,3.12A21.78,21.78,0,0,1,336.13,134.6Z"
                style="opacity: 0.3; transform-origin: 319.913px 129.045px"
                class="animable"
              ></path>
            </g>
          </g>
        </g>
      </g>
      <g
        id="freepik--Coins--inject-42"
        class="animable"
        style="transform-origin: 386.545px 411.68px"
      >
        <g
          id="freepik--coins--inject-42"
          class="animable"
          style="transform-origin: 386.545px 411.68px"
        >
          <path
            d="M412.63,420.1a17.21,17.21,0,0,0-6.37-5.88c-10.41-6-27.28-6-37.69,0a17.21,17.21,0,0,0-6.37,5.88h-1.43v5.45h0c.2,3.79,2.79,7.54,7.79,10.43,10.41,6,27.28,6,37.69,0,5-2.89,7.61-6.64,7.81-10.43h0V420.1Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.415px 425.1px;
            "
            id="el49fbxethy2u"
            class="animable"
          ></path>
          <g id="el055xdhg7b8t5">
            <g
              style="opacity: 0.25; transform-origin: 387.415px 425.1px"
              class="animable"
            >
              <path
                d="M412.63,420.1a17.21,17.21,0,0,0-6.37-5.88c-10.41-6-27.28-6-37.69,0a17.21,17.21,0,0,0-6.37,5.88h-1.43v5.45h0c.2,3.79,2.79,7.54,7.79,10.43,10.41,6,27.28,6,37.69,0,5-2.89,7.61-6.64,7.81-10.43h0V420.1Z"
                id="elg22p9vfsdo6"
                class="animable"
                style="transform-origin: 387.415px 425.1px"
              ></path>
            </g>
          </g>
          <g id="elzjyno4mn2pi">
            <path
              d="M387.42,409.71c-6.82,0-13.65,1.5-18.85,4.51a17.21,17.21,0,0,0-6.37,5.88h-1.44v5.45h0c.2,3.79,2.79,7.54,7.79,10.43,5.2,3,12,4.5,18.85,4.5Z"
              style="opacity: 0.1; transform-origin: 374.09px 425.095px"
              class="animable"
            ></path>
          </g>
          <g id="elhyy5g9bphmw">
            <path
              d="M372.86,412.21a28.63,28.63,0,0,0-4.29,2,17.21,17.21,0,0,0-6.37,5.88h-1.44v5.45h0c.2,3.79,2.79,7.54,7.79,10.43a29.65,29.65,0,0,0,4.29,2Z"
              style="opacity: 0.1; transform-origin: 366.81px 425.09px"
              class="animable"
            ></path>
          </g>
          <g id="elf5x5kirsqrs">
            <path
              d="M402,412.21a28.63,28.63,0,0,1,4.29,2,17.21,17.21,0,0,1,6.37,5.88h1.44v5.45h0c-.2,3.79-2.81,7.54-7.81,10.43a29.65,29.65,0,0,1-4.29,2Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.5;
                transform-origin: 408.05px 425.09px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M406.26,409.23c-10.41-6-27.28-6-37.69,0s-10.41,15.75,0,21.76,27.28,6,37.69,0S416.67,415.24,406.26,409.23Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.415px 420.112px;
            "
            id="el86qm2by7evl"
            class="animable"
          ></path>
          <g id="eli66dzqu0dhi">
            <path
              d="M406.26,409.23c-10.41-6-27.28-6-37.69,0s-10.41,15.75,0,21.76,27.28,6,37.69,0S416.67,415.24,406.26,409.23Z"
              style="opacity: 0.2; transform-origin: 387.415px 420.112px"
              class="animable"
            ></path>
          </g>
          <path
            d="M399.69,413.21a30.06,30.06,0,0,0-24.55,0c-3.48,1.79-5.41,4.24-5.41,6.9s1.93,5.11,5.41,6.9a30.06,30.06,0,0,0,24.55,0c3.48-1.79,5.4-4.24,5.4-6.9S403.17,415,399.69,413.21Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.41px 420.11px;
            "
            id="elf4r18o83suj"
            class="animable"
          ></path>
          <g id="el4qazwd1difo">
            <path
              d="M399.69,413.21a30.06,30.06,0,0,0-24.55,0c-3.48,1.79-5.41,4.24-5.41,6.9s1.93,5.11,5.41,6.9a30.06,30.06,0,0,0,24.55,0c3.48-1.79,5.4-4.24,5.4-6.9S403.17,415,399.69,413.21Z"
              style="opacity: 0.4; transform-origin: 387.41px 420.11px"
              class="animable"
            ></path>
          </g>
          <path
            d="M405.68,410.23c-4.86-2.8-11.35-4.35-18.27-4.35s-13.4,1.55-18.26,4.35c-4.66,2.69-7.22,6.2-7.22,9.88s2.56,7.18,7.22,9.87c4.86,2.81,11.35,4.35,18.26,4.35s13.41-1.54,18.27-4.35c4.66-2.69,7.23-6.19,7.23-9.87S410.34,412.92,405.68,410.23Zm-6,16.78a30.06,30.06,0,0,1-24.55,0c-3.48-1.79-5.41-4.24-5.41-6.9s1.93-5.11,5.41-6.9a30.06,30.06,0,0,1,24.55,0c3.48,1.79,5.4,4.24,5.4,6.9S403.17,425.22,399.69,427Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.42px 420.105px;
            "
            id="elwz85bt3rbbl"
            class="animable"
          ></path>
          <g id="el5b81wj8swhr">
            <path
              d="M405.68,410.23c-4.86-2.8-11.35-4.35-18.27-4.35s-13.4,1.55-18.26,4.35c-4.66,2.69-7.22,6.2-7.22,9.88s2.56,7.18,7.22,9.87c4.86,2.81,11.35,4.35,18.26,4.35s13.41-1.54,18.27-4.35c4.66-2.69,7.23-6.19,7.23-9.87S410.34,412.92,405.68,410.23Zm-6,16.78a30.06,30.06,0,0,1-24.55,0c-3.48-1.79-5.41-4.24-5.41-6.9s1.93-5.11,5.41-6.9a30.06,30.06,0,0,1,24.55,0c3.48,1.79,5.4,4.24,5.4,6.9S403.17,425.22,399.69,427Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.3;
                transform-origin: 387.42px 420.105px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elkso4tpdy3gm">
            <path
              d="M399.27,426.19c6.54-3.36,6.54-8.81,0-12.17s-17.16-3.36-23.71,0-6.54,8.81,0,12.17S392.72,429.55,399.27,426.19Z"
              style="opacity: 0.2; transform-origin: 387.413px 420.105px"
              class="animable"
            ></path>
          </g>
          <path
            d="M399.27,426.19c6.54-3.36,6.54-8.81,0-12.17s-17.16-3.36-23.71,0-6.54,8.81,0,12.17S392.72,429.55,399.27,426.19Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.413px 420.105px;
            "
            id="elhpzibhlb9v"
            class="animable"
          ></path>
          <g id="ellc13y4n8w0a">
            <path
              d="M399.27,426.19c6.54-3.36,6.54-8.81,0-12.17s-17.16-3.36-23.71,0-6.54,8.81,0,12.17S392.72,429.55,399.27,426.19Z"
              style="opacity: 0.45; transform-origin: 387.413px 420.105px"
              class="animable"
            ></path>
          </g>
          <path
            d="M399.27,416.3c-6.55-3.36-17.16-3.36-23.71,0-2.7,1.39-4.29,3.14-4.76,4.95.47,1.8,2.06,3.55,4.76,4.94,6.55,3.36,17.16,3.36,23.71,0,2.7-1.39,4.29-3.14,4.76-4.94C403.56,419.44,402,417.69,399.27,416.3Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.415px 421.245px;
            "
            id="el7fn6e8npd8g"
            class="animable"
          ></path>
          <g id="elhz7h7pt8vo">
            <path
              d="M399.27,416.3c-6.55-3.36-17.16-3.36-23.71,0-2.7,1.39-4.29,3.14-4.76,4.95.47,1.8,2.06,3.55,4.76,4.94,6.55,3.36,17.16,3.36,23.71,0,2.7-1.39,4.29-3.14,4.76-4.94C403.56,419.44,402,417.69,399.27,416.3Z"
              style="opacity: 0.3; transform-origin: 387.415px 421.245px"
              class="animable"
            ></path>
          </g>
          <polygon
            points="389 414.15 389 416.61 388.16 417.5 386.61 419.12 386.61 416.57 389 414.15"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.805px 416.635px;
            "
            id="elvb8xuirguo"
            class="animable"
          ></polygon>
          <g id="eldk9t9tgotwr">
            <polygon
              points="389 414.15 389 416.61 388.16 417.5 386.61 419.12 386.61 416.57 389 414.15"
              style="opacity: 0.3; transform-origin: 387.805px 416.635px"
              class="animable"
            ></polygon>
          </g>
          <path
            d="M386.61,416.56V418a12.6,12.6,0,0,1-2.38.68,2.5,2.5,0,0,1-1,0,1.81,1.81,0,0,1-.48-.2c-.59-.35-.69-.87.44-1.52A3.92,3.92,0,0,1,386.61,416.56Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 384.464px 417.534px;
            "
            id="elub4wtsaxwha"
            class="animable"
          ></path>
          <g id="el3r7vdzbad17">
            <path
              d="M386.61,416.56V418a12.6,12.6,0,0,1-2.38.68,2.5,2.5,0,0,1-1,0,1.81,1.81,0,0,1-.48-.2c-.59-.35-.69-.87.44-1.52A3.92,3.92,0,0,1,386.61,416.56Z"
              style="opacity: 0.45; transform-origin: 384.464px 417.534px"
              class="animable"
            ></path>
          </g>
          <path
            d="M397.74,423.54v2.13l-2.69,1.55-2-1.15a10.15,10.15,0,0,1-7.7.34v-2.12l1.22-1.21-1.22.34a7.88,7.88,0,0,1-6-.69c-1.21-.69-1.77-1.46-1.77-2.24v-2.06l.22-.91-.73-.42V415l2,1.15a3.09,3.09,0,0,0-1.48,2.28v0c0,.76.59,1.5,1.77,2.18,2.61,1.51,5.2,1,7.57.25,2.21-.72,3.82-1.57,5.09-.83.79.45.55,1-.34,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a8.33,8.33,0,0,0,1.41.41,10.45,10.45,0,0,0,6.29-.75l2,1.15Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.405px 421.11px;
            "
            id="elqqqtk7wxme"
            class="animable"
          ></path>
          <g id="el7zgj3xs1in">
            <path
              d="M377.6,418.4v0c0,.76.59,1.5,1.77,2.18,2.61,1.51,5.2,1,7.57.25,2.21-.72,3.82-1.57,5.09-.83.79.45.55,1-.34,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a8.33,8.33,0,0,0,1.41.41,10.45,10.45,0,0,0,6.29-.75l2,1.15,2.69-1.56v2.13l-2.69,1.55-2-1.15a10.15,10.15,0,0,1-7.7.34v-2.12l1.22-1.21-1.22.34a7.88,7.88,0,0,1-6-.69c-1.21-.69-1.77-1.46-1.77-2.24v-2.06l.22-.91"
              style="opacity: 0.2; transform-origin: 387.67px 422.34px"
              class="animable"
            ></path>
          </g>
          <g id="el4umte8xh157">
            <path
              d="M377.09,415v2.13l.73.42h0a4.33,4.33,0,0,1,1.26-1.41Z"
              style="opacity: 0.45; transform-origin: 378.085px 416.275px"
              class="animable"
            ></path>
          </g>
          <path
            d="M397.29,422.16a2.39,2.39,0,0,1-.2,1l-1.34-.77a2.66,2.66,0,0,0,1.52-2C397.28,420.93,397.29,422.1,397.29,422.16Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 396.52px 421.775px;
            "
            id="ell3j7canpjz"
            class="animable"
          ></path>
          <g id="ellxfj66cmzg">
            <g
              style="opacity: 0.3; transform-origin: 396.52px 421.775px"
              class="animable"
            >
              <path
                d="M397.29,422.16a2.39,2.39,0,0,1-.2,1l-1.34-.77a2.66,2.66,0,0,0,1.52-2C397.28,420.93,397.29,422.1,397.29,422.16Z"
                id="elzhtkm75r8da"
                class="animable"
                style="transform-origin: 396.52px 421.775px"
              ></path>
            </g>
          </g>
          <path
            d="M397.29,423.29l-1.54-.9a2.61,2.61,0,0,0,1.54-2.35,1.36,1.36,0,0,0,0-.21,2.86,2.86,0,0,0-1.71-2.08h0a7.07,7.07,0,0,0-3.58-.93h-.34l-.32,0-.28,0h-.11l-.22,0a13.59,13.59,0,0,0-1.73.36c-.28.07-.55.15-.82.24l-1.12.36-.43.15a13.41,13.41,0,0,1-2.38.68,2.5,2.5,0,0,1-1,0,2.45,2.45,0,0,1-.48-.2c-.6-.35-.69-.87.44-1.52a3.88,3.88,0,0,1,3.45-.36l2.39-2.41a10.25,10.25,0,0,0-7.21.39l-2-1.14L377.09,415l2,1.15a3.09,3.09,0,0,0-1.48,2.28v0c0,.77.59,1.51,1.77,2.19,2.61,1.51,5.2,1,7.57.25,2.21-.72,3.81-1.57,5.09-.84.79.46.55,1-.34,1.53a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a10,10,0,0,0,1.4.41,9,9,0,0,0,2.46.18,11.34,11.34,0,0,0,3.84-.93l2,1.15,2.68-1.55Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 387.42px 419.24px;
            "
            id="ellk409gue25"
            class="animable"
          ></path>
          <g id="elqy6ei9e1lhh">
            <g
              style="opacity: 0.3; transform-origin: 387.42px 419.24px"
              class="animable"
            >
              <path
                d="M397.29,423.29l-1.54-.9a2.61,2.61,0,0,0,1.54-2.35,1.36,1.36,0,0,0,0-.21,2.86,2.86,0,0,0-1.71-2.08h0a7.07,7.07,0,0,0-3.58-.93h-.34l-.32,0-.28,0h-.11l-.22,0a13.59,13.59,0,0,0-1.73.36c-.28.07-.55.15-.82.24l-1.12.36-.43.15a13.41,13.41,0,0,1-2.38.68,2.5,2.5,0,0,1-1,0,2.45,2.45,0,0,1-.48-.2c-.6-.35-.69-.87.44-1.52a3.88,3.88,0,0,1,3.45-.36l2.39-2.41a10.25,10.25,0,0,0-7.21.39l-2-1.14L377.09,415l2,1.15a3.09,3.09,0,0,0-1.48,2.28v0c0,.77.59,1.51,1.77,2.19,2.61,1.51,5.2,1,7.57.25,2.21-.72,3.81-1.57,5.09-.84.79.46.55,1-.34,1.53a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a10,10,0,0,0,1.4.41,9,9,0,0,0,2.46.18,11.34,11.34,0,0,0,3.84-.93l2,1.15,2.68-1.55Z"
                style="
                  fill: rgb(255, 255, 255);
                  transform-origin: 387.42px 419.24px;
                "
                id="elg79snhm0x08"
                class="animable"
              ></path>
            </g>
          </g>
          <polygon
            points="393.06 423.94 393.06 426.07 395.05 427.22 395.05 425.1 393.06 423.94"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 394.055px 425.58px;
            "
            id="elnohue97p5z"
            class="animable"
          ></polygon>
          <g id="elpny71s8w0r">
            <polygon
              points="393.06 423.94 393.06 426.07 395.05 427.22 395.05 425.1 393.06 423.94"
              style="opacity: 0.45; transform-origin: 394.055px 425.58px"
              class="animable"
            ></polygon>
          </g>
          <g id="el83i8j8ewfvv">
            <polygon
              points="377.09 414.98 379.8 413.41 381.79 414.56 379.82 413.73 377.09 414.98"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 379.44px 414.195px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="el2m6czf14n9s">
            <path
              d="M383.16,416.93a3.88,3.88,0,0,1,3.45-.36l2.39-2.41-2.39,2.16A3.6,3.6,0,0,0,383.16,416.93Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 386.08px 415.545px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el33sw9tnyuls">
            <path
              d="M385.36,424.29a10,10,0,0,0,1.4.41,9,9,0,0,0,2.46.18,8.67,8.67,0,0,1-3.46-.69l2.14-2.42Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 387.29px 423.334px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elqc8tfcjjcqg">
            <polygon
              points="393.06 423.94 395.05 424.84 397.73 423.55 395.05 425.1 393.06 423.94"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 395.395px 424.325px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="elv8pdpboeilm">
            <path
              d="M369.36,410.59c7.05-4.29,18.05-4.71,18.05-4.71-6.91,0-13.4,1.55-18.26,4.35-4.66,2.69-7.22,6.2-7.22,9.88C361.93,420.11,362.18,415,369.36,410.59Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 374.67px 412.995px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el9ynhohy66l">
            <path
              d="M385.36,435.45c7.49.33,15.17-1.16,20.9-4.46,6-3.44,8.5-8.1,7.64-12.59,0,0,1.12,7.31-8.09,12.31S385.36,435.45,385.36,435.45Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 399.712px 426.947px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M408.82,412.33a17.13,17.13,0,0,0-6.37-5.88c-10.4-6-27.28-6-37.69,0a17.1,17.1,0,0,0-6.36,5.88H357v5.45h0c.19,3.78,2.78,7.54,7.78,10.42,10.41,6,27.29,6,37.69,0,5-2.88,7.62-6.64,7.81-10.42h0v-5.45Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.64px 417.325px;
            "
            id="el4hhccizsxyi"
            class="animable"
          ></path>
          <g id="el94z3aiaatcc">
            <g
              style="opacity: 0.25; transform-origin: 383.64px 417.325px"
              class="animable"
            >
              <path
                d="M408.82,412.33a17.13,17.13,0,0,0-6.37-5.88c-10.4-6-27.28-6-37.69,0a17.1,17.1,0,0,0-6.36,5.88H357v5.45h0c.19,3.78,2.78,7.54,7.78,10.42,10.41,6,27.29,6,37.69,0,5-2.88,7.62-6.64,7.81-10.42h0v-5.45Z"
                id="elao8f288lzqr"
                class="animable"
                style="transform-origin: 383.64px 417.325px"
              ></path>
            </g>
          </g>
          <g id="elwtspn6km1q">
            <path
              d="M383.61,401.94c-6.82,0-13.64,1.5-18.85,4.51a17.1,17.1,0,0,0-6.36,5.88H357v5.45h0c.19,3.78,2.78,7.54,7.78,10.42,5.21,3,12,4.51,18.85,4.51Z"
              style="opacity: 0.1; transform-origin: 370.315px 417.325px"
              class="animable"
            ></path>
          </g>
          <g id="elg05b4wkzxxb">
            <path
              d="M369.05,404.44a29.59,29.59,0,0,0-4.29,2,17.1,17.1,0,0,0-6.36,5.88H357v5.45h0c.19,3.78,2.78,7.54,7.78,10.42a29.59,29.59,0,0,0,4.29,2Z"
              style="opacity: 0.1; transform-origin: 363.035px 417.315px"
              class="animable"
            ></path>
          </g>
          <g id="el2dt24srlg1h">
            <path
              d="M398.16,404.44a28.63,28.63,0,0,1,4.29,2,17.13,17.13,0,0,1,6.37,5.88h1.44v5.45h0c-.19,3.78-2.8,7.54-7.81,10.43a29.62,29.62,0,0,1-4.29,2Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.5;
                transform-origin: 404.21px 417.32px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M402.45,401.45c-10.4-6-27.28-6-37.69,0s-10.4,15.76,0,21.76,27.29,6,37.69,0S412.86,407.46,402.45,401.45Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.605px 412.33px;
            "
            id="ellpqa9uh9ggh"
            class="animable"
          ></path>
          <g id="eli9i3arn2suc">
            <path
              d="M402.45,401.45c-10.4-6-27.28-6-37.69,0s-10.4,15.76,0,21.76,27.29,6,37.69,0S412.86,407.46,402.45,401.45Z"
              style="opacity: 0.2; transform-origin: 383.605px 412.33px"
              class="animable"
            ></path>
          </g>
          <path
            d="M395.88,405.44a30,30,0,0,0-24.54,0c-3.49,1.78-5.41,4.24-5.41,6.9s1.92,5.1,5.41,6.89a30,30,0,0,0,24.54,0c3.49-1.79,5.41-4.23,5.41-6.89S399.37,407.22,395.88,405.44Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.61px 412.335px;
            "
            id="ely66s6l6fqan"
            class="animable"
          ></path>
          <g id="elrd1c70b0txt">
            <path
              d="M395.88,405.44a30,30,0,0,0-24.54,0c-3.49,1.78-5.41,4.24-5.41,6.9s1.92,5.1,5.41,6.89a30,30,0,0,0,24.54,0c3.49-1.79,5.41-4.23,5.41-6.89S399.37,407.22,395.88,405.44Z"
              style="opacity: 0.4; transform-origin: 383.61px 412.335px"
              class="animable"
            ></path>
          </g>
          <path
            d="M401.87,402.46c-4.86-2.81-11.34-4.35-18.26-4.35s-13.4,1.54-18.26,4.35c-4.67,2.69-7.23,6.19-7.23,9.88s2.56,7.18,7.23,9.87c4.86,2.8,11.34,4.35,18.26,4.35s13.4-1.55,18.26-4.35c4.66-2.69,7.23-6.2,7.23-9.87S406.53,405.15,401.87,402.46Zm-6,16.77a30,30,0,0,1-24.54,0c-3.49-1.79-5.41-4.23-5.41-6.89s1.92-5.12,5.41-6.9a30,30,0,0,1,24.54,0c3.49,1.78,5.41,4.24,5.41,6.9S399.37,417.44,395.88,419.23Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.61px 412.335px;
            "
            id="el51frfdiwlet"
            class="animable"
          ></path>
          <g id="elz1poi1rxklk">
            <path
              d="M401.87,402.46c-4.86-2.81-11.34-4.35-18.26-4.35s-13.4,1.54-18.26,4.35c-4.67,2.69-7.23,6.19-7.23,9.88s2.56,7.18,7.23,9.87c4.86,2.8,11.34,4.35,18.26,4.35s13.4-1.55,18.26-4.35c4.66-2.69,7.23-6.2,7.23-9.87S406.53,405.15,401.87,402.46Zm-6,16.77a30,30,0,0,1-24.54,0c-3.49-1.79-5.41-4.23-5.41-6.89s1.92-5.12,5.41-6.9a30,30,0,0,1,24.54,0c3.49,1.78,5.41,4.24,5.41,6.9S399.37,417.44,395.88,419.23Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.3;
                transform-origin: 383.61px 412.335px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el61swozijat9">
            <path
              d="M395.46,418.42c6.55-3.36,6.55-8.81,0-12.17s-17.16-3.36-23.7,0-6.55,8.81,0,12.17S388.92,421.78,395.46,418.42Z"
              style="opacity: 0.2; transform-origin: 383.612px 412.335px"
              class="animable"
            ></path>
          </g>
          <path
            d="M395.46,418.42c6.55-3.36,6.55-8.81,0-12.17s-17.16-3.36-23.7,0-6.55,8.81,0,12.17S388.92,421.78,395.46,418.42Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.612px 412.335px;
            "
            id="elem3z78y4t0r"
            class="animable"
          ></path>
          <g id="elaf1bt2k4au8">
            <path
              d="M395.46,418.42c6.55-3.36,6.55-8.81,0-12.17s-17.16-3.36-23.7,0-6.55,8.81,0,12.17S388.92,421.78,395.46,418.42Z"
              style="opacity: 0.45; transform-origin: 383.612px 412.335px"
              class="animable"
            ></path>
          </g>
          <path
            d="M395.46,408.53c-6.54-3.36-17.16-3.36-23.7,0-2.71,1.39-4.29,3.14-4.76,4.94.47,1.81,2.05,3.56,4.76,4.95,6.54,3.36,17.16,3.36,23.7,0,2.71-1.39,4.29-3.14,4.76-4.95C399.75,411.67,398.17,409.92,395.46,408.53Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.61px 413.475px;
            "
            id="eliu8ij1d39ad"
            class="animable"
          ></path>
          <g id="eldtm0b3kb3jb">
            <path
              d="M395.46,408.53c-6.54-3.36-17.16-3.36-23.7,0-2.71,1.39-4.29,3.14-4.76,4.94.47,1.81,2.05,3.56,4.76,4.95,6.54,3.36,17.16,3.36,23.7,0,2.71-1.39,4.29-3.14,4.76-4.95C399.75,411.67,398.17,409.92,395.46,408.53Z"
              style="opacity: 0.3; transform-origin: 383.61px 413.475px"
              class="animable"
            ></path>
          </g>
          <polygon
            points="385.2 406.38 385.2 408.84 384.36 409.73 382.81 411.35 382.81 408.79 385.2 406.38"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 384.005px 408.865px;
            "
            id="el8p0aawztf3x"
            class="animable"
          ></polygon>
          <g id="elj6z0kzm14xj">
            <polygon
              points="385.2 406.38 385.2 408.84 384.36 409.73 382.81 411.35 382.81 408.79 385.2 406.38"
              style="opacity: 0.3; transform-origin: 384.005px 408.865px"
              class="animable"
            ></polygon>
          </g>
          <path
            d="M382.81,408.79v1.45a14.66,14.66,0,0,1-2.39.68,2.45,2.45,0,0,1-1,0,2.3,2.3,0,0,1-.49-.2c-.59-.35-.69-.88.44-1.53A4,4,0,0,1,382.81,408.79Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 380.654px 409.772px;
            "
            id="elkqothjrjj2o"
            class="animable"
          ></path>
          <g id="elidpykrbwvlt">
            <path
              d="M382.81,408.79v1.45a14.66,14.66,0,0,1-2.39.68,2.45,2.45,0,0,1-1,0,2.3,2.3,0,0,1-.49-.2c-.59-.35-.69-.88.44-1.53A4,4,0,0,1,382.81,408.79Z"
              style="opacity: 0.45; transform-origin: 380.654px 409.772px"
              class="animable"
            ></path>
          </g>
          <path
            d="M393.93,415.77v2.12l-2.69,1.55-2-1.15a10.07,10.07,0,0,1-7.69.35v-2.12l1.22-1.21-1.22.33a7.86,7.86,0,0,1-6-.68c-1.21-.7-1.77-1.46-1.76-2.24v-2.07l.21-.9-.73-.43V407.2l2,1.15a3,3,0,0,0-1.47,2.28v0c0,.77.58,1.51,1.76,2.19,2.61,1.51,5.2,1,7.58.25,2.2-.72,3.81-1.57,5.08-.84.79.46.55,1-.33,1.53a4.42,4.42,0,0,1-3.79.22l-2.54,2.52a9.6,9.6,0,0,0,1.4.41,10.44,10.44,0,0,0,6.29-.76l2,1.15Z"
            style="fill: rgb(159, 209, 255); transform-origin: 383.6px 413.32px"
            id="elswl7mpnhx1r"
            class="animable"
          ></path>
          <g id="elgdkg18q15u7">
            <path
              d="M373.8,410.63v0c0,.77.58,1.51,1.76,2.19,2.61,1.51,5.2,1,7.58.25,2.2-.72,3.81-1.57,5.08-.84.79.46.55,1-.33,1.53a4.42,4.42,0,0,1-3.79.22l-2.54,2.52a9.6,9.6,0,0,0,1.4.41,10.44,10.44,0,0,0,6.29-.76l2,1.15,2.69-1.55v2.12l-2.69,1.55-2-1.15a10.07,10.07,0,0,1-7.69.35v-2.12l1.22-1.21-1.22.33a7.86,7.86,0,0,1-6-.68c-1.21-.7-1.77-1.46-1.76-2.24v-2.07l.21-.9"
              style="opacity: 0.2; transform-origin: 383.87px 414.575px"
              class="animable"
            ></path>
          </g>
          <g id="el2djiyzkvma9">
            <path
              d="M373.28,407.2v2.12l.73.43h0a4.35,4.35,0,0,1,1.26-1.41Z"
              style="opacity: 0.45; transform-origin: 374.275px 408.475px"
              class="animable"
            ></path>
          </g>
          <path
            d="M393.49,414.38a2.57,2.57,0,0,1-.2,1l-1.35-.78a2.65,2.65,0,0,0,1.53-2C393.48,413.16,393.49,414.33,393.49,414.38Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 392.715px 413.99px;
            "
            id="elexuhlig83we"
            class="animable"
          ></path>
          <g id="elh6acda35x49">
            <g
              style="opacity: 0.3; transform-origin: 392.715px 413.99px"
              class="animable"
            >
              <path
                d="M393.49,414.38a2.57,2.57,0,0,1-.2,1l-1.35-.78a2.65,2.65,0,0,0,1.53-2C393.48,413.16,393.49,414.33,393.49,414.38Z"
                id="elvdz0h3f5xcl"
                class="animable"
                style="transform-origin: 392.715px 413.99px"
              ></path>
            </g>
          </g>
          <path
            d="M393.48,415.51l-1.54-.89a2.62,2.62,0,0,0,1.54-2.36c0-.07,0-.14,0-.2a2.92,2.92,0,0,0-1.72-2.09h0a7.08,7.08,0,0,0-3.58-.94h-.34l-.32,0-.28,0h-.11a1.33,1.33,0,0,0-.21,0,13.74,13.74,0,0,0-1.74.36c-.27.07-.55.15-.81.24l-1.13.36-.42.15a13.7,13.7,0,0,1-2.39.68,2.62,2.62,0,0,1-1,0,2,2,0,0,1-.48-.2c-.6-.35-.7-.87.43-1.53a4,4,0,0,1,3.46-.36l2.39-2.41a10.29,10.29,0,0,0-7.22.4l-2-1.14-2.71,1.56,2,1.15a3.09,3.09,0,0,0-1.48,2.28v0c0,.77.59,1.51,1.77,2.19,2.62,1.51,5.21,1,7.58.24,2.2-.72,3.81-1.56,5.08-.83.79.46.55,1-.33,1.53a4.38,4.38,0,0,1-3.79.21l-2.55,2.53a10.1,10.1,0,0,0,1.41.41,9,9,0,0,0,2.45.18,11.23,11.23,0,0,0,3.84-.94l2,1.16,2.69-1.56Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.635px 411.415px;
            "
            id="elreyurdp5op9"
            class="animable"
          ></path>
          <g id="el94xy3j5x8ha">
            <g
              style="opacity: 0.3; transform-origin: 383.635px 411.415px"
              class="animable"
            >
              <path
                d="M393.48,415.51l-1.54-.89a2.62,2.62,0,0,0,1.54-2.36c0-.07,0-.14,0-.2a2.92,2.92,0,0,0-1.72-2.09h0a7.08,7.08,0,0,0-3.58-.94h-.34l-.32,0-.28,0h-.11a1.33,1.33,0,0,0-.21,0,13.74,13.74,0,0,0-1.74.36c-.27.07-.55.15-.81.24l-1.13.36-.42.15a13.7,13.7,0,0,1-2.39.68,2.62,2.62,0,0,1-1,0,2,2,0,0,1-.48-.2c-.6-.35-.7-.87.43-1.53a4,4,0,0,1,3.46-.36l2.39-2.41a10.29,10.29,0,0,0-7.22.4l-2-1.14-2.71,1.56,2,1.15a3.09,3.09,0,0,0-1.48,2.28v0c0,.77.59,1.51,1.77,2.19,2.62,1.51,5.21,1,7.58.24,2.2-.72,3.81-1.56,5.08-.83.79.46.55,1-.33,1.53a4.38,4.38,0,0,1-3.79.21l-2.55,2.53a10.1,10.1,0,0,0,1.41.41,9,9,0,0,0,2.45.18,11.23,11.23,0,0,0,3.84-.94l2,1.16,2.69-1.56Z"
                style="
                  fill: rgb(255, 255, 255);
                  transform-origin: 383.635px 411.415px;
                "
                id="elgqzt7f00o9"
                class="animable"
              ></path>
            </g>
          </g>
          <polygon
            points="389.25 416.17 389.25 418.3 391.24 419.44 391.24 417.32 389.25 416.17"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 390.245px 417.805px;
            "
            id="eli6675xrbqii"
            class="animable"
          ></polygon>
          <g id="elwv2tkau5md">
            <polygon
              points="389.25 416.17 389.25 418.3 391.24 419.44 391.24 417.32 389.25 416.17"
              style="opacity: 0.45; transform-origin: 390.245px 417.805px"
              class="animable"
            ></polygon>
          </g>
          <g id="elxvd73nwmzhi">
            <polygon
              points="373.28 407.2 375.99 405.64 377.98 406.78 376.01 405.96 373.28 407.2"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 375.63px 406.42px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="el9s986boslvu">
            <path
              d="M379.35,409.15a4,4,0,0,1,3.46-.36l2.39-2.41-2.39,2.16A3.65,3.65,0,0,0,379.35,409.15Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 382.275px 407.765px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elyaogf5otjz">
            <path
              d="M381.55,416.52a10.1,10.1,0,0,0,1.41.41,9,9,0,0,0,2.45.18,8.69,8.69,0,0,1-3.46-.69L384.1,414Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 383.48px 415.564px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elb9rwpo7lwt">
            <polygon
              points="389.25 416.17 391.24 417.07 393.93 415.77 391.24 417.32 389.25 416.17"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 391.59px 416.545px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="ellfyrnfn527">
            <path
              d="M365.55,402.82c7.06-4.29,18.06-4.71,18.06-4.71-6.92,0-13.4,1.54-18.26,4.35-4.67,2.69-7.23,6.19-7.23,9.88C358.12,412.34,358.37,407.18,365.55,402.82Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 370.865px 405.225px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el2697jli0ufx">
            <path
              d="M381.55,427.68c7.49.33,15.18-1.16,20.9-4.47,6-3.43,8.51-8.1,7.65-12.58,0,0,1.11,7.31-8.1,12.31S381.55,427.68,381.55,427.68Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 395.907px 419.177px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M414.69,404.93a17.15,17.15,0,0,0-6.37-5.89c-10.4-6-27.28-6-37.69,0a17.2,17.2,0,0,0-6.36,5.89h-1.44v5.44h0c.19,3.79,2.78,7.54,7.78,10.43,10.41,6,27.29,6,37.69,0,5-2.89,7.62-6.64,7.81-10.43h0v-5.44Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.47px 409.92px;
            "
            id="elof3u26tq9y"
            class="animable"
          ></path>
          <g id="elxw732840w9">
            <g
              style="opacity: 0.25; transform-origin: 389.47px 409.92px"
              class="animable"
            >
              <path
                d="M414.69,404.93a17.15,17.15,0,0,0-6.37-5.89c-10.4-6-27.28-6-37.69,0a17.2,17.2,0,0,0-6.36,5.89h-1.44v5.44h0c.19,3.79,2.78,7.54,7.78,10.43,10.41,6,27.29,6,37.69,0,5-2.89,7.62-6.64,7.81-10.43h0v-5.44Z"
                id="elg5layr4nt3e"
                class="animable"
                style="transform-origin: 389.47px 409.92px"
              ></path>
            </g>
          </g>
          <g id="elyowq99w7lkp">
            <path
              d="M389.48,394.53c-6.82,0-13.64,1.51-18.85,4.51a17.2,17.2,0,0,0-6.36,5.89h-1.44v5.44h0c.19,3.79,2.78,7.54,7.78,10.43,5.21,3,12,4.51,18.85,4.51Z"
              style="opacity: 0.1; transform-origin: 376.155px 409.92px"
              class="animable"
            ></path>
          </g>
          <g id="elgdisb3poqf6">
            <path
              d="M374.92,397a29.59,29.59,0,0,0-4.29,2,17.2,17.2,0,0,0-6.36,5.89h-1.44v5.44h0c.19,3.79,2.78,7.54,7.78,10.43a29.59,29.59,0,0,0,4.29,2Z"
              style="opacity: 0.1; transform-origin: 368.875px 409.88px"
              class="animable"
            ></path>
          </g>
          <g id="el0xk45nr5s77g">
            <path
              d="M404,397a28.63,28.63,0,0,1,4.29,2,17.15,17.15,0,0,1,6.37,5.89h1.44v5.44h0c-.19,3.79-2.8,7.54-7.81,10.43a28.63,28.63,0,0,1-4.29,2Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.5;
                transform-origin: 410.05px 409.88px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M408.32,394.05c-10.4-6-27.28-6-37.69,0s-10.4,15.75,0,21.76,27.29,6,37.69,0S418.73,400.06,408.32,394.05Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.475px 404.932px;
            "
            id="elcwk4ah3limi"
            class="animable"
          ></path>
          <g id="el0fqxhr7pofgj">
            <path
              d="M408.32,394.05c-10.4-6-27.28-6-37.69,0s-10.4,15.75,0,21.76,27.29,6,37.69,0S418.73,400.06,408.32,394.05Z"
              style="opacity: 0.2; transform-origin: 389.475px 404.932px"
              class="animable"
            ></path>
          </g>
          <path
            d="M401.75,398a30,30,0,0,0-24.54,0c-3.49,1.79-5.41,4.24-5.41,6.9s1.92,5.11,5.41,6.9a30,30,0,0,0,24.54,0c3.49-1.79,5.41-4.24,5.41-6.9S405.24,399.82,401.75,398Z"
            style="fill: rgb(159, 209, 255); transform-origin: 389.48px 404.9px"
            id="elvseskatgpjt"
            class="animable"
          ></path>
          <g id="elb2gwlan92v">
            <path
              d="M401.75,398a30,30,0,0,0-24.54,0c-3.49,1.79-5.41,4.24-5.41,6.9s1.92,5.11,5.41,6.9a30,30,0,0,0,24.54,0c3.49-1.79,5.41-4.24,5.41-6.9S405.24,399.82,401.75,398Z"
              style="opacity: 0.4; transform-origin: 389.48px 404.9px"
              class="animable"
            ></path>
          </g>
          <path
            d="M407.74,395.06c-4.86-2.81-11.34-4.36-18.26-4.36s-13.4,1.55-18.26,4.36c-4.67,2.69-7.23,6.19-7.23,9.87s2.56,7.19,7.23,9.88c4.86,2.8,11.34,4.35,18.26,4.35s13.4-1.55,18.26-4.35c4.66-2.69,7.23-6.2,7.23-9.88S412.4,397.75,407.74,395.06Zm-6,16.77a30,30,0,0,1-24.54,0c-3.49-1.79-5.41-4.24-5.41-6.9s1.92-5.11,5.41-6.9a30,30,0,0,1,24.54,0c3.49,1.79,5.41,4.24,5.41,6.9S405.24,410,401.75,411.83Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.48px 404.93px;
            "
            id="elpej90q84u1r"
            class="animable"
          ></path>
          <g id="el7f1xiru1y5f">
            <path
              d="M407.74,395.06c-4.86-2.81-11.34-4.36-18.26-4.36s-13.4,1.55-18.26,4.36c-4.67,2.69-7.23,6.19-7.23,9.87s2.56,7.19,7.23,9.88c4.86,2.8,11.34,4.35,18.26,4.35s13.4-1.55,18.26-4.35c4.66-2.69,7.23-6.2,7.23-9.88S412.4,397.75,407.74,395.06Zm-6,16.77a30,30,0,0,1-24.54,0c-3.49-1.79-5.41-4.24-5.41-6.9s1.92-5.11,5.41-6.9a30,30,0,0,1,24.54,0c3.49,1.79,5.41,4.24,5.41,6.9S405.24,410,401.75,411.83Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.3;
                transform-origin: 389.48px 404.93px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elim6j0y0w66">
            <path
              d="M401.33,411c6.55-3.36,6.55-8.8,0-12.16s-17.16-3.36-23.7,0-6.55,8.8,0,12.16S394.79,414.37,401.33,411Z"
              style="opacity: 0.2; transform-origin: 389.482px 404.922px"
              class="animable"
            ></path>
          </g>
          <path
            d="M401.33,411c6.55-3.36,6.55-8.8,0-12.16s-17.16-3.36-23.7,0-6.55,8.8,0,12.16S394.79,414.37,401.33,411Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.482px 404.922px;
            "
            id="ela7d7s95vv3"
            class="animable"
          ></path>
          <g id="el5hofxqrmq7d">
            <path
              d="M401.33,411c6.55-3.36,6.55-8.8,0-12.16s-17.16-3.36-23.7,0-6.55,8.8,0,12.16S394.79,414.37,401.33,411Z"
              style="opacity: 0.45; transform-origin: 389.482px 404.922px"
              class="animable"
            ></path>
          </g>
          <path
            d="M401.33,401.12c-6.54-3.35-17.16-3.35-23.7,0-2.71,1.39-4.29,3.14-4.76,4.95.47,1.81,2.05,3.55,4.76,4.94,6.54,3.36,17.16,3.36,23.7,0,2.71-1.39,4.29-3.13,4.76-4.94C405.62,404.26,404,402.51,401.33,401.12Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.48px 406.069px;
            "
            id="elxjn8r0zc6ul"
            class="animable"
          ></path>
          <g id="elyokqxfq71ki">
            <path
              d="M401.33,401.12c-6.54-3.35-17.16-3.35-23.7,0-2.71,1.39-4.29,3.14-4.76,4.95.47,1.81,2.05,3.55,4.76,4.94,6.54,3.36,17.16,3.36,23.7,0,2.71-1.39,4.29-3.13,4.76-4.94C405.62,404.26,404,402.51,401.33,401.12Z"
              style="opacity: 0.3; transform-origin: 389.48px 406.069px"
              class="animable"
            ></path>
          </g>
          <polygon
            points="391.07 398.98 391.07 401.44 390.23 402.32 388.68 403.95 388.68 401.39 391.07 398.98"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.875px 401.465px;
            "
            id="el63dbp6wmoyq"
            class="animable"
          ></polygon>
          <g id="elh6om2tuv5mw">
            <polygon
              points="391.07 398.98 391.07 401.44 390.23 402.32 388.68 403.95 388.68 401.39 391.07 398.98"
              style="opacity: 0.3; transform-origin: 389.875px 401.465px"
              class="animable"
            ></polygon>
          </g>
          <path
            d="M388.68,401.39v1.45a13.51,13.51,0,0,1-2.39.67,2.64,2.64,0,0,1-1,0,3,3,0,0,1-.49-.2c-.59-.36-.69-.88.44-1.53A3.9,3.9,0,0,1,388.68,401.39Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 386.524px 402.358px;
            "
            id="elq4t0s97mtri"
            class="animable"
          ></path>
          <g id="elrbxbmsvopr">
            <path
              d="M388.68,401.39v1.45a13.51,13.51,0,0,1-2.39.67,2.64,2.64,0,0,1-1,0,3,3,0,0,1-.49-.2c-.59-.36-.69-.88.44-1.53A3.9,3.9,0,0,1,388.68,401.39Z"
              style="opacity: 0.45; transform-origin: 386.524px 402.358px"
              class="animable"
            ></path>
          </g>
          <path
            d="M399.8,408.37v2.12L397.11,412l-2-1.15a10.17,10.17,0,0,1-7.69.35v-2.13l1.22-1.21-1.22.34a7.86,7.86,0,0,1-6-.69c-1.21-.69-1.77-1.45-1.76-2.24v-2.06l.21-.91-.73-.42v-2.13l2,1.15a3.06,3.06,0,0,0-1.47,2.28v0c0,.76.58,1.5,1.76,2.18,2.61,1.51,5.2,1,7.58.25,2.2-.72,3.81-1.57,5.08-.83.79.46.55,1-.33,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a8.5,8.5,0,0,0,1.4.41,10.44,10.44,0,0,0,6.29-.75l2,1.15Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.47px 405.875px;
            "
            id="elj8qfkuy8g9c"
            class="animable"
          ></path>
          <g id="elq1b7hghobs">
            <path
              d="M379.67,403.22v0c0,.76.58,1.5,1.76,2.18,2.61,1.51,5.2,1,7.58.25,2.2-.72,3.81-1.57,5.08-.83.79.46.55,1-.33,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a8.5,8.5,0,0,0,1.4.41,10.44,10.44,0,0,0,6.29-.75l2,1.15,2.69-1.55v2.12L397.11,412l-2-1.15a10.17,10.17,0,0,1-7.69.35v-2.13l1.22-1.21-1.22.34a7.86,7.86,0,0,1-6-.69c-1.21-.69-1.77-1.45-1.76-2.24v-2.06l.21-.91"
              style="opacity: 0.2; transform-origin: 389.735px 407.15px"
              class="animable"
            ></path>
          </g>
          <g id="el4bl69iw9ukd">
            <path
              d="M379.15,399.79v2.13l.73.42h0a4.45,4.45,0,0,1,1.26-1.41Z"
              style="opacity: 0.45; transform-origin: 380.145px 401.065px"
              class="animable"
            ></path>
          </g>
          <path
            d="M399.36,407a2.56,2.56,0,0,1-.2,1l-1.35-.77a2.68,2.68,0,0,0,1.53-2.05C399.35,405.76,399.36,406.92,399.36,407Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 398.585px 406.59px;
            "
            id="el0oqoaitibig"
            class="animable"
          ></path>
          <g id="elx8el0exzsc">
            <g
              style="opacity: 0.3; transform-origin: 398.585px 406.59px"
              class="animable"
            >
              <path
                d="M399.36,407a2.56,2.56,0,0,1-.2,1l-1.35-.77a2.68,2.68,0,0,0,1.53-2.05C399.35,405.76,399.36,406.92,399.36,407Z"
                id="elu998g354ngf"
                class="animable"
                style="transform-origin: 398.585px 406.59px"
              ></path>
            </g>
          </g>
          <path
            d="M399.35,408.11l-1.54-.89a2.63,2.63,0,0,0,1.54-2.36c0-.08,0-.14,0-.21a2.92,2.92,0,0,0-1.72-2.08h0a7.07,7.07,0,0,0-3.58-.93h-.34l-.32,0-.28,0H393l-.21,0a13.74,13.74,0,0,0-1.74.36c-.27.07-.55.15-.81.24l-1.13.37-.42.14a12.87,12.87,0,0,1-2.39.68,2.28,2.28,0,0,1-1,0,1.68,1.68,0,0,1-.48-.2c-.6-.34-.7-.87.43-1.52a3.93,3.93,0,0,1,3.46-.36l2.39-2.41a10.23,10.23,0,0,0-7.22.4l-2-1.15-2.71,1.57,2,1.14a3.09,3.09,0,0,0-1.48,2.29v0c0,.76.59,1.5,1.77,2.18,2.62,1.51,5.21,1,7.58.25,2.2-.72,3.81-1.57,5.08-.84.79.46.55,1-.33,1.53a4.39,4.39,0,0,1-3.79.22l-2.55,2.52a10.18,10.18,0,0,0,1.41.42,9.81,9.81,0,0,0,2.45.18,11.46,11.46,0,0,0,3.84-.94l2,1.15,2.69-1.55Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.505px 404.025px;
            "
            id="elc8u5gwg7vwc"
            class="animable"
          ></path>
          <g id="elu09pbs9hig">
            <g
              style="opacity: 0.3; transform-origin: 389.505px 404.025px"
              class="animable"
            >
              <path
                d="M399.35,408.11l-1.54-.89a2.63,2.63,0,0,0,1.54-2.36c0-.08,0-.14,0-.21a2.92,2.92,0,0,0-1.72-2.08h0a7.07,7.07,0,0,0-3.58-.93h-.34l-.32,0-.28,0H393l-.21,0a13.74,13.74,0,0,0-1.74.36c-.27.07-.55.15-.81.24l-1.13.37-.42.14a12.87,12.87,0,0,1-2.39.68,2.28,2.28,0,0,1-1,0,1.68,1.68,0,0,1-.48-.2c-.6-.34-.7-.87.43-1.52a3.93,3.93,0,0,1,3.46-.36l2.39-2.41a10.23,10.23,0,0,0-7.22.4l-2-1.15-2.71,1.57,2,1.14a3.09,3.09,0,0,0-1.48,2.29v0c0,.76.59,1.5,1.77,2.18,2.62,1.51,5.21,1,7.58.25,2.2-.72,3.81-1.57,5.08-.84.79.46.55,1-.33,1.53a4.39,4.39,0,0,1-3.79.22l-2.55,2.52a10.18,10.18,0,0,0,1.41.42,9.81,9.81,0,0,0,2.45.18,11.46,11.46,0,0,0,3.84-.94l2,1.15,2.69-1.55Z"
                style="
                  fill: rgb(255, 255, 255);
                  transform-origin: 389.505px 404.025px;
                "
                id="elqejmvu3qd2"
                class="animable"
              ></path>
            </g>
          </g>
          <polygon
            points="395.12 408.77 395.12 410.89 397.11 412.04 397.11 409.92 395.12 408.77"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 396.115px 410.405px;
            "
            id="elnhlw7gbs8u"
            class="animable"
          ></polygon>
          <g id="elsio7hekpbn8">
            <polygon
              points="395.12 408.77 395.12 410.89 397.11 412.04 397.11 409.92 395.12 408.77"
              style="opacity: 0.45; transform-origin: 396.115px 410.405px"
              class="animable"
            ></polygon>
          </g>
          <g id="el9n6vbwymgrj">
            <polygon
              points="379.15 399.8 381.86 398.23 383.85 399.38 381.88 398.55 379.15 399.8"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 381.5px 399.015px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="elmmsqnetugu">
            <path
              d="M385.22,401.75a3.93,3.93,0,0,1,3.46-.36l2.39-2.41-2.39,2.16A3.62,3.62,0,0,0,385.22,401.75Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 388.145px 400.365px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el57rtf99pg55">
            <path
              d="M387.42,409.11a10.18,10.18,0,0,0,1.41.42,9.81,9.81,0,0,0,2.45.18,8.69,8.69,0,0,1-3.46-.69l2.15-2.43Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 389.35px 408.157px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elb8296rerfve">
            <polygon
              points="395.12 408.77 397.11 409.67 399.8 408.37 397.11 409.92 395.12 408.77"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 397.46px 409.145px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="el0f2ai0jfi3zi">
            <path
              d="M371.42,395.41c7.06-4.28,18.06-4.71,18.06-4.71-6.92,0-13.4,1.55-18.26,4.36-4.67,2.69-7.23,6.19-7.23,9.87C364,404.93,364.24,399.77,371.42,395.41Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 376.735px 397.815px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elxig88bfi65o">
            <path
              d="M387.42,420.27c7.49.33,15.18-1.15,20.9-4.46,6-3.44,8.51-8.1,7.65-12.58,0,0,1.11,7.3-8.1,12.31S387.42,420.27,387.42,420.27Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 401.777px 411.773px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M409.06,398.26a17.2,17.2,0,0,0-6.36-5.89c-10.41-6-27.28-6-37.69,0a17.31,17.31,0,0,0-6.37,5.89H357.2v5.44h0c.19,3.79,2.78,7.54,7.79,10.43,10.41,6,27.28,6,37.69,0,5-2.89,7.61-6.64,7.8-10.43h0v-5.44Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.84px 403.25px;
            "
            id="elp0gnt4xd2u9"
            class="animable"
          ></path>
          <g id="elas2irxn811">
            <g
              style="opacity: 0.25; transform-origin: 383.84px 403.25px"
              class="animable"
            >
              <path
                d="M409.06,398.26a17.2,17.2,0,0,0-6.36-5.89c-10.41-6-27.28-6-37.69,0a17.31,17.31,0,0,0-6.37,5.89H357.2v5.44h0c.19,3.79,2.78,7.54,7.79,10.43,10.41,6,27.28,6,37.69,0,5-2.89,7.61-6.64,7.8-10.43h0v-5.44Z"
                id="elgl17t34v1r"
                class="animable"
                style="transform-origin: 383.84px 403.25px"
              ></path>
            </g>
          </g>
          <g id="elpy0hrwt60wf">
            <path
              d="M383.85,387.86c-6.82,0-13.64,1.51-18.84,4.51a17.31,17.31,0,0,0-6.37,5.89H357.2v5.44h0c.19,3.79,2.78,7.54,7.79,10.43,5.2,3,12,4.51,18.84,4.51Z"
              style="opacity: 0.1; transform-origin: 370.525px 403.25px"
              class="animable"
            ></path>
          </g>
          <g id="elpwwh53hb4hq">
            <path
              d="M369.3,390.36a28.63,28.63,0,0,0-4.29,2,17.31,17.31,0,0,0-6.37,5.89H357.2v5.44h0c.19,3.79,2.78,7.54,7.79,10.43a28.63,28.63,0,0,0,4.29,2Z"
              style="opacity: 0.1; transform-origin: 363.25px 403.24px"
              class="animable"
            ></path>
          </g>
          <g id="el4rmaoqt2rhc">
            <path
              d="M398.41,390.36a28.63,28.63,0,0,1,4.29,2,17.2,17.2,0,0,1,6.36,5.89h1.44v5.44h0c-.19,3.79-2.8,7.54-7.8,10.43a28.63,28.63,0,0,1-4.29,2Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.5;
                transform-origin: 404.455px 403.24px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M402.7,387.38c-10.41-6-27.28-6-37.69,0s-10.41,15.75,0,21.76,27.28,6,37.69,0S413.11,393.39,402.7,387.38Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.855px 398.262px;
            "
            id="elj23ybvdotw"
            class="animable"
          ></path>
          <g id="el1lcfcwh9x5f">
            <path
              d="M402.7,387.38c-10.41-6-27.28-6-37.69,0s-10.41,15.75,0,21.76,27.28,6,37.69,0S413.11,393.39,402.7,387.38Z"
              style="opacity: 0.2; transform-origin: 383.855px 398.262px"
              class="animable"
            ></path>
          </g>
          <path
            d="M396.13,391.36a30.06,30.06,0,0,0-24.55,0c-3.49,1.79-5.41,4.24-5.41,6.9s1.92,5.11,5.41,6.9a30.06,30.06,0,0,0,24.55,0c3.48-1.79,5.4-4.24,5.4-6.9S399.61,393.15,396.13,391.36Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.85px 398.26px;
            "
            id="elu7pv7wdhcyf"
            class="animable"
          ></path>
          <g id="el5dd8uj3goa9">
            <path
              d="M396.13,391.36a30.06,30.06,0,0,0-24.55,0c-3.49,1.79-5.41,4.24-5.41,6.9s1.92,5.11,5.41,6.9a30.06,30.06,0,0,0,24.55,0c3.48-1.79,5.4-4.24,5.4-6.9S399.61,393.15,396.13,391.36Z"
              style="opacity: 0.4; transform-origin: 383.85px 398.26px"
              class="animable"
            ></path>
          </g>
          <path
            d="M402.12,388.39c-4.86-2.81-11.35-4.36-18.27-4.36s-13.4,1.55-18.26,4.36c-4.66,2.69-7.22,6.19-7.22,9.87s2.56,7.19,7.22,9.88c4.86,2.8,11.34,4.35,18.26,4.35s13.41-1.55,18.27-4.35c4.66-2.69,7.22-6.2,7.22-9.88S406.78,391.08,402.12,388.39Zm-6,16.77a30.06,30.06,0,0,1-24.55,0c-3.49-1.79-5.41-4.24-5.41-6.9s1.92-5.11,5.41-6.9a30.06,30.06,0,0,1,24.55,0c3.48,1.79,5.4,4.24,5.4,6.9S399.61,403.37,396.13,405.16Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.855px 398.26px;
            "
            id="elcx2cj6icxid"
            class="animable"
          ></path>
          <g id="elvlxn2nsdlu">
            <path
              d="M402.12,388.39c-4.86-2.81-11.35-4.36-18.27-4.36s-13.4,1.55-18.26,4.36c-4.66,2.69-7.22,6.19-7.22,9.87s2.56,7.19,7.22,9.88c4.86,2.8,11.34,4.35,18.26,4.35s13.41-1.55,18.27-4.35c4.66-2.69,7.22-6.2,7.22-9.88S406.78,391.08,402.12,388.39Zm-6,16.77a30.06,30.06,0,0,1-24.55,0c-3.49-1.79-5.41-4.24-5.41-6.9s1.92-5.11,5.41-6.9a30.06,30.06,0,0,1,24.55,0c3.48,1.79,5.4,4.24,5.4,6.9S399.61,403.37,396.13,405.16Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.3;
                transform-origin: 383.855px 398.26px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elp8nrf5at15">
            <path
              d="M395.71,404.34c6.54-3.36,6.54-8.8,0-12.16s-17.16-3.36-23.71,0-6.54,8.8,0,12.16S389.16,407.7,395.71,404.34Z"
              style="opacity: 0.2; transform-origin: 383.853px 398.26px"
              class="animable"
            ></path>
          </g>
          <path
            d="M395.71,404.34c6.54-3.36,6.54-8.8,0-12.16s-17.16-3.36-23.71,0-6.54,8.8,0,12.16S389.16,407.7,395.71,404.34Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.853px 398.26px;
            "
            id="elkqvh2shltl"
            class="animable"
          ></path>
          <g id="elgaa3nbsr61g">
            <path
              d="M395.71,404.34c6.54-3.36,6.54-8.8,0-12.16s-17.16-3.36-23.71,0-6.54,8.8,0,12.16S389.16,407.7,395.71,404.34Z"
              style="opacity: 0.45; transform-origin: 383.853px 398.26px"
              class="animable"
            ></path>
          </g>
          <path
            d="M395.71,394.46c-6.55-3.36-17.16-3.36-23.71,0-2.71,1.38-4.29,3.13-4.76,4.94.47,1.81,2.05,3.55,4.76,4.94,6.55,3.36,17.16,3.36,23.71,0,2.7-1.39,4.29-3.13,4.75-4.94C400,397.59,398.41,395.84,395.71,394.46Z"
            style="fill: rgb(159, 209, 255); transform-origin: 383.85px 399.4px"
            id="el34s11kvv51i"
            class="animable"
          ></path>
          <g id="elk8v3l13mdk">
            <path
              d="M395.71,394.46c-6.55-3.36-17.16-3.36-23.71,0-2.71,1.38-4.29,3.13-4.76,4.94.47,1.81,2.05,3.55,4.76,4.94,6.55,3.36,17.16,3.36,23.71,0,2.7-1.39,4.29-3.13,4.75-4.94C400,397.59,398.41,395.84,395.71,394.46Z"
              style="opacity: 0.3; transform-origin: 383.85px 399.4px"
              class="animable"
            ></path>
          </g>
          <polygon
            points="385.44 392.31 385.44 394.76 384.6 395.65 383.05 397.28 383.05 394.72 385.44 392.31"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 384.245px 394.795px;
            "
            id="elx0yx8oz8l9o"
            class="animable"
          ></polygon>
          <g id="el51aggavyml2">
            <polygon
              points="385.44 392.31 385.44 394.76 384.6 395.65 383.05 397.28 383.05 394.72 385.44 392.31"
              style="opacity: 0.3; transform-origin: 384.245px 394.795px"
              class="animable"
            ></polygon>
          </g>
          <path
            d="M383.05,394.72v1.45a13.28,13.28,0,0,1-2.39.67,2.65,2.65,0,0,1-1,0,2.86,2.86,0,0,1-.48-.2c-.6-.36-.69-.88.43-1.53A3.94,3.94,0,0,1,383.05,394.72Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 380.897px 395.69px;
            "
            id="elpxs37zcs1b"
            class="animable"
          ></path>
          <g id="eltp58nnmanzl">
            <path
              d="M383.05,394.72v1.45a13.28,13.28,0,0,1-2.39.67,2.65,2.65,0,0,1-1,0,2.86,2.86,0,0,1-.48-.2c-.6-.36-.69-.88.43-1.53A3.94,3.94,0,0,1,383.05,394.72Z"
              style="opacity: 0.45; transform-origin: 380.897px 395.69px"
              class="animable"
            ></path>
          </g>
          <path
            d="M394.18,401.7v2.12l-2.69,1.55-2-1.15a10.15,10.15,0,0,1-7.7.35v-2.13l1.22-1.21-1.22.34a7.86,7.86,0,0,1-6-.69c-1.2-.69-1.76-1.45-1.76-2.24v-2.06l.22-.91-.73-.42v-2.13l2,1.15a3.09,3.09,0,0,0-1.47,2.28v0c0,.76.59,1.5,1.76,2.18,2.62,1.51,5.21,1,7.58.25,2.21-.72,3.81-1.57,5.09-.83.79.46.55,1-.34,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a8.5,8.5,0,0,0,1.4.41,10.47,10.47,0,0,0,6.3-.75l2,1.15Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.85px 399.245px;
            "
            id="elvjl7mqmwbb7"
            class="animable"
          ></path>
          <g id="elzkah772v9ms">
            <path
              d="M374,396.55v0c0,.76.59,1.5,1.76,2.18,2.62,1.51,5.21,1,7.58.25,2.21-.72,3.81-1.57,5.09-.83.79.46.55,1-.34,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a8.5,8.5,0,0,0,1.4.41,10.47,10.47,0,0,0,6.3-.75l2,1.15,2.69-1.55v2.12l-2.69,1.55-2-1.15a10.15,10.15,0,0,1-7.7.35v-2.13l1.22-1.21-1.22.34a7.86,7.86,0,0,1-6-.69c-1.2-.69-1.76-1.45-1.76-2.24v-2.06l.22-.91"
              style="opacity: 0.2; transform-origin: 384.075px 400.49px"
              class="animable"
            ></path>
          </g>
          <g id="eldbp2myp53b">
            <path
              d="M373.53,393.12v2.13l.73.42h0a4.41,4.41,0,0,1,1.25-1.41Z"
              style="opacity: 0.45; transform-origin: 374.52px 394.395px"
              class="animable"
            ></path>
          </g>
          <path
            d="M393.73,400.31a2.56,2.56,0,0,1-.2,1l-1.35-.77a2.66,2.66,0,0,0,1.53-2C393.72,399.09,393.73,400.25,393.73,400.31Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 392.955px 399.925px;
            "
            id="elhz2otjv04pf"
            class="animable"
          ></path>
          <g id="elc9prczcdca6">
            <g
              style="opacity: 0.3; transform-origin: 392.955px 399.925px"
              class="animable"
            >
              <path
                d="M393.73,400.31a2.56,2.56,0,0,1-.2,1l-1.35-.77a2.66,2.66,0,0,0,1.53-2C393.72,399.09,393.73,400.25,393.73,400.31Z"
                id="elli9tbb15ql"
                class="animable"
                style="transform-origin: 392.955px 399.925px"
              ></path>
            </g>
          </g>
          <path
            d="M393.73,401.44l-1.54-.89a2.63,2.63,0,0,0,1.54-2.36,1.49,1.49,0,0,0,0-.21c-.06-.73-.6-1.44-1.72-2.08h0a7,7,0,0,0-3.57-.93h-.34l-.32,0-.28,0-.11,0-.22,0a13.74,13.74,0,0,0-1.74.36l-.81.24-1.12.37-.43.14a12.6,12.6,0,0,1-2.38.68,2.34,2.34,0,0,1-1,0,1.68,1.68,0,0,1-.48-.2c-.6-.34-.69-.87.43-1.52a3.94,3.94,0,0,1,3.46-.36l2.39-2.41a10.2,10.2,0,0,0-7.21.4l-2-1.15-2.71,1.57,2,1.14a3.08,3.08,0,0,0-1.48,2.29v0c0,.76.58,1.5,1.76,2.18,2.62,1.51,5.21,1,7.58.25,2.21-.72,3.81-1.57,5.08-.83.8.45.56,1-.33,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a9.67,9.67,0,0,0,1.4.42,9.86,9.86,0,0,0,2.46.18,11.58,11.58,0,0,0,3.84-.94l2,1.15,2.68-1.55Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.9px 397.355px;
            "
            id="el9pccjwmxtbo"
            class="animable"
          ></path>
          <g id="el78008hzvxsb">
            <g
              style="opacity: 0.3; transform-origin: 383.9px 397.355px"
              class="animable"
            >
              <path
                d="M393.73,401.44l-1.54-.89a2.63,2.63,0,0,0,1.54-2.36,1.49,1.49,0,0,0,0-.21c-.06-.73-.6-1.44-1.72-2.08h0a7,7,0,0,0-3.57-.93h-.34l-.32,0-.28,0-.11,0-.22,0a13.74,13.74,0,0,0-1.74.36l-.81.24-1.12.37-.43.14a12.6,12.6,0,0,1-2.38.68,2.34,2.34,0,0,1-1,0,1.68,1.68,0,0,1-.48-.2c-.6-.34-.69-.87.43-1.52a3.94,3.94,0,0,1,3.46-.36l2.39-2.41a10.2,10.2,0,0,0-7.21.4l-2-1.15-2.71,1.57,2,1.14a3.08,3.08,0,0,0-1.48,2.29v0c0,.76.58,1.5,1.76,2.18,2.62,1.51,5.21,1,7.58.25,2.21-.72,3.81-1.57,5.08-.83.8.45.56,1-.33,1.52a4.39,4.39,0,0,1-3.79.22l-2.54,2.52a9.67,9.67,0,0,0,1.4.42,9.86,9.86,0,0,0,2.46.18,11.58,11.58,0,0,0,3.84-.94l2,1.15,2.68-1.55Z"
                style="
                  fill: rgb(255, 255, 255);
                  transform-origin: 383.9px 397.355px;
                "
                id="el70yiev1l4bj"
                class="animable"
              ></path>
            </g>
          </g>
          <polygon
            points="389.5 402.1 389.5 404.22 391.49 405.37 391.49 403.25 389.5 402.1"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 390.495px 403.735px;
            "
            id="elfs6fm1s1zg"
            class="animable"
          ></polygon>
          <g id="elfpz7cb3465">
            <polygon
              points="389.5 402.1 389.5 404.22 391.49 405.37 391.49 403.25 389.5 402.1"
              style="opacity: 0.45; transform-origin: 390.495px 403.735px"
              class="animable"
            ></polygon>
          </g>
          <g id="elia45b4nnml">
            <polygon
              points="373.53 393.13 376.24 391.56 378.23 392.71 376.25 391.88 373.53 393.13"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 375.88px 392.345px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="elp1n612u8if">
            <path
              d="M379.59,395.08a3.94,3.94,0,0,1,3.46-.36l2.39-2.41-2.39,2.16A3.62,3.62,0,0,0,379.59,395.08Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 382.515px 393.695px;
              "
              class="animable"
            ></path>
          </g>
          <g id="ell1n5ec6n9t9">
            <path
              d="M381.8,402.44a9.67,9.67,0,0,0,1.4.42,9.86,9.86,0,0,0,2.46.18,8.67,8.67,0,0,1-3.46-.69l2.14-2.43Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 383.73px 401.487px;
              "
              class="animable"
            ></path>
          </g>
          <g id="elhk41ujd6znr">
            <polygon
              points="389.5 402.1 391.49 403 394.17 401.7 391.49 403.25 389.5 402.1"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 391.835px 402.475px;
              "
              class="animable"
            ></polygon>
          </g>
          <g id="elvg1kdseyiok">
            <path
              d="M365.79,388.74c7.06-4.28,18.06-4.71,18.06-4.71-6.92,0-13.4,1.55-18.26,4.36-4.66,2.69-7.22,6.19-7.22,9.87C358.37,398.26,358.62,393.1,365.79,388.74Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 371.11px 391.145px;
              "
              class="animable"
            ></path>
          </g>
          <g id="el9qv8nih1xms">
            <path
              d="M381.8,413.6c7.49.33,15.17-1.15,20.9-4.46,5.95-3.44,8.5-8.1,7.64-12.58,0,0,1.11,7.3-8.09,12.31S381.8,413.6,381.8,413.6Z"
              style="
                fill: rgb(245, 245, 245);
                opacity: 0.5;
                transform-origin: 396.151px 405.103px;
              "
              class="animable"
            ></path>
          </g>
        </g>
      </g>
      <g
        id="freepik--speech-bubble--inject-42"
        class="animable"
        style="transform-origin: 329.811px 83.3025px"
      >
        <g
          id="freepik--speech-bubble--inject-42"
          class="animable"
          style="transform-origin: 329.811px 83.3025px"
        >
          <g
            id="freepik--speech-bubble--inject-42"
            class="animable"
            style="transform-origin: 329.811px 83.3025px"
          >
            <path
              d="M349.86,79.86a20,20,0,0,1-5.39,13.69l-1.65,1.58A20,20,0,0,1,314.53,67l1.58-1.66a20,20,0,0,1,33.75,14.54Z"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 329.778px 79.9511px;
              "
              id="eldg14h5cbvfk"
              class="animable"
            ></path>
            <path
              d="M329.77,58.17a21.73,21.73,0,0,0-3.52,43.17l2.46,6.29a1.26,1.26,0,0,0,2.35,0l2.43-6.3a21.73,21.73,0,0,0-3.72-43.15Zm20.09,21.69a20,20,0,0,1-5.39,13.69l-1.65,1.58A20,20,0,0,1,314.53,67l1.58-1.66a20,20,0,0,1,33.75,14.54Z"
              style="
                fill: rgb(224, 224, 224);
                transform-origin: 329.811px 83.3025px;
              "
              id="elgp6bftvomxp"
              class="animable"
            ></path>
            <g id="ela5rl4kem6l">
              <path
                d="M349.86,79.86a20,20,0,0,1-5.39,13.69l-1.65,1.58A20,20,0,0,1,314.53,67l1.58-1.66a20,20,0,0,1,33.75,14.54Z"
                style="
                  fill: rgb(255, 255, 255);
                  opacity: 0.7;
                  transform-origin: 329.778px 79.9511px;
                "
                class="animable"
              ></path>
            </g>
          </g>
          <path
            d="M321.67,67.94a13.94,13.94,0,0,1,1.56-1.2,11.23,11.23,0,0,1,4-1.64,12.26,12.26,0,0,1,2.5-.24,10.28,10.28,0,0,1,3.32.5,7.24,7.24,0,0,1,2.57,1.44,6.38,6.38,0,0,1,1.65,2.25,7,7,0,0,1,.59,3,7.38,7.38,0,0,1-.46,2.76,7.72,7.72,0,0,1-1.15,2,8.55,8.55,0,0,1-1.52,1.49l-1.56,1.19a11.16,11.16,0,0,0-1.27,1.09,2.12,2.12,0,0,0-.64,1.2l-.48,3.06H327.2l-.35-3.41a2.74,2.74,0,0,1,.28-1.73,5.39,5.39,0,0,1,1.09-1.37,13.59,13.59,0,0,1,1.52-1.19,13.75,13.75,0,0,0,1.57-1.26,6.46,6.46,0,0,0,1.23-1.53,4,4,0,0,0,.49-2,3,3,0,0,0-.28-1.35,3,3,0,0,0-.79-1,3.41,3.41,0,0,0-1.2-.66,4.7,4.7,0,0,0-1.52-.23,7,7,0,0,0-2,.26,6.85,6.85,0,0,0-1.4.59c-.39.21-.71.41-1,.58a1.29,1.29,0,0,1-.71.27A1.14,1.14,0,0,1,323,70.1Zm4,22.4a3.26,3.26,0,0,1,.24-1.24,3,3,0,0,1,.66-1,3.17,3.17,0,0,1,2.27-.93,3.19,3.19,0,0,1,1.24.25,3.39,3.39,0,0,1,1,.68,3.06,3.06,0,0,1,.68,1,3.23,3.23,0,0,1,0,2.49,3.12,3.12,0,0,1-.68,1,3.21,3.21,0,0,1-2.25.9,3.29,3.29,0,0,1-1.26-.24,3,3,0,0,1-1.67-1.65A3.24,3.24,0,0,1,325.63,90.34Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 329.766px 79.1745px;
            "
            id="elgglnojduafn"
            class="animable"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Equipment--inject-42"
        class="animable"
        style="transform-origin: 411.595px 332.635px"
      >
        <g
          id="freepik--equipment--inject-42"
          class="animable"
          style="transform-origin: 411.595px 332.635px"
        >
          <path
            d="M362.42,295.64a2.07,2.07,0,1,0,2.06-2.08A2.07,2.07,0,0,0,362.42,295.64Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 364.49px 295.63px;
            "
            id="el8509i10yxch"
            class="animable"
          ></path>
          <polygon
            points="401.48 317.9 402.14 317.32 383.13 295.2 364.49 295.2 364.49 296.08 382.73 296.08 401.48 317.9"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 383.315px 306.55px;
            "
            id="el0iqv9i8gbokc"
            class="animable"
          ></polygon>
          <ellipse
            cx="427.48"
            cy="338.18"
            rx="32.8"
            ry="33.04"
            style="
              fill: rgb(255, 255, 255);
              transform-origin: 427.48px 338.18px;
            "
            id="el2bkpebbjtql"
            class="animable"
          ></ellipse>
          <path
            d="M394.19,338.18a33.29,33.29,0,1,1,33.29,33.53A33.45,33.45,0,0,1,394.19,338.18Zm1,0a32.32,32.32,0,1,0,32.32-32.56A32.47,32.47,0,0,0,395.16,338.18Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 427.48px 338.42px;
            "
            id="el26jqu7bm381"
            class="animable"
          ></path>
          <path
            d="M415.13,317.51l-5.5,3.6a.43.43,0,0,0-.13.61.44.44,0,0,0,.6.13l4.85-3.18A2.37,2.37,0,0,1,415.13,317.51Z"
            style="
              fill: rgb(69, 90, 100);
              transform-origin: 412.277px 319.713px;
            "
            id="elok4c2z7crs"
            class="animable"
          ></path>
          <path
            d="M446.56,321l-28.78-4.44a2,2,0,0,1,.92,1l27.72,4.28h.07a.44.44,0,0,0,.43-.38A.43.43,0,0,0,446.56,321Z"
            style="fill: rgb(69, 90, 100); transform-origin: 432.351px 319.2px"
            id="el9ieo30gfhoo"
            class="animable"
          ></path>
          <path
            d="M446.49,335.6a.44.44,0,0,1-.44-.44V323.75a.44.44,0,0,1,.88,0v11.41A.44.44,0,0,1,446.49,335.6Z"
            style="fill: rgb(69, 90, 100); transform-origin: 446.49px 329.455px"
            id="el4n0q3kb145t"
            class="animable"
          ></path>
          <g id="els9zejqnv5r">
            <g
              style="opacity: 0.4; transform-origin: 446.495px 339.14px"
              class="animable"
            >
              <rect
                x="442.89"
                y="337.41"
                width="7.21"
                height="3.46"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 446.495px 339.14px;
                "
                id="eltq7mlrvxs9"
                class="animable"
              ></rect>
            </g>
          </g>
          <g id="el3eavrvus4vw">
            <g
              style="opacity: 0.4; transform-origin: 416.86px 357.355px"
              class="animable"
            >
              <path
                d="M413.28,355.52h7.21a2,2,0,0,1,2,2v1.67a0,0,0,0,1,0,0H411.23a0,0,0,0,1,0,0v-1.67A2,2,0,0,1,413.28,355.52Z"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 416.86px 357.355px;
                "
                id="el7vez2onaa4r"
                class="animable"
              ></path>
            </g>
          </g>
          <path
            d="M419.32,354.53h-4.87V318.32a2.44,2.44,0,1,1,4.87,0Zm-3.87-1h2.87V318.32a1.44,1.44,0,1,0-2.87,0Z"
            style="
              fill: rgb(69, 90, 100);
              transform-origin: 416.885px 335.127px;
            "
            id="elx5e462gtus"
            class="animable"
          ></path>
          <g id="el28xjnyuzb6g">
            <g
              style="opacity: 0.4; transform-origin: 410.81px 323.745px"
              class="animable"
            >
              <path
                d="M407.89,325.11h5.84v-2.73h-3.11a2.73,2.73,0,0,0-2.73,2.73Z"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 410.81px 323.745px;
                "
                id="elwibc32dgvqn"
                class="animable"
              ></path>
            </g>
          </g>
          <g id="el2xjshcn6fq7">
            <g
              style="opacity: 0.4; transform-origin: 434.33px 323.745px"
              class="animable"
            >
              <path
                d="M448.66,325.11H420v-2.73h25.89a2.73,2.73,0,0,1,2.73,2.73Z"
                style="
                  fill: rgb(159, 209, 255);
                  transform-origin: 434.33px 323.745px;
                "
                id="el0wzd7b7iccp"
                class="animable"
              ></path>
            </g>
          </g>
        </g>
      </g>
      <g
        id="freepik--Overhead--inject-42"
        class="animable"
        style="transform-origin: 430.055px 207.54px"
      >
        <g
          id="freepik--overhead--inject-42"
          class="animable"
          style="transform-origin: 430.055px 207.54px"
        >
          <path
            d="M380.1,216.35a2.07,2.07,0,1,0,2.07-2.08A2.08,2.08,0,0,0,380.1,216.35Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 382.17px 216.34px;
            "
            id="el4srbrj2yb49"
            class="animable"
          ></path>
          <polygon
            points="382.17 216.79 394.37 216.79 405.74 207.74 413.92 207.74 413.92 206.85 405.44 206.85 394.06 215.91 382.17 215.91 382.17 216.79"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 398.045px 211.82px;
            "
            id="elzq0w6u0zpb"
            class="animable"
          ></polygon>
          <ellipse
            cx="446.72"
            cy="207.29"
            rx="32.8"
            ry="33.04"
            style="
              fill: rgb(255, 255, 255);
              transform-origin: 446.72px 207.29px;
            "
            id="elqt9yndmjs6"
            class="animable"
          ></ellipse>
          <path
            d="M413.43,207.29a33.29,33.29,0,1,1,33.29,33.54A33.45,33.45,0,0,1,413.43,207.29Zm1,0a32.32,32.32,0,1,0,32.31-32.55A32.48,32.48,0,0,0,414.41,207.29Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 446.719px 207.54px;
            "
            id="eldozw95jcw1s"
            class="animable"
          ></path>
          <g id="el477cdcjhbao">
            <path
              d="M446,225.64l-3,8.78a25,25,0,0,0,7.33,0l-3-8.78,2.14-4-.14-2.26H444l-.14,2.26Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 446.665px 227.035px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M446.72,235.19a26.37,26.37,0,0,1-22.47-12.51l-.19-.31.24-.28a9.55,9.55,0,0,1,7.18-3.25H462a9.53,9.53,0,0,1,7.17,3.25l.25.28-.2.31A26.39,26.39,0,0,1,446.72,235.19Zm-21.41-12.72a25.42,25.42,0,0,0,42.83,0,8.55,8.55,0,0,0-6.17-2.63H431.48A8.53,8.53,0,0,0,425.31,222.47Z"
            style="fill: rgb(69, 90, 100); transform-origin: 446.74px 227.015px"
            id="ell6d1y785008"
            class="animable"
          ></path>
          <path
            d="M459.72,208.19a.41.41,0,0,0,0-.46l-1.82-2.3a1.82,1.82,0,0,1-.23-1.87l0,0h0a1.85,1.85,0,0,1,1.48-1.15l2.92-.33a.39.39,0,0,0,.34-.31,16.49,16.49,0,0,0,0-5.43.38.38,0,0,0-.34-.32l-2.91-.33a1.88,1.88,0,0,1-1.47-1.14l0-.07a1.81,1.81,0,0,1,.23-1.85l1.83-2.3a.38.38,0,0,0,0-.45,15.23,15.23,0,0,0-3.83-3.85.39.39,0,0,0-.45,0l-2.3,1.82a1.9,1.9,0,0,1-1.88.23l0,0a1.86,1.86,0,0,1-1.15-1.49l-.33-2.92a.36.36,0,0,0-.31-.33,15.72,15.72,0,0,0-5.43,0,.38.38,0,0,0-.31.33l-.35,2.92a1.84,1.84,0,0,1-1.15,1.48l0,0a1.92,1.92,0,0,1-1.87-.24L438,186a.38.38,0,0,0-.46,0,15.36,15.36,0,0,0-3.84,3.83.39.39,0,0,0,0,.46l1.82,2.29a1.86,1.86,0,0,1,.22,1.87l0,0a1.83,1.83,0,0,1-1.47,1.14l-2.94.33a.37.37,0,0,0-.32.31,16.08,16.08,0,0,0,0,5.42.37.37,0,0,0,.34.31l2.92.35a1.89,1.89,0,0,1,1.48,1.16v0a1.82,1.82,0,0,1-.23,1.86l-1.83,2.3a.41.41,0,0,0,0,.46,15.57,15.57,0,0,0,3.84,3.84.38.38,0,0,0,.45,0l2.3-1.82a1.86,1.86,0,0,1,1.87-.22h0a1.86,1.86,0,0,1,1.15,1.49l.33,2.91a.37.37,0,0,0,.31.34,14.85,14.85,0,0,0,2.71.24,16.1,16.1,0,0,0,2.72-.23.39.39,0,0,0,.31-.33l.35-2.92a1.85,1.85,0,0,1,1.14-1.48h0a1.88,1.88,0,0,1,1.88.23l2.29,1.83a.38.38,0,0,0,.45,0A15.74,15.74,0,0,0,459.72,208.19Zm-13-1.16a8.06,8.06,0,1,1,8-8A8.06,8.06,0,0,1,446.72,207Z"
            style="
              fill: rgb(69, 90, 100);
              transform-origin: 446.708px 198.987px;
            "
            id="el51ql3078sah"
            class="animable"
          ></path>
          <path
            d="M431.48,219.34a9,9,0,0,0-6.8,3.08,26,26,0,0,0,22,12.27l-7.84-15.35Z"
            style="fill: rgb(69, 90, 100); transform-origin: 435.68px 227.015px"
            id="el6txfedrhuln"
            class="animable"
          ></path>
          <path
            d="M462,219.34a9,9,0,0,1,6.8,3.08,26,26,0,0,1-22,12.27l7.83-15.35Z"
            style="fill: rgb(69, 90, 100); transform-origin: 457.8px 227.015px"
            id="eli52fbkpscd"
            class="animable"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Material--inject-42"
        class="animable"
        style="transform-origin: 405.295px 100.52px"
      >
        <g
          id="freepik--material--inject-42"
          class="animable"
          style="transform-origin: 405.295px 100.52px"
        >
          <polygon
            points="368.69 163.16 368.16 162.46 410.5 130.09 410.5 102.26 411.38 102.26 411.38 130.52 411.21 130.65 368.69 163.16"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 389.77px 132.71px;
            "
            id="elufxju5l44un"
            class="animable"
          ></polygon>
          <path
            d="M366.36,162.81a2.07,2.07,0,1,0,2.06-2.08A2.07,2.07,0,0,0,366.36,162.81Z"
            style="fill: rgb(159, 209, 255); transform-origin: 368.43px 162.8px"
            id="el4qgm29byd4f"
            class="animable"
          ></path>
          <ellipse
            cx="410.94"
            cy="69.22"
            rx="32.8"
            ry="33.04"
            style="fill: rgb(255, 255, 255); transform-origin: 410.94px 69.22px"
            id="el2on28uiagtk"
            class="animable"
          ></ellipse>
          <path
            d="M377.65,69.22a33.29,33.29,0,1,1,33.29,33.53A33.46,33.46,0,0,1,377.65,69.22Zm1,0a32.31,32.31,0,1,0,32.31-32.56A32.47,32.47,0,0,0,378.63,69.22Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 410.939px 69.46px;
            "
            id="el2hefwoacds4"
            class="animable"
          ></path>
          <g id="elomins8z48k">
            <path
              d="M396.18,57.13a6,6,0,0,0,1.11.61,6.46,6.46,0,0,0,5.22-.17c1.37-.67,2.54-1.85,4.06-2a3.43,3.43,0,0,1,3.34,1.61,8.53,8.53,0,0,1,.77,3.52,4.25,4.25,0,0,0,1.48,3.2c1.28.86,3,.3,4.28-.64a6.23,6.23,0,0,1,1.39-1,2.34,2.34,0,0,1,2.76.9c.94,1.51.06,4,1.51,5a2.43,2.43,0,0,0,1.94.27,4.06,4.06,0,0,0,1-.36V52.41H396.18Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 410.61px 60.4662px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M420,86.53H401.18a5.51,5.51,0,0,1-5.5-5.51V51.91h29.85V81A5.51,5.51,0,0,1,420,86.53ZM396.68,52.91V81a4.51,4.51,0,0,0,4.5,4.51H420A4.51,4.51,0,0,0,424.53,81V52.91Z"
            style="fill: rgb(69, 90, 100); transform-origin: 410.605px 69.22px"
            id="el4m0cl22n4ze"
            class="animable"
          ></path>
          <path
            d="M406.05,62.21a2.27,2.27,0,1,1,2.28-2.27A2.27,2.27,0,0,1,406.05,62.21Zm0-3.54a1.27,1.27,0,1,0,1.28,1.27A1.27,1.27,0,0,0,406.05,58.67Z"
            style="fill: rgb(69, 90, 100); transform-origin: 406.06px 59.94px"
            id="elkpp9u3p44gk"
            class="animable"
          ></path>
          <path
            d="M392.11,74.38a.52.52,0,0,1-.35-.14.5.5,0,0,1,0-.71L405.7,59.59a.5.5,0,0,1,.71,0,.5.5,0,0,1,0,.7L392.47,74.24A.54.54,0,0,1,392.11,74.38Z"
            style="
              fill: rgb(69, 90, 100);
              transform-origin: 399.083px 66.9111px;
            "
            id="elkuu4cm5ry8"
            class="animable"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Labor--inject-42"
        class="animable"
        style="transform-origin: 99.64px 100.605px"
      >
        <g
          id="freepik--labor--inject-42"
          class="animable"
          style="transform-origin: 99.64px 100.605px"
        >
          <polygon
            points="133.17 163.83 133.7 163.13 98.01 135.78 98.01 102.26 97.13 102.26 97.13 136.22 97.3 136.35 133.17 163.83"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 115.415px 133.045px;
            "
            id="el48zn8zmcao1"
            class="animable"
          ></polygon>
          <path
            d="M135.5,163.48a2.07,2.07,0,1,1-2.07-2.08A2.08,2.08,0,0,1,135.5,163.48Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 133.43px 163.47px;
            "
            id="el0j4ya4t0wr9h"
            class="animable"
          ></path>
          <ellipse
            cx="97.57"
            cy="69.22"
            rx="32.8"
            ry="33.04"
            style="fill: rgb(255, 255, 255); transform-origin: 97.57px 69.22px"
            id="el3ted1mq4gxd"
            class="animable"
          ></ellipse>
          <path
            d="M97.57,102.75a33.54,33.54,0,1,1,33.29-33.53A33.45,33.45,0,0,1,97.57,102.75Zm0-66.09a32.56,32.56,0,1,0,32.31,32.56A32.47,32.47,0,0,0,97.57,36.66Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 97.3201px 69.2109px;
            "
            id="eljk5kljtmghk"
            class="animable"
          ></path>
          <path
            d="M97.74,77.1A15.11,15.11,0,0,1,82.61,63.23l0-.54h30.35l0,.54A15.1,15.1,0,0,1,97.74,77.1ZM83.67,63.69a14.19,14.19,0,0,0,28.15,0Z"
            style="fill: rgb(69, 90, 100); transform-origin: 97.785px 69.8955px"
            id="ell50iefs2cqj"
            class="animable"
          ></path>
          <g id="elmo5ojqlfuqj">
            <path
              d="M90.89,52.53a.45.45,0,0,1-.72.47l-3.74-3.48a6.76,6.76,0,0,1,3.21-2.46Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 88.6687px 50.0818px;
              "
              class="animable"
            ></path>
          </g>
          <g id="ela2ufo3rp4ou">
            <path
              d="M109.05,49.51,105.31,53a.44.44,0,0,1-.71-.47l1.25-5.47A6.67,6.67,0,0,1,109.05,49.51Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 106.813px 50.0862px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M113.13,58.25H82.34l0-.55a27.82,27.82,0,0,1,.4-2.92,14.19,14.19,0,0,1,2.91-5.92l.34-.39,4.32,4L89,46.32l.29-.19A17.36,17.36,0,0,1,97,43.66v0h.66l.14.5v-.5h.66v0a17.36,17.36,0,0,1,7.77,2.47l.29.19-1.41,6.18,4.32-4,.34.39a14.32,14.32,0,0,1,2.91,5.92c.17.93.3,1.88.39,2.92Zm-29.69-1H112c-.09-.8-.2-1.55-.34-2.28a12.83,12.83,0,0,0-2.36-5l-3.69,3.45a.91.91,0,0,1-1.08.13,1,1,0,0,1-.46-1.08l1.29-5.64A16.73,16.73,0,0,0,98,44.64h-.44a16.73,16.73,0,0,0-7.44,2.14l1.29,5.64a1,1,0,0,1-.46,1.08.91.91,0,0,1-1.08-.13l-3.7-3.45a13.11,13.11,0,0,0-2.36,5C83.63,55.7,83.52,56.45,83.44,57.25Z"
            style="fill: rgb(69, 90, 100); transform-origin: 97.735px 50.955px"
            id="el00sx5k9rucfq"
            class="animable"
          ></path>
          <path
            d="M100.58,57.75V45a1.83,1.83,0,0,0-1.76-1.9h-2.5A1.84,1.84,0,0,0,94.55,45V57.75Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 97.5652px 50.425px;
            "
            id="elmchfi9e4veh"
            class="animable"
          ></path>
          <g id="el260d55w5mhh">
            <path
              d="M100.58,57.75V45a1.83,1.83,0,0,0-1.76-1.9h-2.5A1.84,1.84,0,0,0,94.55,45V57.75Z"
              style="
                fill: rgb(255, 255, 255);
                opacity: 0.6;
                transform-origin: 97.5652px 50.425px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M101.08,58.25h-7V45a2.34,2.34,0,0,1,2.27-2.4h2.5a2.34,2.34,0,0,1,2.26,2.4Zm-6-1h5V45a1.33,1.33,0,0,0-1.26-1.4h-2.5A1.34,1.34,0,0,0,95.05,45Z"
            style="fill: rgb(69, 90, 100); transform-origin: 97.595px 50.425px"
            id="elq6o6kb6m01d"
            class="animable"
          ></path>
          <path
            d="M113.16,62.08H82.32a1.54,1.54,0,0,1-1.54-1.54V58.86a1.54,1.54,0,0,1,1.54-1.54h30.84a1.54,1.54,0,0,1,1.54,1.54v1.68A1.54,1.54,0,0,1,113.16,62.08ZM82.32,58.32a.54.54,0,0,0-.54.54v1.68a.54.54,0,0,0,.54.54h30.84a.54.54,0,0,0,.54-.54V58.86a.54.54,0,0,0-.54-.54Z"
            style="fill: rgb(69, 90, 100); transform-origin: 97.74px 59.7px"
            id="el7codsc6ghc"
            class="animable"
          ></path>
          <path
            d="M97.57,96A26.36,26.36,0,0,1,75.1,83.46l-.2-.32.25-.28a9.57,9.57,0,0,1,7.18-3.25h30.49A9.56,9.56,0,0,1,120,82.86l.25.28-.2.32A26.38,26.38,0,0,1,97.57,96ZM76.15,83.25a25.45,25.45,0,0,0,42.84,0,8.56,8.56,0,0,0-6.17-2.64H82.33A8.56,8.56,0,0,0,76.15,83.25Z"
            style="fill: rgb(69, 90, 100); transform-origin: 97.575px 87.805px"
            id="elu6wwfx8zays"
            class="animable"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Clipboard--inject-42"
        class="animable"
        style="transform-origin: 73.785px 219.831px"
      >
        <g
          id="freepik--Preconstruction--inject-42"
          class="animable"
          style="transform-origin: 73.785px 219.831px"
        >
          <ellipse
            cx="127.13"
            cy="228.89"
            rx="2.07"
            ry="2.08"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 127.13px 228.89px;
            "
            id="elod5fm9uzhzp"
            class="animable"
          ></ellipse>
          <polygon
            points="127.13 229.34 110.54 229.34 99.17 220.28 84.96 220.28 84.96 219.39 99.47 219.39 110.85 228.45 127.13 228.45 127.13 229.34"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 106.045px 224.365px;
            "
            id="elkxtvvqq3ywb"
            class="animable"
          ></polygon>
          <ellipse
            cx="52.16"
            cy="219.84"
            rx="32.8"
            ry="33.04"
            style="fill: rgb(255, 255, 255); transform-origin: 52.16px 219.84px"
            id="elp4hdterlubr"
            class="animable"
          ></ellipse>
          <path
            d="M52.16,253.37a33.54,33.54,0,1,1,33.29-33.53A33.45,33.45,0,0,1,52.16,253.37Zm0-66.09a32.56,32.56,0,1,0,32.31,32.56A32.47,32.47,0,0,0,52.16,187.28Z"
            style="
              fill: rgb(159, 209, 255);
              transform-origin: 51.91px 219.831px;
            "
            id="el4kjcwxl8vln"
            class="animable"
          ></path>
          <g id="elk6qm49q02">
            <path
              d="M33,200.91v37.14H60.77V200.91ZM57.29,234H36.49V204.91h20.8Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 46.885px 219.48px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M61.27,238.55H32.52V200.41H61.27Zm-27.75-1H60.27V201.41H33.52Z"
            style="fill: rgb(69, 90, 100); transform-origin: 46.895px 219.48px"
            id="el02b643ul2nv4"
            class="animable"
          ></path>
          <path
            d="M57.79,234.54H36V204.41h21.8Zm-20.8-1h19.8V205.41H37Z"
            style="fill: rgb(69, 90, 100); transform-origin: 46.9px 219.475px"
            id="elnt3z3cd397k"
            class="animable"
          ></path>
          <rect
            x="39.9"
            y="202.84"
            width="13.98"
            height="4.97"
            style="fill: rgb(69, 90, 100); transform-origin: 46.89px 205.325px"
            id="eliz4zlm43pwb"
            class="animable"
          ></rect>
          <g id="elwmuhnvgruh">
            <polyline
              points="67.02 206.87 67.02 232.79 67.26 237.79 65.6 233.44 65.6 206.87 67.26 206.87"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 66.43px 222.33px;
              "
              class="animable"
            ></polyline>
          </g>
          <path
            d="M67.26,239.2l-2.16-5.76V206.37h4.31l0,27.24Zm-1.16-5.85,1.16,3,1.15-3v-26H66.1Z"
            style="fill: rgb(69, 90, 100); transform-origin: 67.255px 222.785px"
            id="el3tu3dciuo6h"
            class="animable"
          ></path>
          <g id="elc33nj3ljgeh">
            <path
              d="M68.91,204.58H65.6v-1.65a1.65,1.65,0,0,1,1.66-1.65h0a1.65,1.65,0,0,1,1.65,1.65Z"
              style="
                fill: rgb(159, 209, 255);
                opacity: 0.4;
                transform-origin: 67.255px 202.93px;
              "
              class="animable"
            ></path>
          </g>
          <path
            d="M69.41,205.09H65.1v-2.16a2.16,2.16,0,0,1,4.31,0Zm-3.31-1h2.31v-1.16a1.16,1.16,0,0,0-2.31,0Z"
            style="fill: rgb(69, 90, 100); transform-origin: 67.255px 203.003px"
            id="ellr1f5nfc06p"
            class="animable"
          ></path>
        </g>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#32DFEC"
            flood-opacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#ff0000"
            flood-opacity="0.5"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          ></feColorMatrix>
        </filter>
      </defs>
    </svg>
  </main>
</template>

<style scoped>
svg#freepik_stories-construction-costs:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-construction-costs.animated #freepik--Floor--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Symbols--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Plants--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Shadows--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Wall--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Crane--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomOut;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Device--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedLeft;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Character--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Coins--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated
  #freepik--speech-bubble--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Equipment--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Overhead--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Material--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Labor--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-construction-costs.animated #freepik--Clipboard--inject-42 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
  animation-delay: 0s;
}
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes lightSpeedRight {
  from {
    transform: translate3d(50%, 0, 0) skewX(-20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes lightSpeedLeft {
  from {
    transform: translate3d(-50%, 0, 0) skewX(20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(-10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.svg-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.svg-wrapper svg {
  width: 100%;
  height: 100%;
}
</style>


